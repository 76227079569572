import React, { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { adminURL } from "../../../../../utils/urls";
import Slots from "./morningComponents/Slots";
import AddTimeSlots from "./morningComponents/AddTimeSlots";
import SuccessModal from "../../SuccessModal";
import SlotModal from "../../SlotModal";
import ErrorComponent from "../../ErrorComponent";
import ConfirmDeleteModal from "../ConfirmDeleteModal";

const MorningSlot = ({ userID, selectedDate, firstDayOfMonth }) => {
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newSlot, setNewSlot] = useState({ start: "", end: "" });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [slotToDelete, setSlotToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const queryClient = useQueryClient();

  const handleAddSlot = () => {
    setIsModalOpen(true);
  };

  const handleSaveSlot = async () => {
    if (!newSlot.start || !newSlot.end) {
      setError("Please select both start and end times.");
      return;
    }

    setIsLoading(true);

    try {
      const savedDate = localStorage.getItem("selectedDate");

      if (!savedDate) {
        setShowError(true);
        setFeedbackMessage(
          "An error occurred while adding the slot, no Selected date"
        );
      }

      const response = await adminURL.post(`users/${userID}/slots`, {
        starts_at: `${savedDate} ${newSlot.start}:00`,
        ends_at: `${savedDate} ${newSlot.end}:00`,
      });

      if (response.status === 200) {
        setShowSuccess(true);
        setFeedbackMessage("Slot added successfully!");
        setTimeout(() => setShowSuccess(false), 120000);
      } else if (response.status === 422) {
        setShowError(true);
        setFeedbackMessage(
          response.data.message || "An error occurred while adding the slot."
        );
        setTimeout(() => setShowError(false), 120000);
      }
    } catch (error) {
      setShowError(true);
      setFeedbackMessage(
        error.response?.data?.message ||
          error.message ||
          "An error occurred while adding the slot."
      );
      setTimeout(() => setShowError(false), 2000);
    } finally {
      setIsLoading(false);
      setNewSlot({ start: "", end: "" });
      setIsModalOpen(false);
      setError("");
    }
  };

  const handleSelectSlot = (slot) => {
    const slotString = `${slot.start}-${slot.end}`;
    if (selectedSlots.includes(slotString)) {
      setSelectedSlots(selectedSlots.filter((s) => s !== slotString));
    } else {
      if (selectedSlots.length < 2) {
        setSelectedSlots([...selectedSlots, slotString]);
      }
    }
  };

  const deleteSlotMutation = useMutation({
    mutationFn: async (id) => {
      setIsDeleting(true);
      const response = await adminURL.delete(`slots/${id}`);
      return response;
    },
    onSuccess: (response) => {
      setIsDeleting(false);
      setIsConfirmDeleteOpen(false);
      if (response.status === 200) {
        setShowSuccess(true);
        setFeedbackMessage("Slot deleted successfully!");
        setTimeout(() => setShowSuccess(false), 2000);
        queryClient.invalidateQueries(["slots", userID]);
      } else {
        setShowError(true);
        setFeedbackMessage(response?.data?.data?.message);
        setTimeout(() => setShowError(false), 2000);
      }
    },
    onError: (error) => {
      setIsDeleting(false);
      setIsConfirmDeleteOpen(false);
      setShowError(true);
      setFeedbackMessage(error.message);
      setTimeout(() => setShowError(false), 2000);
    },
  });

  const handleDeleteSlot = (id) => {
    setSlotToDelete(id);
    setIsConfirmDeleteOpen(true);
  };

  const confirmDelete = () => {
    deleteSlotMutation.mutate(slotToDelete);
  };

  const cancelDelete = () => {
    setIsConfirmDeleteOpen(false);
    setSlotToDelete(null);
  };

  return (
    <div className="p-4 flex flex-col gap-6 items-center">
      {showError && <ErrorComponent message={feedbackMessage} />}
      <Slots
        handleAddSlot={handleAddSlot}
        selectedSlots={selectedSlots}
        firstDayOfMonth={firstDayOfMonth}
        selectedDate={selectedDate}
        handleSelectSlot={handleSelectSlot}
        handleDeleteSlot={handleDeleteSlot}
        userID={userID}
      />

      <AddTimeSlots
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        error={error}
        newSlot={newSlot}
        setNewSlot={setNewSlot}
        handleSaveSlot={handleSaveSlot}
        setError={setError}
      />

      {/* Loading Modal */}
      <SlotModal isOpen={isLoading} text="Saving slot..." />

      {/* Success and Error Feedback Modals */}
      <SuccessModal
        isOpen={showSuccess || showError}
        message={feedbackMessage}
        isError={showError}
        onClose={() => {
          setShowSuccess(false);
          setShowError(false);
        }}
      />

      {/* Confirm Delete Modal */}
      <ConfirmDeleteModal
        isOpen={isConfirmDeleteOpen}
        onConfirm={confirmDelete}
        onCancel={cancelDelete}
        isDeleting={isDeleting}
      />
    </div>
  );
};

export default MorningSlot;
