import React, { useEffect } from "react";
import Payments from "./payments/Payments";
import PaymentTables from "./paymentTables/PaymentTables";

const Payment = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <section>
      <div className="flex justify-center mx-auto md:max-w-6xl">
        <header
          style={{ backgroundColor: "rgba(12, 17, 23, 1)" }}
          className="flex border border-primary justify-center items-center py-6 px-14 md:px-10 rounded-md space-x-4 md:space-x-8 md:px-8  w-full "
        >
          <h1 className="text-lg md:text-xl text-white">Transaction</h1>
        </header>
      </div>
      <section className="py-8">
        <Payments />
      </section>
      <section className="py-8">
        <PaymentTables />
      </section>
    </section>
  );
};

export default Payment;
