import React from "react";
import { motion } from "framer-motion";

const BookedSlotDetails = ({ slot, onClose }) => {
  return (
    <motion.div
      initial={{ scale: 0.5, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0.5, opacity: 0 }}
      transition={{ type: "spring", stiffness: 300, damping: 20 }}
      className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center p-4 z-50"
    >
      <div className="bg-black rounded-lg shadow-lg p-6 max-w-sm w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold text-white">Slot Details</h2>
          <button
            onClick={onClose}
            className="text-red-500 text-lg font-bold focus:outline-none"
          >
            &times;
          </button>
        </div>
        <p className="text-lg text-white mb-2">
          <strong>Booker:</strong> {slot.booker.name}
        </p>
        <p className="text-lg text-white">
          <strong>Period:</strong> {slot.period_for_humans}
        </p>
      </div>
    </motion.div>
  );
};

export default BookedSlotDetails;
