import React from "react";
import MailScreen from "./MailScreen";

const Mail = () => {
  return (
    <section className="p-4">
      <MailScreen />
    </section>
  );
};

export default Mail;
