import React from "react";
import { motion } from "framer-motion";
import Icon from "../.././assets/images/logo/logo2.png";

const Loader = ({ text = "Fetching users..." }) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <motion.img
        src={Icon}
        alt="Loading Icon"
        initial={{ opacity: 0 }}
        animate={{ opacity: [0, 1, 0] }}
        transition={{ duration: 1.5, repeat: Infinity }}
        className="w-20 h-20"
      />
      <motion.span
        initial={{ opacity: 0 }}
        animate={{ opacity: [0, 1, 0] }}
        transition={{ duration: 1.5, repeat: Infinity }}
        className="text-white mt-4 text-lg"
      >
        {text}
      </motion.span>
    </div>
  );
};

export default Loader;
