import React from "react";
import { motion } from "framer-motion";
import { useStepContext } from "../../../context/stepContext";
import { slideDownVariant } from "../../../utils/variants";

const Navigator = () => {
  const { stepLists } = useStepContext();

  const displaySteps = stepLists?.map((step, index) => (
    <div
      key={index}
      className={
        index !== stepLists.length - 1
          ? "w-full flex items-center"
          : "flex items-center"
      }
    >
      <div className="relative flex flex-col items-center">
        <div
          className={`rounded-full transition duration-500 ease-in-out h-12 w-12 flex items-center justify-center py-3   ${
            step.selected
              ? "bg-amber-500 text-white font-bold  shadow-md"
              : "bg-gray-700 shadow-md"
          }`}
        >
          {/* display number */}
          {step.selected ? (
            <span className="text-black font-bold text-xl">{index + 1}</span>
          ) : (
            <span className="text-white font-bold text-xl">{index + 1}</span>
          )}
        </div>
        <div
          className={`absolute top-0 text-center mt-16 w-32 text-xs font-medium uppercase hidden md:flex ${
            step.highlighted ? "text-gray-900" : "text-gray-300"
          }`}
        ></div>
      </div>
      <div
        className={`flex-auto border-t-4 transition duration-200 ease-in-out  ${
          step.completed ? "border-amber-500" : "border-gray-400"
        }`}
      >
        {/* display line */}
      </div>
    </div>
  ));

  return (
    <motion.div
      variants={slideDownVariant}
      initial="hidden"
      animate="visible"
      className="flex mx-auto"
    >
      {displaySteps}
    </motion.div>
  );
};

export default Navigator;
