import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import "react-datepicker/dist/react-datepicker.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const DashboardChart = ({ data }) => {
  if (!data) return null;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        position: "nearest",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        titleColor: "white",
        bodyColor: "white",
        borderColor: "white",
        borderWidth: 1,
        padding: 10,
        displayColors: true,
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y;
            }
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          borderDash: [8, 4],
          color: "white",
        },
        ticks: {
          color: "white",
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => `${value}`,
        },
        grid: {
          display: true,
          color: "white",
          borderDash: [20, 20],
        },
      },
    },
    barPercentage: 0.5,
    interaction: {
      mode: "index",
      intersect: false,
    },
    onHover: (event, chartElement) => {
      event.native.target.style.cursor = chartElement[0]
        ? "pointer"
        : "default";
    },
  };

  const chartData = {
    labels: data.labels,
    datasets: data.datasets.map((dataset, index) => ({
      ...dataset,
      backgroundColor: index === 0 ? "#3b82f6" : "#ec4899",
      barThickness: 10,
      borderRadius: 5,
    })),
  };

  return (
    <div className="relative rounded-lg bg-gray-500 bg-opacity-10 border border-customBorderTwo px-3 py-6 shadow-md min-w-[250px] md:min-w-[400px]">
      <h2 className="text-white mb-5 ml-5 text-xl font-bold">
        User Statistics
      </h2>
      <div className="flex flex-col justify-between mb-5 md:flex-row">
        <div className="flex space-x-4">
          <div className="flex items-center">
            <div className="w-4 h-4 bg-blue-500 rounded-full mr-2"></div>
            <span className="text-white text-sm">Male</span>
          </div>
          <div className="flex items-center">
            <div className="w-4 h-4 bg-pink-500 rounded-full mr-2"></div>
            <span className="text-white text-sm">Female</span>
          </div>
        </div>
      </div>
      <div className="h-72 p-5">
        <Bar options={options} data={chartData} />
      </div>
    </div>
  );
};

export default DashboardChart;
