import React from "react";

const Duration = ({ data }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const bookedDate = formatDate(data?.slot?.booked_at);

  return (
    <section className="p-4 text-center md:text-start">
      <h1 className="text-white mb-4">Selected Duration:</h1>
      <div className="flex flex-col lg:flex-col xl:flex-row justify-between items-center space-y-2 xl:space-y-0 xl:space-x-4">
        <div className="bg-white text-black rounded-full px-4 py-1">
          {data?.slot?.period_for_humans}
        </div>
        <div className="text-gray-400">{bookedDate}</div>
        {/* <div className="flex items-center space-x-2">
          <input type="checkbox" className="form-checkbox text-blue-600" />
          <span className="text-white">Set reminder</span>
        </div> */}
      </div>
    </section>
  );
};

export default Duration;
