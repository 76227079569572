import {
  faEnvelope,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { baseURL } from "../../utils/urls";
import LoadingModal from "../../components/modals/LoadingModal";
import AnimatedModal from "../../components/modals/AnimatedModal";

const LoginForm = ({ onForgotPasswordClick, setIsLoggedIn }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
  } = useForm();

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const onSubmit = useCallback(async (data) => {
    setIsLoading(true);
    try {
      const response = await baseURL.post("login", {
        email: data.email,
        password: data.password,
      });

      if (response.status === 422) {
        setErrorMessage(response.data.message);
        setErrorModalOpen(true);
      } else if (response.status === 200) {
        localStorage.setItem("refID", response?.data?.data?.token);
        localStorage.setItem("refreshID", response?.data?.data?.user?.id);

        const approvedStatus = response?.data?.data?.user?.approved;
        const role = response?.data?.data?.user?.role;

        if (role === "coach") {
          navigate(
            approvedStatus ? "/coaches/dashboard" : "/coaches/approval-status"
          );
          return;
        } else if (role === "admin") {
          navigate("/dashboard");
        }
      }
    } catch (error) {
      if (error.response?.status === 401) {
        setErrorMessage("Login failed. Please check your credentials.");
      } else {
        setErrorMessage("An error occurred. Please try again.");
      }
      setErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="!mb-5">
        <h2 className="text-2xl text-white font-bold py-4 tablet:text-primary">
          Welcome back
        </h2>
        <p className="text-base text-white tablet:text-primary">
          Please, enter your details
        </p>
      </div>
      <div className="!mt-5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full mt-3 flex flex-col relative">
            <label
              htmlFor="coach-email"
              className="mb-2 text-white text-base text-left"
            >
              Enter your email
            </label>
            <div className="flex items-center relative">
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <input
                    {...field}
                    id="email"
                    type="email"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value:
                          /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                        message: "Invalid email address",
                      },
                    })}
                    className={`bg-transparent border-2 py-2 px-10 border-yellow-500 text-white rounded-lg h-11 w-full focus:outline-none focus:border-primary ${
                      errors.email ? "border-red-500" : ""
                    }`}
                    autoComplete="email"
                  />
                )}
              />
              <FontAwesomeIcon
                icon={faEnvelope}
                className="absolute left-3 text-gray-400"
              />
            </div>
            {errors.email && (
              <p className="text-red-500 text-sm">{errors.email.message}</p>
            )}
          </div>

          <div className="w-full mt-3 flex flex-col relative">
            <label
              htmlFor="password"
              className="mb-2 text-white text-base text-left"
            >
              Enter your password
            </label>
            <div className="flex items-center relative">
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <input
                    {...field}
                    id="password"
                    type={showPassword ? "text" : "password"}
                    {...register("password", {
                      required: "Password is required",
                      minLength: {
                        value: 8,
                        message: "Password must be at least 8 characters long",
                      },
                      pattern: {
                        value:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                        message:
                          "Password must include uppercase, lowercase, number, and special character",
                      },
                    })}
                    className={`bg-transparent border-2 py-2 px-10 border-yellow-500 text-white rounded-lg h-11 w-full focus:outline-none focus:border-primary ${
                      errors.password ? "border-red-500" : ""
                    }`}
                    autoComplete="current-password"
                  />
                )}
              />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="absolute right-3 text-gray-400 cursor-pointer"
                onClick={togglePasswordVisibility}
              />
            </div>
            {errors.password && (
              <p className="text-red-500 text-sm">{errors.password.message}</p>
            )}
          </div>
          <div className="w-full mt-3 flex items-center">
            <input
              type="checkbox"
              id="rememberMe"
              checked={rememberMe}
              onChange={() => setRememberMe(!rememberMe)}
              className="mr-2"
            />
            <label htmlFor="rememberMe" className="text-white">
              Remember Me
            </label>
          </div>
          <input
            type="submit"
            value="Submit"
            className="login-btn font-bold block w-80 my-4 rounded-md text-base font-sans uppercase transition-all duration-700 text-black hover:bg-right"
          />

          <div className="flex flex-col items-start mt-6 tablet:flex-row tablet:justify-between tablet:items-center">
            <div className="text-white text-sm mb-2 tablet:mb-0">
              Want to register as a coach?{" "}
              <Link
                to="/coaches/signup"
                className="text-primary hover:underline transition duration-300"
              >
                Click here
              </Link>
            </div>
            <Link
              className="text-primary text-sm block text-right transition duration-300"
              onClick={onForgotPasswordClick}
            >
              Forgot Password?
            </Link>
          </div>
        </form>
      </div>
      <LoadingModal isOpen={isLoading} />
      <AnimatedModal
        isOpen={errorModalOpen}
        onClose={() => setErrorModalOpen(false)}
      >
        <p className="text-white">{errorMessage}</p>
      </AnimatedModal>
    </div>
  );
};

export default LoginForm;
