import React, { useEffect, useState } from "react";
import Cdetails from "./Cdetails";
import { useParams } from "react-router-dom";
import Csmall from "./Csmall";
import { adminURL } from "../../utils/urls";
import Loader from "../loader/FetchLoader";

const Cdisplay = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

 
  const fetchData = async () => {
    try {
      const response = await adminURL.get(`users/${id}`);
     
      setUser(response.data.data?.user);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (loading) {
    return (
     <Loader text="fetching coaches"/>
    );
  }

  return (
    <section className="flex flex-col items-center justify-center min-h-screen p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-5xl w-full">
        {/* details */}
        <div className="flex flex-col items-center">
          {user ? (
            <Cdetails user={user} />
          ) : (
            <p className="text-white">User not found</p>
          )}
        </div>
        {/* small details */}
        <div className="flex flex-col items-center">
          {user && <Csmall user={user} />}
        </div>
      </div>
    </section>
  );
};

export default Cdisplay;
