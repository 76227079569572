import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { baseURL } from "../../utils/urls";
import LoadingModal from "../../components/modals/LoadingModal";
import AnimatedModal from "../../components/modals/AnimatedModal";

const ResetPasswordForm = ({ setIsLoggedIn }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isInvalidToken, setIsInvalidToken] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { id: token } = useParams();
  const location = useLocation();
  const email = new URLSearchParams(location.search).get("email");

  useEffect(() => {
    if (!token || !email) {
      navigate("/login");
    }
  }, [token, email, navigate]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    watch,
  } = useForm();

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const onSubmit = useCallback(
    async (data) => {
      setIsLoading(true);

      try {
        const response = await baseURL.post("reset-password", {
          email: email,
          token: token,
          password: data.newPassword,
          password_confirmation: data.confirmPassword,
        });

        if (response.status === 422) {
          const errorMessage = response.data.message;
          if (errorMessage === "This password reset token is invalid.") {
            setIsInvalidToken(true);
            setModalMessage("This password reset token is invalid.");
            setErrorModalOpen(true);
            setTimeout(() => {
              setErrorModalOpen(false);
              navigate("/login");
            }, 3000);
          } else {
            setModalMessage(errorMessage);
            setErrorModalOpen(true);
          }
        } else if (response.status === 200) {
          setSuccessModalOpen(true);
          setTimeout(() => {
            setSuccessModalOpen(false);
            navigate("/login");
          }, 3000);
        }
      } catch (error) {
        setModalMessage("An error occurred. Please try again.");
        setErrorModalOpen(true);
      } finally {
        setIsLoading(false);
      }
    },
    [email, token, navigate]
  );

  return (
    <div>
      <div className="!mb-5">
        <h2 className="text-2xl text-white font-bold py-4 tablet:text-primary">
          Reset Your Password
        </h2>
        <p className="text-base text-white tablet:text-primary">
          Please, enter your new password.
        </p>
      </div>
      <div className="!mt-5">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full mt-3 flex flex-col relative">
            <label
              htmlFor="new-password"
              className="mb-2 text-white text-base text-left"
            >
              New Password
            </label>
            <div className="flex items-center relative">
              <Controller
                name="newPassword"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <input
                    {...field}
                    id="new-password"
                    type={showPassword ? "text" : "password"}
                    {...register("newPassword", {
                      required: "New password is required",
                      minLength: {
                        value: 8,
                        message: "Password must be at least 8 characters long",
                      },
                      pattern: {
                        value:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                        message:
                          "Password must include uppercase, lowercase, number, and special character",
                      },
                    })}
                    className={`bg-transparent border-2 py-2 px-10 border-yellow-500 text-white rounded-lg h-11 w-full focus:outline-none focus:border-primary ${
                      errors.newPassword ? "border-red-500" : ""
                    }`}
                    autoComplete="new-password"
                  />
                )}
              />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="absolute right-3 text-gray-400 cursor-pointer"
                onClick={togglePasswordVisibility}
              />
            </div>
            {errors.newPassword && (
              <p className="text-red-500 text-sm">
                {errors.newPassword.message}
              </p>
            )}
          </div>

          <div className="w-full mt-3 flex flex-col relative">
            <label
              htmlFor="confirm-password"
              className="mb-2 text-white text-base text-left"
            >
              Confirm New Password
            </label>
            <div className="flex items-center relative">
              <Controller
                name="confirmPassword"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <input
                    {...field}
                    id="confirm-password"
                    type={showPassword ? "text" : "password"}
                    {...register("confirmPassword", {
                      required: "Please confirm your password",
                      validate: (value) =>
                        value === watch("newPassword") ||
                        "Passwords do not match",
                    })}
                    className={`bg-transparent border-2 py-2 px-10 border-yellow-500 text-white rounded-lg h-11 w-full focus:outline-none focus:border-primary ${
                      errors.confirmPassword ? "border-red-500" : ""
                    }`}
                    autoComplete="new-password"
                  />
                )}
              />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="absolute right-3 text-gray-400 cursor-pointer"
                onClick={togglePasswordVisibility}
              />
            </div>
            {errors.confirmPassword && (
              <p className="text-red-500 text-sm">
                {errors.confirmPassword.message}
              </p>
            )}
          </div>

          <input
            type="submit"
            value="Reset Password"
            className="login-btn font-bold block w-80 my-4 rounded-md text-base font-sans uppercase transition-all duration-700 text-black hover:bg-right"
          />
        </form>
      </div>
      <LoadingModal isOpen={isLoading} />
      <AnimatedModal
        isOpen={errorModalOpen}
        onClose={() => setErrorModalOpen(false)}
      >
        <div className="text-center bg-gray-800 p-6 rounded-lg">
          {isInvalidToken && <p className="text-6xl mb-4">❌</p>}
          <p className="text-white text-xl">{modalMessage}</p>
          {isInvalidToken && (
            <p className="text-white mt-2">Redirecting to login...</p>
          )}
        </div>
      </AnimatedModal>
      <AnimatedModal
        isOpen={successModalOpen}
        onClose={() => setSuccessModalOpen(false)}
      >
        <div className="text-center bg-gray-800 p-6 rounded-lg">
          <p className="text-6xl mb-4">😊</p>
          <p className="text-white text-xl">Password reset successful!</p>
          <p className="text-white mt-2">Redirecting to login...</p>
        </div>
      </AnimatedModal>
    </div>
  );
};

export default ResetPasswordForm;
