import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import EventFileInput from "./EventFileInput";
import Modal from "./eventModal/Modal";


const CreateEvents = () => {
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [title, setTitle] = useState("");
  const [venue, setVenue] = useState("");
  const [description, setDescription] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const navigate = useNavigate();
  const token = localStorage.getItem("refID");

  const handleFileChange = (file) => {
    setImageFile(file);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!date) newErrors.date = "Date is required";
    if (!time) newErrors.time = "Time is required";
    if (!title) newErrors.title = "Title is required";
    if (!venue) newErrors.venue = "Venue is required";
    if (!description) newErrors.description = "Description is required";
    if (!imageFile) newErrors.imageFile = "Image is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsSubmitting(true);

    const formData = new FormData();
    const startsAt = `${date} ${time}`;

    formData.append("starts_at", startsAt);
    formData.append("name", title);
    formData.append("venue", venue);
    formData.append("description", description);
    formData.append("image", imageFile);

    try {
      const res = await axios.post(
        "https://platinumfuse.v2napi.com/api/events",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.status === 200) {
        const eventName = res?.data?.data?.event?.name;
        const eventID = res?.data?.data?.event?.id;

        setModalMessage("Event created successfully");
        setIsSuccess(true);
        setModalVisible(true);

        setTimeout(() => {
          navigate(`/dashboard/events/create/preview/${eventName}/${eventID}`);
        }, 2000);
      } else {
        setModalMessage("Error in creating event");
        setIsSuccess(false);
        setModalVisible(true);
      }
    } catch (error) {
      setModalMessage("Error in creating event");
      setIsSuccess(false);
      setModalVisible(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section
      style={{ backgroundColor: "rgba(12, 17, 23, 1)" }}
      className="flex flex-col items-center w-full max-w-[1020px] mx-auto px-4 sm:px-8"
    >
      {/* Modal */}
      {modalVisible && (
        <Modal
          message={modalMessage}
          success={isSuccess}
          onClose={() => setModalVisible(false)}
        />
      )}

      <form className="w-full space-y-4 pt-8" onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8">
          <div className="md:col-span-2 space-y-4">
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
              <div className="flex flex-col space-y-2">
                <label className="text-white">Date</label>
                <input
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  className="text-white bg-gray-500 py-3 px-2 rounded-xl placeholder-white"
                />
                {errors.date && <p className="text-red-500">{errors.date}</p>}
              </div>
              <div className="flex flex-col space-y-2">
                <label className="text-white">Time</label>
                <input
                  type="time"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                  className="text-white bg-gray-500 py-3 px-2 rounded-xl placeholder-white"
                />
                {errors.time && <p className="text-red-500">{errors.time}</p>}
              </div>
            </div>

            <div className="flex flex-col space-y-2">
              <label className="text-white">Title</label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="text-white bg-gray-500 py-3 px-2 rounded-xl placeholder-white w-full sm:w-[400px]"
              />
              {errors.title && <p className="text-red-500">{errors.title}</p>}
            </div>

            <div className="flex flex-col space-y-2">
              <label className="text-white">Venue</label>
              <textarea
                value={venue}
                onChange={(e) => setVenue(e.target.value)}
                className="text-white bg-gray-500 py-10 px-2 rounded-xl placeholder-white w-full"
              />
              {errors.venue && <p className="text-red-500">{errors.venue}</p>}
            </div>
          </div>

          <EventFileInput onFileChange={handleFileChange} />
        </div>

        <div className="flex flex-col space-y-2">
          <label className="text-white">Description</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="text-white bg-gray-500 py-10 px-2 rounded-xl w-full placeholder-white"
          />
          {errors.description && (
            <p className="text-red-500">{errors.description}</p>
          )}
        </div>

        <div className="flex flex-col sm:flex-row justify-between items-center py-8 space-y-4 sm:space-y-0">
          <Link
            to="/dashboard/events"
            className="border text-white py-2 px-10 rounded-md"
          >
            Cancel
          </Link>
          <button
            type="submit"
            className="bg-amber-500 text-black py-2 px-10 rounded-md"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Next"}
          </button>
        </div>
      </form>
    </section>
  );
};

export default CreateEvents;
