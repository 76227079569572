import React, { useState } from "react";
import BlockRequests from "./blockedrequest/BlockRequestsTable";
import BlockedUsers from "./blockedusers/BlockedUsers";

const UserReport = () => {
  const [activeTab, setActiveTab] = useState("blockRequestsTab");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <section className="flex flex-col w-full p-5 space-y-5 min-h-screen pt-20 md:pt-0">
      <div className="flex flex-col w-full">
        <div className="flex space-x-2 min-w-[300px] w-full mx-auto p-5 bg-gray-900 rounded-t-lg">
          <button
            onClick={() => handleTabChange("blockRequestsTab")}
            className={`${
              activeTab === "blockRequestsTab"
                ? "bg-primary text-gray-900"
                : "text-gray-300 border border-gray-300 bg-black"
            } flex-1 text-sm px-4 py-2 rounded-lg capitalize font-semibold text-center md:text-xl`}
          >
            Report/Block Requests
          </button>
          <button
            onClick={() => handleTabChange("blockedUsersTab")}
            className={`${
              activeTab === "blockedUsersTab"
                ? "bg-primary text-gray-900"
                : "text-gray-300 border border-gray-300 bg-black"
            } flex-1 px-4 py-2 rounded-lg capitalize font-semibold text-center text-sm md:text-xl`}
          >
            Blocked Users
          </button>
        </div>

        <div className="relative p-4 z-10 bg-gray-800 rounded-b-lg flex-1 min-w-[300px] w-full">
          {activeTab === "blockRequestsTab" && <BlockRequests />}
          {activeTab === "blockedUsersTab" && <BlockedUsers />}
        </div>
      </div>
    </section>
  );
};

export default UserReport;
