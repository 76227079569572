import React, { useState, useEffect } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import {
  UilAngleLeft,
  UilAngleRight,
  UilLeftArrowToLeft,
  UilArrowToRight,
} from "@iconscout/react-unicons";


const Table = ({
  columns,
  data,
  initialSortBy,
  headStyles,
  fullWidth,
  pagination,
  onPageChange,
}) => {
  const [activeRow, setActiveRow] = useState(null);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex:
          parseInt(localStorage.getItem("pageIndex")) ||
          pagination.currentPage - 1,
        pageSize: pagination.perPage,
        sortBy: initialSortBy || [],
      },
      manualPagination: true,
      pageCount: pagination.lastPage,
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    const savedOriginalId = localStorage.getItem("originalID");

    if (savedOriginalId && page.length > 0) {
      const matchingRow = page.find(
        (row) => row.original.id.toString() === savedOriginalId
      );

      if (matchingRow) {
        setActiveRow(matchingRow.id);
      } else {
        setActiveRow(page[0].id);

        localStorage.setItem("originalID", page[0].original.id);
      }
    } else if (page.length > 0) {
      setActiveRow(page[0].id);

      localStorage.setItem("originalID", page[0].original.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleRowClick = (row) => {
    setActiveRow(row.id);
    localStorage.setItem("originalID", row.original.id);
    // window.location.reload(); // Force refresh to apply the correct row selection
  };

  const handlePageChange = (newPage) => {
    onPageChange(newPage);
  };

  return (
    <div
      className={`is-scrollbar-hidden min-w-full w-full ${
        fullWidth ? "max-w-full" : ""
      } max-h-full overflow-auto`}
    >
      <table
        {...getTableProps()}
        className={`w-full max-w-full rounded-2xl is-hoverable text-left`}
      >
        <thead
          style={{
            backgroundColor: "rgba(12, 17, 23, 1)",
            border: "1px solid #343023",
            borderBottom: "none",
          }}
          className={`text-white font-bold text-center capitalize p-2 py-4 rounded-t-lg sticky top-0 ${headStyles}`}
        >
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="whitespace-nowrap py-3 text-base font-semibold capitalize lg:px-5"
                  style={{
                    borderBottom: "1px solid #343023",
                    borderLeft: "1px solid #343023",
                    borderRight: "1px solid #343023",
                  }}
                >
                  <div className="flex items-center text-center justify-center">
                    <span>{column.render("Header")}</span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          className="text-slate-300 space-y-5 text-center bg-gray-500 bg-opacity-10"
          {...getTableBodyProps()}
          style={{
            borderTop: "1px solid #343023",
            borderBottom: "1px solid #343023",
          }}
        >
          {page.map((row, rowIndex) => {
            prepareRow(row);
            const isActive = row.id === activeRow;
            return (
              <tr
                key={rowIndex}
                {...row.getRowProps()}
                className={`py-5 border-y border-t-transparent border-b-customBorderTwo cursor-pointer ${
                  isActive ? "bg-gray-700 text-white" : "hover:bg-gray-700"
                }`}
                onClick={() => handleRowClick(row)}
              >
                {row.cells.map((cell, cellIndex) => (
                  <td
                    key={cellIndex}
                    className="px-4 py-3 sm:px-5 font-medium max-w-min"
                    {...cell.getCellProps()}
                    style={{
                      borderLeft: "1px solid #343023",
                      borderRight: "1px solid #343023",
                    }}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="w-full flex justify-between p-5">
        <div className="text-gray-300 text-sm flex items-end">
          Showing{" "}
          {pagination.currentPage * pagination.perPage - pagination.perPage + 1}{" "}
          -{" "}
          {Math.min(
            pagination.currentPage * pagination.perPage,
            pagination.total
          )}{" "}
          of {pagination.total}
        </div>
        <div className="flex justify-end items-center">
          <button
            onClick={() => handlePageChange(1)}
            disabled={!canPreviousPage}
            className="mr-3 border border-gray-300 text-gray-300 rounded-lg hover:text-primary hover:border-primary"
          >
            <UilLeftArrowToLeft className="text-gray-300 p-1 hover:text-primary" />
          </button>
          <button
            onClick={() => handlePageChange(pagination.currentPage - 1)}
            disabled={!canPreviousPage}
            className="mr-3 border border-gray-300 text-gray-300 rounded-lg hover:text-primary hover:border-primary"
          >
            <UilAngleLeft className="text-gray-300 hover:text-primary" />
          </button>
          <button
            onClick={() => handlePageChange(pagination.currentPage + 1)}
            disabled={!canNextPage}
            className="mr-3 border border-gray-300 text-gray-300 rounded-lg hover:text-primary hover:border-primary"
          >
            <UilAngleRight className="text-gray-300 hover:text-primary" />
          </button>
          <button
            onClick={() => handlePageChange(pagination.lastPage)}
            disabled={!canNextPage}
            className="border border-gray-300 text-gray-300 rounded-lg hover:text-primary hover:border-primary"
          >
            <UilArrowToRight className="text-gray-300 p-1 hover:text-primary" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Table;
