import React, { useState } from "react";
import Navigation from "./navigation/Navigation";
import UserDemographics from "./user-demographics/UserDemographics";
import UserEngagement from "./user-engagement/UserEngagement";
import UserInteraction from "./user-interaction/UserInteraction";
import UserConversion from "./user-conversion/UserConversion";
import UserRatings from "./reviews/UserRatings";

const Analytics = () => {
  const [activeContent, setActiveContent] = useState("User demographics");

  const renderContent = () => {
    switch (activeContent) {
      case "User demographics":
        return <UserDemographics />;
      case "User engagements":
        return <UserEngagement />;
      case "User interaction":
        return <UserInteraction />;
      case "Conversion & Monetization":
        return <UserConversion/>;
      case "Review & ratings":
        return <UserRatings/>;
      default:
        return <div>Select a section</div>;
    }
  };

  return (
    <section className="pt-40 md:pt-0">
      <Navigation onNavigate={setActiveContent} />
      <div className="p-4">{renderContent()}</div>
    </section>
  );
};

export default Analytics;
