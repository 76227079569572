import React from "react";
import ReactECharts from "echarts-for-react";
import { useQuery } from "@tanstack/react-query";
import { adminURL } from "../../utils/urls";

const fetchAppUsageData = async () => {
  const { data } = await adminURL.get(`charts/messages`);
  return data;
};

const MessagingActivityChart = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["message-activity"],
    queryFn: fetchAppUsageData,
  });

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error loading data</p>;

  const dates = data ? data.labels : [];
  const messagesData = data ? data.series[0].data : [];

  const options = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      borderWidth: "1px",
      textStyle: {
        color: "#fff",
      },
    },
    legend: {
      data: [
        { name: "Messages", icon: "rect", textStyle: { color: "#ffffff" } },
      ],
      itemWidth: 14,
      itemHeight: 14,
      textStyle: {
        color: "#ffffff",
      },
      right: 10,
      top: 10,
    },
    xAxis: {
      type: "category",
      data: dates,
      axisLabel: {
        formatter: "{value}",
        color: "#ffffff",
      },
      axisLine: {
        lineStyle: {
          color: "#ffffff",
        },
      },
    },
    yAxis: {
      type: "value",
      name: "Number of Messages",
      nameLocation: "middle",
      nameGap: 30,
      axisLabel: {
        formatter: "{value}",
        color: "#ffffff",
      },
      axisLine: {
        lineStyle: {
          color: "#ffffff",
        },
      },
      splitLine: {
        show: false,
      },
    },
    series: [
      {
        name: "Messages",
        type: "bar",
        itemStyle: {
          color: "#8A3AB9",
        },
        data: messagesData,
        barWidth: "30%",
      },
    ],
  };

  return (
    <div className="flex flex-col bg-gray-500 bg-opacity-10  w-full space-y-1 rounded-xl  relative shadow-xl">
      <h2 className="text-white text-left text-lg border-[0.5px] border-customBorderTwo rounded-t-lg py-3 px-4">
        Messages
      </h2>
      <div className="p-3">
        {data && (
          <div>
            <ReactECharts
              option={options}
              style={{ height: "400px", width: "100%" }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MessagingActivityChart;
