import React from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import {
  UilAngleLeft,
  UilAngleRight,
  UilLeftArrowToLeft,
  UilArrowToRight,
} from "@iconscout/react-unicons";

const Table = ({ columns, data, initialSortBy, fullWidth }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    pageCount,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 10, 
        sortBy: initialSortBy || [],
      },
    },
    useSortBy,
    usePagination 
  );

  return (
    <div
      className={`is-scrollbar-hidden min-w-full w-full ${fullWidth ? "max-w-full" : ""} max-h-full overflow-auto`}
    >
      <table
        {...getTableProps()}
        className="w-full max-w-full p-5 rounded-lg is-hoverable text-left"
      >
        <thead
          style={{
            backgroundColor: "rgba(12, 17, 23, 1)",
            border: "1px solid #343023",
          }}
          className="text-white font-bold text-center capitalize p-2 py-4 rounded-t-lg sticky top-0"
        >
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="whitespace-nowrap py-3 text-base font-semibold capitalize lg:px-5"
                  style={{
                    borderBottom: "1px solid #343023",
                    borderLeft: "1px solid #343023",
                    borderRight: "1px solid #343023",
                  }}
                >
                  <div className="flex items-center justify-center">
                    {column.render("Header")}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps()}
          className="text-slate-300 text-center"
          style={{
            borderTop: "1px solid #343023",
            borderBottom: "1px solid #343023",
          }}
        >
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="py-5 border-y border-t-transparent border-b-gray-300">
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className="px-4 py-3 sm:px-5 font-medium max-w-min text-white"
                    style={{ borderLeft: "1px solid #343023", borderRight: "1px solid #343023" }}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* Pagination */}
      <div className="w-full flex justify-between p-5">
        <div className="text-gray-300 text-sm">
          Showing {pageIndex * pageSize + 1} - {Math.min((pageIndex + 1) * pageSize, data.length)} of {data.length}
        </div>
        <div className="flex items-center">
          <button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            className="mr-3 border border-gray-300 text-gray-300 rounded-lg hover:text-primary hover:border-primary"
          >
            <UilLeftArrowToLeft className="text-gray-300 p-1 hover:text-primary" />
          </button>
          <button
            onClick={previousPage}
            disabled={!canPreviousPage}
            className="mr-3 border border-gray-300 text-gray-300 rounded-lg hover:text-primary hover:border-primary"
          >
            <UilAngleLeft className="text-gray-300 hover:text-primary" />
          </button>
          <button
            onClick={nextPage}
            disabled={!canNextPage}
            className="mr-3 border border-gray-300 text-gray-300 rounded-lg hover:text-primary hover:border-primary"
          >
            <UilAngleRight className="text-gray-300 hover:text-primary" />
          </button>
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            className="border border-gray-300 text-gray-300 rounded-lg hover:text-primary hover:border-primary"
          >
            <UilArrowToRight className="text-gray-300 p-1 hover:text-primary" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Table;
