import React, { useEffect } from "react";
import EventTable from "./Tables/EventTable";

const Events = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return <EventTable />;
};

export default Events;
