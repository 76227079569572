import React from "react";
import ReactECharts from "echarts-for-react";
import { useQuery } from "@tanstack/react-query";
import { adminURL } from "../../utils/urls";

const fetchSubData = async () => {
  const { data } = await adminURL.get(`charts/subscriptions`);
  return data;
};

const SubscriptionChart = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["subscription-conversion"],
    queryFn: fetchSubData,
  });

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error loading data</p>;

  const months = data ? data.labels : [];
  const subscriptions = data ? data.series[0].data : [];

  const options = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "line",
        lineStyle: {
          type: "dashed",
          color: "rgba(224, 224, 224, 0.2)",
        },
        crossStyle: {
          type: "dashed",
          color: "rgba(224, 224, 224, 0.2)",
        },
      },
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      borderWidth: "1px",
      textStyle: {
        color: "#fff",
      },
    },
    xAxis: {
      type: "category",
      data: months,
      axisLabel: {
        color: "#ffffff",
      },
      axisLine: {
        lineStyle: {
          color: "#ffffff",
        },
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        color: "#ffffff",
      },
      axisLine: {
        lineStyle: {
          color: "#ffffff",
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: "dashed",
          color: "rgba(224, 224, 224, 0.2)",
        },
      },
    },
    series: [
      {
        name: "Number of Subscribers",
        type: "line",
        smooth: true,
        data: subscriptions,
        showSymbol: false,
        lineStyle: {
          color: "rgba(16, 185, 129, 1)",
          width: 2,
        },
        areaStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              { offset: 0, color: "rgba(16, 185, 129, 1)" },
              { offset: 1, color: "rgba(16, 185, 129, 0.2)" },
            ],
          },
        },
      },
    ],
  };

  return (
    <div className="flex flex-col bg-gray-500 bg-opacity-10  w-full space-y-1 rounded-xl  relative shadow-xl">
      <h2 className="text-white text-left text-lg border-[0.5px] border-customBorderTwo rounded-t-lg py-3 px-4">
        Subscription Conversion
      </h2>
      <div className="p-3">
        {data && (
          <div>
            <ReactECharts
              option={options}
              style={{ height: "400px", width: "100%" }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SubscriptionChart;
