import React from "react";

const MailScreen = () => {
  const supportEmail = "support@platinumfuse.com";

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-900 text-white p-4">
      <h1 className="text-2xl md:text-3xl font-bold mb-4">
        PlatinumFuse Support
      </h1>
      <p className="text-center mb-6 max-w-md">
        For any support assistance or inquiries, please contact our support
        team. We're here to help you with any questions or issues you may have.
      </p>
      <a
        href={`mailto:${supportEmail}`}
        className="bg-amber-500 text-black py-2 px-4 rounded-full font-bold hover:bg-amber-600 transition duration-300"
      >
        Contact Support
      </a>
      <p className="mt-4 text-sm text-gray-400">
        Or email us directly at: {supportEmail}
      </p>
    </div>
  );
};

export default MailScreen;
