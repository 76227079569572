export const steps = [
  {
    id: "I",
    desc: "SECTION I",
    completed: false,
    highlighted: true,
    selected: true,
  },
  {
    id: "II",
    desc: "SECTION II",
    completed: false,
    highlighted: false,
    selected: false,
  },
];
