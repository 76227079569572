import React from "react";
import AddNotificationForm from "./AddNotificationForm";

const AddNotification = () => {
  return (
    <section className="flex flex-col items-center w-full min-w-[400px] ">
      <div className="w-full flex justify-center py-6">
        <header
          style={{ backgroundColor: "rgba(12, 17, 23, 1)" }}
          className="flex flex-col md:flex-row justify-center items-center py-6 px-6 md:px-10 rounded-md space-y-4 md:space-y-0 md:space-x-8 w-full max-w-[1020px]"
        >
          <h1 className="text-xl text-white capitalize">Send Push Notification</h1>
        </header>
      </div>

      <div className="w-full max-w-[1020px] px-4 md:px-0">
        <AddNotificationForm />
      </div>
    </section>
  );
};

export default AddNotification;
