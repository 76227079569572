import React, { useState } from "react";
import { useStepContext } from "../../../context/stepContext";
import CBFiles from "./cbfiles/CBFiles";
import SignupModal from "../../modal/SignupModal";
import { toast } from "react-toastify";
import { baseURL } from "../../../../utils/urls";
import { nigeriaCities } from "../utils";

const CBtwo = () => {
  const [speciality, setSpeciality] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [city, setCity] = useState("");
  // const [state, setState] = useState("");
  // const [country, setCountry] = useState("");
  const [errors, setErrors] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { handleStep } = useStepContext();

  const validateLinkedIn = (linkedin) => {
    const regex =
      /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|pub|profile)\/[A-Za-z0-9_-]+\/?$/;
    return regex.test(linkedin);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!speciality) newErrors.speciality = "Speciality is required";
    if (!linkedin) newErrors.linkedin = "LinkedIn is required";
    else if (!validateLinkedIn(linkedin))
      newErrors.linkedin = "Invalid LinkedIn web URL";
    if (!city) newErrors.city = "City is required";
    // if (!state) newErrors.state = "State is required";
    // if (!country) newErrors.country = "Country is required";
    if (files.length === 0) newErrors.resume = "Resume is required";

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setIsLoading(true);

      const formData = new FormData();
      formData.append("occupation", speciality);
      formData.append("linkedin", linkedin);
      formData.append("city", city);
      // formData.append("state", state);
      // formData.append("country", country);
      formData.append("_method", "PUT");
      if (files.length > 0) {
        formData.append("cv", files[0]);
      }

      const coachToken = localStorage.getItem("coachToken");
      const id = localStorage.getItem("browsercid");

      try {
        const { data } = await baseURL.post(`users/${id}`, formData, {
          headers: {
            Authorization: `Bearer ${coachToken}`,
            "Content-Type": "multipart/form-data",
          },
        });

        if (data?.errors) {
          toast.warning(data.message);
        } else {
          setIsModalOpen(true);
        }
      } catch (error) {
        toast.error("Network error. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="space-y-6 p-4">
        <div className="space-y-6 md:space-y-0 md:grid md:grid-cols-2 md:gap-[10rem]">
          <div className="space-y-4">
            <div className="space-y-1">
              <label className="text-white capitalize">Speciality</label>
              <input
                type="text"
                value={speciality}
                onChange={(e) => setSpeciality(e.target.value)}
                onFocus={() =>
                  setErrors((prev) => ({ ...prev, speciality: "" }))
                }
                className="bg-gray-700 text-white shadow-sm w-full rounded-lg p-2"
                placeholder="Accountant"
                required
              />
              {errors.speciality && (
                <p className="text-red-500 text-sm">{errors.speciality}</p>
              )}
            </div>

            <div className="space-y-1">
              <label className="text-white capitalize">LinkedIn</label>
              <input
                type="text"
                value={linkedin}
                onChange={(e) => setLinkedin(e.target.value)}
                onFocus={() => setErrors((prev) => ({ ...prev, linkedin: "" }))}
                className="bg-gray-700 text-white shadow-sm w-full rounded-lg p-2"
                placeholder="https://www.linkedin.com/in/john-doe"
                required
              />
              {errors.linkedin && (
                <p className="text-red-500 text-sm">{errors.linkedin}</p>
              )}
            </div>

            <div className="space-y-1">
              <label className="text-white capitalize">City</label>
              <select
                value={city}
                onChange={(e) => setCity(e.target.value)}
                onFocus={() => setErrors((prev) => ({ ...prev, city: "" }))}
                className="bg-gray-700 text-white shadow-sm w-full rounded-lg p-2"
                required
              >
                <option value="">Select your city</option>
                {nigeriaCities.map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </select>
              {errors.city && (
                <p className="text-red-500 text-sm">{errors.city}</p>
              )}
            </div>
          </div>

          <div className="space-y-4">
            <CBFiles text="Upload resume" setFiles={setFiles} />
            {errors.resume && (
              <p className="text-red-500 text-sm">{errors.resume}</p>
            )}
          </div>
        </div>

        <div className="mt-8 flex space-x-10 justify-end">
          <button
            onClick={() => handleStep(1)}
            className="px-8 py-2 md:px-16 md:py-2 bg-transparent border border-white font-bold text-white rounded-lg hover:text-black hover:bg-primary hover:border-primary"
          >
            Back
          </button>
          <button
            type="submit"
            className="px-8 py-2 md:px-16 md:py-2 bg-primary font-bold text-black rounded-lg hover:bg-amber-700"
            disabled={isLoading}
          >
            {isLoading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>
      <SignupModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
      />
    </>
  );
};

export default CBtwo;
