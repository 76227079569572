import React from "react";
import { AiOutlineClose } from "react-icons/ai";

const Modal = ({ reason, closeModal }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-70">
      <div className="bg-black rounded-lg max-w-xl w-full p-6 relative">
        <button
          onClick={closeModal}
          className="absolute top-4 right-4 text-red hover:text-gray-800"
        >
          <AiOutlineClose size={24} />
        </button>

        <h2 className="text-lg font-semibold mb-4 text-gray-800">
          Full Reason:
        </h2>
        <p className="text-white leading-relaxed">{reason}</p>

        <button
          onClick={closeModal}
          className="mt-6 bg-amber-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Modal;
