import React, { useState } from "react";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStepContext } from "../../../context/stepContext";
import Icon from "../../../../assets/coachassets/images-videos/logo2.png";
import { baseURL } from "../../../../utils/urls";
import { toast } from "react-toastify";

const CBOne = () => {
  const [selectedGender, setSelectedGender] = useState(null);
  const [birthday, setBirthday] = useState("");
  const [levelOfEducation, setLevelOfEducation] = useState("");
  const [institutionName, setInstitutionName] = useState("");
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [photoData, setPhotoData] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [nin, setNin] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { handleStep } = useStepContext();

  const handleSelectGender = (gender) => {
    const genderValue =
      gender === "male" ? "M" : gender === "female" ? "F" : "";
    setSelectedGender(genderValue);
    setErrors((prev) => ({ ...prev, gender: null }));
  };

  const validateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    return monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
      ? age - 1
      : age;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
    const phoneNumberPattern = /^[0-9]{11,}$/;
    if (!selectedGender) newErrors.gender = "Gender is required";
    if (!birthday) {
      newErrors.birthday = "Birthday is required";
    } else if (validateAge(birthday) < 18) {
      newErrors.birthday = "You must be at least 18 years old to onboard";
    }
    if (!levelOfEducation)
      newErrors.levelOfEducation = "Level of education is required";
    if (!institutionName)
      newErrors.institutionName = "Institution name is required";
    if (!profilePhoto) newErrors.profilePhoto = "Profile photo is required";
    if (!phoneNumber) {
      newErrors.phoneNumber = "Phone number is required";
    } else if (!phoneNumberPattern.test(phoneNumber)) {
      newErrors.phoneNumber = "Phone number must be at least 11 digits";
    }
    if (!nin) newErrors.nin = "NIN is required";

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setIsLoading(true);

      const payload = new FormData();
      payload.append("gender", selectedGender);
      payload.append("dob", birthday);
      payload.append("education", levelOfEducation);
      payload.append("institute", institutionName);
      payload.append("photo", photoData);
      payload.append("phone", phoneNumber);
      payload.append("nin", nin);
      payload.append("role", "coach");
      payload.append("_method", "PUT");

      const coachToken = localStorage.getItem("coachToken");
      const id = localStorage.getItem("browsercid");

      try {
        const { data } = await baseURL.post(`users/${id}`, payload, {
          headers: {
            Authorization: `Bearer ${coachToken}`,
            "Content-Type": "multipart/form-data",
          },
        });

        if (data?.errors) {
          toast.warning(data.message);
        } else {
          handleStep(2);
        }
      } catch (error) {
        alert("Network error. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleClearError = (field) => {
    setErrors((prev) => ({ ...prev, [field]: null }));
  };

  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setErrors((prev) => ({
          ...prev,
          profilePhoto: "File size should be less than or equal to 5MB",
        }));
      } else {
        setPhotoData(file);
        const reader = new FileReader();
        reader.onload = (e) => {
          setProfilePhoto(e.target.result);
        };
        reader.readAsDataURL(file);
        handleClearError("profilePhoto");
      }
    }
  };

  return (
    <>
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-transparent bg-opacity-50">
          <div className="bg-black p-6 rounded-lg text-center">
            <img
              src={Icon}
              alt="Loading..."
              className="w-16 h-16 mx-auto mb-4"
            />
            <p className="text-lg font-bold text-white">
              Thank you for your patience.
            </p>
            <p className="mt-2 text-white">
              One more step remaining to complete your onboarding process.
            </p>
          </div>
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6 p-4">
        <div className="space-y-6 md:space-y-0 gap-[5rem] md:grid md:grid-cols-2 xl:gap-[10rem]">
          <div className="space-y-4">
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
              {["Male", "Female"].map((gender) => (
                <div
                  key={gender}
                  className={`px-3 py-2 text-white rounded-md text-center cursor-pointer ${
                    selectedGender === (gender === "Male" ? "M" : "F")
                      ? "bg-blue-500"
                      : "bg-gray-700"
                  }`}
                  onClick={() => handleSelectGender(gender.toLowerCase())}
                >
                  {gender}
                </div>
              ))}
            </div>

            {errors.gender && (
              <p className="text-red-500 text-sm">{errors.gender}</p>
            )}

            <div className="space-y-1">
              <label className="text-white capitalize">Date of Birth</label>
              <input
                type="date"
                value={birthday}
                onChange={(e) => {
                  setBirthday(e.target.value);
                  handleClearError("birthday");
                }}
                className="bg-gray-700 text-white shadow-sm w-full rounded-lg p-2 placeholder-gray-500"
                required
              />
              {errors.birthday && (
                <p className="text-red-500 text-sm">{errors.birthday}</p>
              )}
            </div>

            <div className="space-y-1">
              <label className="text-white capitalize">
                Level of Education
              </label>
              <select
                value={levelOfEducation}
                onChange={(e) => {
                  setLevelOfEducation(e.target.value);
                  handleClearError("levelOfEducation");
                }}
                className="bg-gray-700 text-white shadow-sm w-full rounded-lg p-2"
                required
              >
                <option value="">Select</option>
                <option value="hsd">High School</option>
                <option value="asd">Associate's Degree</option>
                <option value="bsc">Bachelor's Degree</option>
                <option value="msc">Master's Degree</option>
                <option value="doc">Doctorate</option>
              </select>
              {errors.levelOfEducation && (
                <p className="text-red-500 text-sm">
                  {errors.levelOfEducation}
                </p>
              )}
            </div>

            <div className="space-y-1">
              <label className="text-white capitalize">
                Name of Institution
              </label>
              <input
                type="text"
                value={institutionName}
                onChange={(e) => {
                  setInstitutionName(e.target.value);
                  handleClearError("institutionName");
                }}
                placeholder="University of Benin"
                className="bg-gray-700 text-white shadow-sm w-full rounded-lg p-2"
                required
              />
              {errors.institutionName && (
                <p className="text-red-500 text-sm">{errors.institutionName}</p>
              )}
            </div>
          </div>

          <div className="space-y-4">
            <div className="text-center">
              <label htmlFor="profile-photo" className="cursor-pointer">
                <div className="border-primary bg-primary border-2 rounded-full mt-4 w-32 h-32 m-auto">
                  {profilePhoto ? (
                    <img
                      src={profilePhoto}
                      alt="Profile"
                      className="w-full h-full rounded-full"
                    />
                  ) : (
                    <div className="flex justify-center items-center w-full h-full">
                      <FontAwesomeIcon icon={faCamera} className="text-5xl" />
                    </div>
                  )}
                </div>
                <p className="max-w-sm mt-4 py-2 px-4 mx-auto border-2 border-primary rounded-lg cursor-pointer text-sm font-bold uppercase bg-primary">
                  Upload Photo
                </p>
              </label>
              <input
                id="profile-photo"
                name="profilePhoto"
                type="file"
                accept="image/*"
                onChange={handlePhotoUpload}
                className="hidden"
              />
              {errors.profilePhoto && (
                <p className="text-red-500 text-sm">{errors.profilePhoto}</p>
              )}
            </div>
            <div className="space-y-1">
              <label className="text-white capitalize">Phone Number</label>
              <input
                type="text"
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                  handleClearError("phoneNumber");
                }}
                placeholder="+23490389629"
                className="bg-gray-700 text-white shadow-sm w-full rounded-lg p-2"
                required
              />
              {errors.phoneNumber && (
                <p className="text-red-500 text-sm">{errors.phoneNumber}</p>
              )}
            </div>

            <div className="space-y-1">
              <label className="text-white capitalize">NIN</label>
              <input
                type="text"
                value={nin}
                onChange={(e) => {
                  setNin(e.target.value);
                  handleClearError("nin");
                }}
                placeholder="123456781011"
                className="bg-gray-700 text-white shadow-sm w-full rounded-lg p-2"
                required
              />
              {errors.nin && (
                <p className="text-red-500 text-sm">{errors.nin}</p>
              )}
            </div>
          </div>
        </div>

        <div className="mt-8 flex justify-end">
          <button
            type="submit"
            className="px-4 py-2 bg-primary font-bold text-black rounded-lg hover:bg-amber-700"
          >
            Save & Continue
          </button>
        </div>
      </form>
    </>
  );
};

export default CBOne;
