import React, { useState, useCallback } from "react";
import { FaImage, FaTimes } from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useDropzone } from "react-dropzone";
import Swal from "sweetalert2";

const Preview = ({ setCoverImage }) => {
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleImageUpload = async (file) => {
    if (file) {
      if (file.size > 3 * 1024 * 1024) {
        Swal.fire({
          icon: "error",
          title: "File too large",
          text: "The image should be 5MB or less.",
          confirmButtonColor: "#3085d6",
        });
        return;
      }
      
      setLoading(true);
      try {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result);
          setCoverImage(file);
          setLoading(false);
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.error("Error uploading image:", error);
        setLoading(false);
      }
    }
  };

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Invalid file type",
        text: "Please upload an image file.",
        confirmButtonColor: "#3085d6",
      });
    } else if (acceptedFiles.length > 0) {
      handleImageUpload(acceptedFiles[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    maxFiles: 1,
  });

  const removeImage = () => {
    setImage(null);
    setCoverImage(null);
  };

  return (
    <section
      {...getRootProps()}
      className="flex flex-col items-center justify-center bg-gray-200 p-8 rounded-xl shadow-xl w-full h-full"
    >
      <input {...getInputProps()} />
      <div className="w-full">
        {loading ? (
          <div className="flex flex-col items-center justify-center">
            <AiOutlineLoading3Quarters className="text-6xl text-gray-900 mb-4 animate-spin" />
            <p className="text-gray-900 font-bold md:text-xl">Uploading...</p>
          </div>
        ) : image ? (
          <div className="relative">
            <img
              src={image}
              alt="Cover Preview"
              className="w-full h-auto rounded-lg"
            />
            <button
              type="button"
              onClick={removeImage}
              className="absolute top-2 right-2 bg-red-600 text-white rounded-full p-2 hover:bg-red-800"
            >
              <FaTimes className="text-lg" />
            </button>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-full">
            <FaImage className="text-6xl text-gray-900 mb-4" />
            <p className="mb-4 text-gray-900 font-bold md:text-xl">
              No image selected
            </p>
            <label
              htmlFor="coverImage"
              className="px-6 py-2 bg-primary rounded-md text-black cursor-pointer"
            >
              Upload Image
            </label>
            <input
              type="file"
              id="coverImage"
              accept="image/*"
              onChange={(e) => handleImageUpload(e.target.files[0])}
              className="hidden"
            />
            <p className="mt-4 text-gray-600">Or drag & drop a cover image here</p>
          </div>
        )}
      </div>
    </section>
  );
};

export default Preview;
