import React from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Details from "./Details";
import Schedule from "./schedule/Schedule";
import Duration from "./duration/Duration";
import { adminURL } from "../../../../../utils/urls";
import Buttons from "./buttons/Buttons";

const fetchSlotData = async (id) => {
  const response = await adminURL.get(`slots/${id}`);
  return response?.data?.data;
};

const cancelAppointment = async (id) => {
  const response = await adminURL.delete(`appointments/${id}`);
  
  return response.data;
};

const PendingProfile = () => {
  const id = localStorage.getItem("originalID") || 150;
  const queryClient = useQueryClient();

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ["appointment", id],
    queryFn: () => fetchSlotData(id),
    enabled: !!id,
    refetchInterval: 10000,
  });

  const { mutate: cancelSlot, isLoading: isCancelling } = useMutation({
    mutationFn: () => cancelAppointment(id),
    onSuccess: () => {
      queryClient.invalidateQueries(["appointment", id]);
    },
  });

  if (isLoading) {
    return (
      <section className="w-full flex justify-center lg:items-start xl:items-center">
        <div className="flex flex-col justify-center items-center h-full">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-500"></div>
          <p className="mt-2 text-gray-500">Loading...</p>
        </div>
      </section>
    );
  }

  // if (isFetching) {
  //   return (
  //     <section className="w-full flex justify-center lg:items-start xl:items-center">

  //     </section>
  //   );
  // }

  if (!data || data?.data?.length === 0) {
    return null;
  }

  return (
    <section className="w-full flex justify-center lg:items-start xl:items-center">
      <section className="flex flex-col">
        <div className="text-center">
          <img
            src={data?.slot?.booker?.photo_url}
            alt="pending profile img"
            className="mx-auto h-40 w-40 rounded-full object-cover aspect-square"
          />
        </div>
        <Details data={data} />
        <Schedule data={data} />
        <Duration data={data} />
        <Buttons
          data={data}
          onCancel={() => cancelSlot(id)}
          isCancelling={isCancelling}
        />
      </section>
    </section>
  );
};

export default PendingProfile;
