import React, { useState } from "react";
import docPdf from "../../assets/images/docpdf.png";

const Cdetails = ({ user }) => {
  const formattedDob = new Date(user?.dob)
    .toISOString()
    .split("T")[0]
    .replace(/-/g, "/");

  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="p-4 space-y-4">
      {/* Gender */}
      <div className="space-y-1">
        <label className="text-white capitalize">Gender</label>
        <input
          type="text"
          value={user?.gender === "F" ? "Female" : "Male"}
          readOnly
          className="bg-gray-700 outline-none text-white shadow-sm w-full rounded-lg p-2"
        />
      </div>

      {/* First Name */}
      <div className="space-y-1">
        <label className="text-white capitalize">First Name</label>
        <input
          type="text"
          value={user.first_name}
          readOnly
          className="bg-gray-700 outline-none text-white shadow-sm w-full rounded-lg p-2"
        />
      </div>

      {/* Birthday */}
      <div className="space-y-1">
        <label className="text-white capitalize">Birthday</label>
        <input
          type="text"
          value={formattedDob}
          readOnly
          className="bg-gray-700 outline-none text-white shadow-sm w-full rounded-lg p-2"
        />
      </div>

      {/* Level of Education */}
      <div className="space-y-1">
        <label className="text-white capitalize">Level of Education</label>
        <input
          type="text"
          value={user?.education}
          readOnly
          className="bg-gray-700 outline-none text-white shadow-sm w-full rounded-lg p-2"
        />
      </div>

      {/* Name of Institution */}
      <div className="space-y-1">
        <label className="text-white capitalize">Name of Institution</label>
        <input
          type="text"
          value={user?.institute}
          readOnly
          className="bg-gray-700 outline-none text-white shadow-sm w-full rounded-lg p-2"
        />
      </div>

      {/* Speciality */}
      <div className="space-y-1">
        <label className="text-white capitalize">Speciality</label>
        <input
          type="text"
          value={user?.occupation}
          readOnly
          className="bg-gray-700 outline-none text-white shadow-sm w-full rounded-lg p-2"
        />
      </div>

      {/* Email */}
      <div className="space-y-1">
        <label className="text-white capitalize">Email</label>
        <input
          type="text"
          value={user?.email}
          readOnly
          className="bg-gray-700 outline-none text-white shadow-sm w-full rounded-lg p-2"
        />
      </div>

      {/* LinkedIn */}
      <div className="space-y-1">
        <label className="text-white capitalize">LinkedIn</label>
        <input
          type="text"
          value={user?.linkedin}
          readOnly
          className="bg-gray-700 outline-none text-white shadow-sm w-full rounded-lg p-2"
        />
      </div>

      {/* City */}
      <div className="space-y-1">
        <label className="text-white capitalize">City</label>
        <input
          type="text"
          value={user?.city}
          readOnly
          className="bg-gray-700 outline-none text-white shadow-sm w-full rounded-lg p-2"
        />
      </div>

      {/* State */}
      {/* <div className="space-y-1">
        <label className="text-white capitalize">State</label>
        <input
          type="text"
          value={user.state}
          readOnly
          className="bg-gray-700 outline-none text-white shadow-sm w-full rounded-lg p-2"
        />
      </div> */}

      {/* Country */}
      {/* <div className="space-y-1">
        <label className="text-white capitalize">Country</label>
        <input
          type="text"
          value={user.country}
          readOnly
          className="bg-gray-700 outline-none text-white shadow-sm w-full rounded-lg p-2"
        />
      </div> */}

      {/* Uploaded Resume */}
      <div className="space-y-1">
        <label className="text-white capitalize">Uploaded Resume</label>
        <div className="bg-gray-700 outline-none text-white shadow-sm w-full rounded-lg p-2">
          <a
            href={user?.cv_url}
            target="_blank"
            rel="noopener noreferrer"
            className="relative inline-block"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <img src={docPdf} alt="Resume" className="w-20 h-20" />
            {isHovered && (
              <div className="absolute inset-0 bg-black bg-opacity-70 flex items-center justify-center text-white text-sm text-center p-2 rounded">
                Click to view resume
              </div>
            )}
          </a>
        </div>
      </div>

      {/* Additional Documents */}
      {/* <div className="space-y-1">
        <label className="text-white capitalize">Additional Documents</label>
        <div className="bg-gray-700 outline-none text-white shadow-sm w-full rounded-lg p-2">
          <img
            src={user.additionalDocuments}
            alt="Additional Documents"
            className="w-20 h-20 "
          />
        </div>
      </div> */}

      {/* NIN */}
      <div className="space-y-1">
        <label className="text-white capitalize">NIN</label>
        <input
          type="text"
          value={user?.nin}
          readOnly
          className="bg-gray-700 outline-none text-white shadow-sm w-full rounded-lg p-2"
        />
      </div>
    </div>
  );
};

export default Cdetails;
