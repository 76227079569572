import React, { useState } from "react";

const Navigation = ({ onNavigate }) => {
  const [activeNav, setActiveNav] = useState("User demographics");

  const navigators = [
    "User demographics",
    "User engagements",
    "User interaction",
    "Conversion & Monetization",
    "Review & ratings",
  ];

  const handleNavClick = (nav) => {
    setActiveNav(nav);
    onNavigate(nav);
  };

  return (
    <div className="flex space-x-4 p-4 ml-4">
      {navigators.map((nav) => (
        <button
          key={nav}
          className={`py-2 px-4 rounded-md cursor-pointer ${
            activeNav === nav
              ? "bg-amber-500 text-black font-bold shadow-md shadow-gray-900/10"
              : "bg-[#131A22] text-white border border-white"
          }`}
          onClick={() => handleNavClick(nav)}
        >
          {nav}
        </button>
      ))}
    </div>
  );
};

export default Navigation;
