import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { adminURL } from "../../../../utils/urls";
import axios from "axios";
import { nigeriaCities } from "../../cSignup/utils";
import Loader from "../../../../components/loader/FetchLoader";

const PersonalInfo = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [userData, setUserData] = useState(null);
  const [errors, setErrors] = useState({});
  const [profilePhoto, setProfilePhoto] = useState("");
  const [photoData, setPhotoData] = useState(null);
  const id = localStorage.getItem("refreshID");

  // New state for tracking changes
  const [updatedData, setUpdatedData] = useState({});

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await adminURL.get(`users/${id}`);
      setUserData(response.data?.data?.user);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatDisplayDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setUpdatedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "linkedin") {
      if (!validateLinkedIn(value)) {
        setErrors((prev) => ({ ...prev, linkedin: "Invalid LinkedIn URL" }));
      } else {
        setErrors((prev) => ({ ...prev, linkedin: null }));
      }
    }
  };

  const validateLinkedIn = (linkedin) => {
    const regex =
      /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|pub|profile)\/[A-Za-z0-9_-]+\/?$/;
    return regex.test(linkedin);
  };

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    if (errors.linkedin) {
      return;
    }
    setIsSaving(true);

    const token = localStorage.getItem("refID");
    let dataToSend;
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (photoData) {
      dataToSend = new FormData();
      dataToSend.append(
        "first_name",
        updatedData.first_name || userData.first_name
      );
      dataToSend.append(
        "last_name",
        updatedData.last_name || userData.last_name
      );
      dataToSend.append(
        "occupation",
        updatedData.occupation || userData.occupation
      );
      dataToSend.append(
        "institute",
        updatedData.institute || userData.institute
      );
      dataToSend.append("rate", updatedData.rate || userData.rate);
      dataToSend.append(
        "education",
        updatedData.education || userData.education
      );
      dataToSend.append("linkedin", updatedData.linkedin || userData.linkedin);
      dataToSend.append("city", updatedData.city || userData.city);
      dataToSend.append("photo", photoData);
      dataToSend.append("_method", "PATCH");
      config.headers["Content-Type"] = "multipart/form-data";

      try {
        await axios.post(
          `https://platinumfuse.v2napi.com/api/users/${id}`,
          dataToSend,
          config
        );
      } catch (error) {
        console.error("Error saving changes:", error);
      }
    } else {
      dataToSend = {
        first_name: updatedData.first_name || userData.first_name,
        last_name: updatedData.last_name || userData.last_name,
        occupation: updatedData.occupation || userData.occupation,
        institute: updatedData.institute || userData.institute,
        rate: updatedData.rate || userData.rate,
        education: updatedData.education || userData.education,
        linkedin: updatedData.linkedin || userData.linkedin,
        city: updatedData.city || userData.city,
      };
      config.headers["Content-Type"] = "application/json";

      try {
        await axios.patch(
          `https://platinumfuse.v2napi.com/api/users/${id}`,
          dataToSend,
          config
        );
      } catch (error) {
        console.error("Error saving changes:", error);
      }
    }

    setIsSaving(false);
    window.location.reload();
  };

  const handleClearError = (field) => {
    setErrors((prev) => ({ ...prev, [field]: null }));
  };

  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setErrors((prev) => ({
          ...prev,
          profilePhoto: "File size should be less than or equal to 5MB",
        }));
      } else {
        setPhotoData(file);
        const reader = new FileReader();
        reader.onload = (e) => {
          setProfilePhoto(e.target.result);
        };
        reader.readAsDataURL(file);
        handleClearError("profilePhoto");
      }
    }
  };

  if (isLoading) {
    return <Loader text="profile information..." />;
  }

  return (
    <form
      className="flex flex-col items-center p-4 w-full"
      onSubmit={handleSaveChanges}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full">
        <div className="flex flex-col w-full">
          <div className="space-y-4 w-full">
            <div className="space-y-1 relative">
              <label className="text-white capitalize">Gender</label>
              <input
                type="text"
                name="gender"
                value={userData?.gender === "F" ? "Female" : "Male"}
                readOnly
                onChange={handleInputChange}
                className="bg-gray-700 text-white shadow-sm w-full rounded-lg p-2 pr-10 cursor-not-allowed"
              />
              {/* <FontAwesomeIcon
                icon={faPen}
                className="absolute right-3 top-10 text-white"
                title="Edit Gender"
              /> */}
            </div>
            <div className="space-y-1 relative">
              <label className="text-white capitalize">First Name</label>
              <input
                type="text"
                name="first_name"
                value={userData?.first_name}
                readOnly
                onChange={handleInputChange}
                className="bg-gray-700 text-white shadow-sm w-full rounded-lg p-2 pr-10 cursor-not-allowed"
              />
              {/* <FontAwesomeIcon
                icon={faPen}
                className="absolute right-3 top-10 text-white"
                title="Edit First Name"
              /> */}
            </div>
            <div className="space-y-1 relative">
              <label className="text-white capitalize">Last Name</label>
              <input
                type="text"
                name="last_name"
                value={userData?.last_name}
                readOnly
                onChange={handleInputChange}
                className="bg-gray-700 text-white shadow-sm w-full rounded-lg p-2 pr-10 cursor-not-allowed"
              />
              {/* <FontAwesomeIcon
                icon={faPen}
                className="absolute right-3 top-10 text-white"
                title="Edit Last Name"
              /> */}
            </div>
            <div className="space-y-1 relative">
              <label className="text-white capitalize">Specialty</label>
              <input
                type="text"
                name="occupation"
                value={userData?.occupation}
                onChange={handleInputChange}
                className="bg-gray-700 text-white shadow-sm w-full rounded-lg p-2 pr-10"
              />
              <FontAwesomeIcon
                icon={faPen}
                className="absolute right-3 top-10 text-white"
                title="Edit Specialty"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div className="space-y-4 w-full">
            <div className="text-center">
              <label htmlFor="profile-photo" className="cursor-pointer">
                <div className="border-primary bg-primary border-2 rounded-full mt-4 w-32 h-32 m-auto">
                  {profilePhoto ? (
                    <img
                      src={profilePhoto}
                      alt="Profile"
                      className="w-full h-full rounded-full"
                    />
                  ) : (
                    <div className="flex justify-center items-center w-full h-full">
                      <FontAwesomeIcon icon={faCamera} className="text-5xl" />
                    </div>
                  )}
                </div>
                <p className="max-w-sm mt-4 py-2 px-4 mx-auto border-2 border-primary rounded-lg cursor-pointer text-sm font-bold uppercase bg-primary">
                  Upload Photo
                </p>
              </label>
              <input
                id="profile-photo"
                name="profilePhoto"
                type="file"
                accept="image/*"
                onChange={handlePhotoUpload}
                className="hidden"
              />
              {errors.profilePhoto && (
                <p className="text-red-500 text-sm">{errors.profilePhoto}</p>
              )}
            </div>
            <div className="space-y-1 relative">
              <label className="text-white capitalize">Phone Number</label>
              <input
                type="text"
                name="phone"
                value={userData?.phone}
                readOnly
                // onChange={handleInputChange}
                className="bg-gray-700 text-white shadow-sm w-full rounded-lg p-2 pr-10 cursor-not-allowed"
              />
              {/* <FontAwesomeIcon
                icon={faPen}
                className="absolute right-3 top-10 text-white"
                title="Edit Phone Number"
              /> */}
              {/* {errors.phone && (
                <p className="text-red-500 text-sm mt-1">{errors.phone}</p>
              )} */}
            </div>
          </div>
        </div>
      </div>

      <hr className="border-primary w-full my-8" />

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full">
        <div className="flex flex-col space-y-2 w-full">
          <div className="space-y-1 relative">
            <label className="text-white capitalize">Name of Institution</label>
            <input
              type="text"
              name="institute"
              value={userData?.institute}
              readOnly
              onChange={handleInputChange}
              className="bg-gray-700 text-white shadow-sm w-full rounded-lg p-2 pr-10 cursor-not-allowed"
            />
            {/* <FontAwesomeIcon
              icon={faPen}
              className="absolute right-3 top-10 text-white"
              title="Edit Name of Institution"
            /> */}
          </div>
          <div className="space-y-1 relative py-2">
            <label className="text-white capitalize">NIN</label>
            <input
              type="text"
              value={userData?.nin}
              readOnly
              className="bg-gray-700 text-white shadow-sm w-full rounded-lg p-2 pr-10 cursor-not-allowed"
            />
          </div>
          <div className="space-y-1 relative">
            <label className="text-white capitalize">Rate</label>
            <input
              type="text"
              name="rate"
              value={userData?.rate}
              onChange={handleInputChange}
              className="bg-gray-700 text-white shadow-sm w-full rounded-lg p-2 pr-10"
            />
            <FontAwesomeIcon
              icon={faPen}
              className="absolute right-3 top-10 text-white"
              title="Edit Rate"
            />
          </div>
          {/* 
          <div className="space-y-1 relative">
            <label className="text-white capitalize">Date of Birth</label>
            {isEditing ? (
              <input
                type="date"
                name="dob"
                value={formatDate(userData?.dob)}
                onChange={handleInputChange}
                className="bg-gray-700 text-white shadow-sm w-full rounded-lg p-2 pr-10"
              />
            ) : (
              <input
                type="text"
                name="dob"
                value={formatDisplayDate(userData?.dob)}
                readOnly
                className="bg-gray-700 text-white shadow-sm w-full rounded-lg p-2 pr-10"
              />
            )}
            <FontAwesomeIcon
              icon={isEditing ? faSave : faPen}
              className="absolute right-3 top-10 text-white cursor-pointer"
              title={isEditing ? "Save Date of Birth" : "Edit Date of Birth"}
              onClick={toggleEdit}
            />
          </div> */}

          <div className="space-y-1 relative">
            <label className="text-white capitalize">Date of Birth</label>

            <input
              type="text"
              name="dob"
              value={formatDisplayDate(userData?.dob)}
              readOnly
              className="bg-gray-700 text-white shadow-sm w-full rounded-lg p-2 pr-10 cursor-not-allowed"
            />
          </div>
        </div>

        <div className="flex flex-col space-y-2 w-full">
          <div className="space-y-1 relative">
            <label className="text-white capitalize">Education</label>
            <select
              name="education"
              value={userData?.education}
              onChange={handleInputChange}
              className="bg-gray-700 text-white shadow-sm w-full rounded-lg p-2 pr-10"
            >
              <option value="">Select Education Level</option>
              <option value="hsd">High School</option>
              <option value="asd">Associate's Degree</option>
              <option value="bsc">Bachelor's Degree</option>
              <option value="msc">Master's Degree</option>
              <option value="doc">Doctorate</option>
            </select>
            {/* <FontAwesomeIcon
              icon={faPen}
              className="absolute right-3 top-10 text-white"
              title="Edit Education"
            /> */}
          </div>
          <div className="space-y-2 relative">
            <label className="text-white capitalize">LinkedIn Profile</label>
            <input
              type="text"
              name="linkedin"
              value={userData?.linkedin}
              onChange={handleInputChange}
              className="bg-gray-700 text-white shadow-sm w-full rounded-lg p-2 pr-10"
            />
            <FontAwesomeIcon
              icon={faPen}
              className="absolute right-3 top-10 text-white"
              title="Edit LinkedIn Profile"
            />
            {errors.linkedin && (
              <p className="text-red-500 text-sm mt-1">{errors.linkedin}</p>
            )}
          </div>
          <div className="space-y-2 relative">
            <label className="text-white capitalize">Email</label>
            <input
              type="email"
              name="email"
              value={userData?.email}
              readOnly
              // onChange={handleInputChange}
              className="bg-gray-700 text-white shadow-sm w-full rounded-lg p-2 pr-10 cursor0not-allowed"
            />
            {/* <FontAwesomeIcon
              icon={faPen}
              className="absolute right-3 top-10 text-white"
              title="Edit Email"
            /> */}
          </div>

          <div className="space-y-2 relative">
            <label className="text-white capitalize">City</label>
            <select
              name="city"
              value={userData?.city}
              onChange={handleInputChange}
              className="bg-gray-700 text-white shadow-sm w-full rounded-lg p-2"
              required
            >
              <option value="">Select your city</option>
              {nigeriaCities.map((city, index) => (
                <option key={index} value={city}>
                  {city}
                </option>
              ))}
            </select>
            {errors.city && (
              <p className="text-red-500 text-sm">{errors.city}</p>
            )}
          </div>
        </div>
      </div>

      <div className="flex justify-end mt-8 w-full">
        <button
          type="submit"
          disabled={isSaving}
          className="bg-primary text-white rounded-lg py-2 px-4"
        >
          {isSaving ? "Saving..." : "Save Changes"}
        </button>
      </div>
    </form>
  );
};

export default PersonalInfo;
