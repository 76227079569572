// FilesUpload Component
import React from "react";
import Top from "./Top";
import Bottom from "./Bottom";

const FilesUpload = ({
  setTitle,
  title,
  setDescription,
  description,
  setCoverImage,
  imagePreview,
  setFile,
  setPrice,
  price,
}) => {
  return (
    <section className="px-10">
      <div>
        <Top
          setTitle={setTitle}
          title={title}
          setCoverImage={setCoverImage}
          imagePreview={imagePreview}
          setFile={setFile}
        />
        <Bottom
          setPrice={setPrice}
          price={price}
          setDescription={setDescription}
          description={description}
        />
      </div>
    </section>
  );
};

export default FilesUpload;
