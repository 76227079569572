import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { IoCloudUpload, IoTrashBin, IoAlertCircle } from "react-icons/io5";
import { ImSpinner2 } from "react-icons/im";
import Swal from "sweetalert2";

const DragFile = ({ setFile }) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      setLoading(true);

      setTimeout(() => {
        if (rejectedFiles.length > 0) {
          Swal.fire({
            icon: "error",
            title: "Invalid file type",
            text: "Only documents (pdf, doc, docx) are allowed.",
            confirmButtonColor: "#3085d6",
          });
          setLoading(false);
          return;
        }

        const validFiles = acceptedFiles.filter(
          (file) => file.size <= 5 * 1024 * 1024 
        );

        if (validFiles.length < acceptedFiles.length) {
          Swal.fire({
            icon: "error",
            title: "File too large",
            text: "Each file should be 5MB or less.",
            confirmButtonColor: "#3085d6",
          });
          setLoading(false);
          return;
        }

        setFiles(validFiles);
        setLoading(false);
        if (validFiles.length > 0) {
          setFile(validFiles[0]);
        }
      }, 2000);
    },
    [setFile]
  );

  const removeFile = (file) => {
    setFiles(files.filter((f) => f.path !== file.path));
    setFile(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
        ".docx",
      ],
    },
  });

  return (
    <section className="bg-gray-200 p-8 rounded-xl shadow-xl">
      <div
        {...getRootProps()}
        className="flex flex-col items-center justify-center p-10 rounded-md w-full max-w-lg relative"
      >
        <input {...getInputProps()} />
        {loading ? (
          <ImSpinner2 className="text-6xl text-gray-900 mb-4 animate-spin" />
        ) : (
          <>
            <IoCloudUpload className="text-6xl text-gray-900 mb-4" />
            <p className="mb-4 text-gray-900 font-bold md:text-xl">
              Drop your file here
            </p>
            <button
              type="button"
              className="px-6 py-2 bg-primary rounded-md text-black"
            >
              Browse
            </button>
            <IoAlertCircle
              className="absolute top-2 right-2 text-xl text-gray-900"
              title="Allowed file types: pdf, doc, docx (Max 5MB)"
            />
          </>
        )}
      </div>
      <div className="mt-4 w-full max-w-lg">
        {files.map((file) => (
          <div
            key={file.path}
            className="flex items-center justify-between p-4 bg-white rounded-md shadow-md"
          >
            <p className="text-gray-900">{file.path}</p>
            <button
              type="button"
              onClick={() => removeFile(file)}
              className="text-red-600 hover:text-red-800"
            >
              <IoTrashBin className="text-xl" />
            </button>
          </div>
        ))}
      </div>
    </section>
  );
};

export default DragFile;
