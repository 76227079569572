import React, { useState } from "react";
import { Link, useParams, useNavigate} from "react-router-dom";
import { adminURL } from "../../../../../utils/urls";

const SendIv = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const { eventID } = useParams(); 
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  const handleSendClick = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      const response = await adminURL.post(
        `/events/${eventID}/broadcast`,
        { message },
        {
          headers: {
            Accept: "application/json",
          },
        }
      );

      if (response.status === 200) {
        setIsModalOpen(true);

      }
          setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error("Error sending message:", error);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    navigate('/dashboard/events/create')
  };

  return (
    <section className="w-full px-4 sm:px-10 pt-16">
      <form className="space-y-4 w-full">
        <div>
          <label htmlFor="message" className="block text-white mb-2">
            Message:
          </label>
          <textarea
            id="message"
            className="w-full sm:w-[450px] bg-gray-700 text-white p-2 rounded-md focus:outline-none h-32 resize-none"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>
        <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 items-center pt-4 pb-6">
          <Link
            to="/dashboard/events/create"
            className="text-white bg-transparent border py-2 px-10 border-white rounded-md w-full sm:w-auto text-center"
          >
            CANCEL
          </Link>
          <button
            type="submit"
            onClick={handleSendClick}
            className="bg-amber-500 text-black py-2 px-10 rounded-md font-bold w-full sm:w-auto"
          >
         {loading ? 'Broadcasting...' : 'SEND' }   
          </button>
        </div>
      </form>

      {isModalOpen && (
        <>
          <div
            className="fixed inset-0 bg-black bg-opacity-50 flex justify-center py-8 px-2 items-center cursor-pointer"
            onClick={closeModal}
          ></div>
          <div className="fixed inset-0 flex justify-center items-center px-4">
            <div className="bg-gray-900 p-6 text-center rounded-lg py-20 px-4 sm:px-10 relative">
              <button
                className="absolute top-2 right-2 text-white text-xl"
                onClick={closeModal}
              >
                x
              </button>
              <p className="text-white mb-4">
                Your message has been sent to users.
              </p>
              <button
                className="bg-amber-500 text-black py-2 px-10 rounded-md font-bold mt-6"
                onClick={closeModal}
              >
                OK
              </button>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default SendIv;
