import React, { useState } from "react";
import { adminURL } from "../../../utils/urls";
import { useNavigate } from "react-router-dom";

const DarkModal = ({ message, success, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-gray-800 rounded-lg p-6 max-w-sm w-full">
        <div
          className={`text-2xl mb-4 ${
            success ? "text-green-400" : "text-red-400"
          }`}
        >
          {success ? "✓" : "✗"}
        </div>
        <p className="text-white mb-4">{message}</p>
        <button
          onClick={onClose}
          className="bg-amber-500 text-black py-2 px-4 rounded-md font-bold hover:bg-amber-600 transition-colors"
        >
          Close
        </button>
      </div>
    </div>
  );
};

const AddNotificationForm = () => {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();

  const validateForm = () => {
    const newErrors = {};
    if (!title) newErrors.title = "Title is required";
    if (!message) newErrors.message = "Message is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsSubmitting(true);

    try {
      const response = await adminURL.post("notifications", {
        title,
        message,
      });

      if (response.status === 200) {
        setModalMessage("Notification successfully sent");
        setIsSuccess(true);
      } else {
        setModalMessage("Error sending notification");
        setIsSuccess(false);
      }
    } catch (error) {
      setModalMessage("Error sending notification");
      setIsSuccess(false);
    } finally {
      setModalVisible(true);
      setIsSubmitting(false);
    }
  };

  const handleModalClose = () => {
    setModalVisible(false);
    window.location.reload();
    // navigate("/dashboard/push-notifications");
  };

  const handleCancel = () => {
    navigate("/dashboard/push-notifications");
  };

  return (
    <div className="bg-gray-500 bg-opacity-10 w-full p-6 rounded-lg mx-auto max-w-5xl">
      {/* <button
        onClick={() => navigate("/dashboard/push-notifications")}
        className="mb-4 text-white hover:text-amber-500 transition-colors"
      >
        ← Back to Push Notifications
      </button> */}

      {modalVisible && (
        <DarkModal
          message={modalMessage}
          success={isSuccess}
          onClose={handleModalClose}
        />
      )}
      <form className="space-y-4 w-full" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="title" className="block text-white mb-2">
            Title
          </label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full md:w-[49%] bg-gray-700 text-white p-2 rounded-md px-2 py-4 shadow-sm focus:outline-none"
          />
          {errors.title && <p className="text-red-500">{errors.title}</p>}
        </div>
        <div>
          <label htmlFor="message" className="block text-white mb-2">
            Message
          </label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="w-full bg-gray-700 text-white p-2 rounded-md focus:outline-none h-32 resize-none"
          ></textarea>
          {errors.message && <p className="text-red-500">{errors.message}</p>}
        </div>
        <div className="flex flex-col md:flex-row justify-between items-center pt-4 space-y-4 md:space-y-0">
          <button
            type="button"
            onClick={handleCancel}
            className="text-white bg-transparent border py-2 px-10 border-white rounded-md hover:bg-gray-700 transition-colors"
          >
            CANCEL
          </button>
          <button
            type="submit"
            className="bg-amber-500 text-black py-2 px-10 rounded-md font-bold hover:bg-amber-600 transition-colors"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Sending..." : "SEND"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddNotificationForm;
