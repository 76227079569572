// Top Component
import React from "react";
import DragFile from "./DragFile";
import Preview from "./preview/Preview";

const Top = ({ setTitle, title, setCoverImage, imagePreview, setFile }) => {
  return (
    <section className="grid grid-cols-1 md:grid-cols-2 gap-8 p-4">
      <section className="flex flex-col space-y-10">
        <DragFile setFile={setFile} />
        <div className="flex flex-col space-y-2">
          <label className="text-lg font-bold text-white">
            Title <span>*</span>
          </label>
          <input
            type="text"
            value={title}
            required
            onChange={(e) => setTitle(e.target.value)}
            className="p-4 text-black font-bold bg-gray-200 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
          />
        </div>
      </section>
      <section className="px-10">
        <Preview setCoverImage={setCoverImage} imagePreview={imagePreview} />
      </section>
    </section>
  );
};

export default Top;
