import React from "react";
import Logo from "../assets/coachassets/images-videos/Logo.png";
// import Icon from "../assets/coachassets/images-videos/logo2.png";
import FullLogo from "../assets/coachassets/images-videos/Platinum-Fuse-Full-Logo.png";
import SignupForm from "../coaches-platform/components/clogin/SignupForm";
import { useLocation } from "react-router-dom";
import BasicDetails from "../coaches-platform/components/cSignup/basicDetails/BasicDetails";

const Signup = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const basicDetails = queryParams.get("basic_details") === "true";

  return (
    <>
      {!basicDetails ? (
        <div className="min-h-screen grid grid-cols-1 lg:grid-cols-2 bg-gray-700">
          <div className="flex flex-col justify-center items-center p-8 text-center space-y-5 tablet:bg-black tablet:bg-custom tablet:w-screen tablet:pt-20 w-full">
            <div className="flex justify-center items-center w-full">
              {/* <img
                src={Icon}
                alt="Platinum Fuse Icon"
                className="w-20 tablet:hidden"
              /> */}
              <img
                src={FullLogo}
                alt="Platinum Fuse Full Logo"
                className="w-3/6"
              />
            </div>
            <SignupForm />
          </div>
          <div className="hidden lg:flex justify-center items-center p-20 bg-black rounded-l-[100px] bg-custom">
            <img src={Logo} alt="Platinum Fuse Logo" />
          </div>
        </div>
      ) : (
        <BasicDetails />
      )}
    </>
  );
};

export default Signup;
