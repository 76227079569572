import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Details from "./Details";
import SmallDetails from "./SmallDetails";
import { adminURL } from "../../utils/urls";
import Loader from "../loader/FetchLoader";

const Display = () => {
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(true);

  const { id } = useParams();
  const fetchData = async (page) => {
    setLoading(true);
    try {
      const response = await adminURL.get(`users/${id}`);
      setUser(response.data.data?.user);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <section className="flex flex-col items-center justify-center min-h-screen p-4 sm:p-6 lg:p-8">
      {loading ? (
        <Loader text="Fetching user..." />
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-5xl w-full">
          {/* details */}
          <div className="flex flex-col items-center">
            {user ? (
              <Details user={user} />
            ) : (
              <p className="text-white">User not found</p>
            )}
          </div>
          {/* small details */}
          <div className="flex flex-col items-center">
            {user ? <SmallDetails user={user} /> : null}
          </div>
        </div>
      )}
    </section>
  );
};

export default Display;
