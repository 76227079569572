import React from "react";
import Publish from "./Publish";

const Publications = () => {
  return (
    <section>
      <div className="flex justify-center">
        <header
          style={{ backgroundColor: "rgba(12, 17, 23, 1)" }}
          className="flex justify-center border border-primary items-center py-6 px-14 md:px-10 rounded-md space-x-4 md:space-x-8 md:px-8 md:max-w-[1040px] w-full "
        >
          <h1 className="text-lg md:text-xl text-white">Publications</h1>
        </header>
      </div>
      <section className="max-w-5xl mx-auto">
        <Publish />
      </section>
    </section>
  );
};

export default Publications;
