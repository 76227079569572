import React from 'react';
import { CSSTransition } from 'react-transition-group';
import './modal.css'; 

function Modal({ isOpen, onClose, children }) {
    return (
        <CSSTransition in={isOpen} timeout={300} classNames="modal" unmountOnExit>
            <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="bg-gray-800 absolute inset-0 modal-overlay opacity-70" onClick={onClose}></div>
                <div className="card text-slate-700 dark:text-navy-100 w-11/12 max-w-md p-4 rounded shadow-lg relative max-h-[80vh] bg-gray-900 tablet:w-[80%]">
                    {children}
                </div>
            </div>
        </CSSTransition>
    );
}

export default Modal;
