import React from "react";
import { motion } from "framer-motion";

const ErrorComponent = ({ message }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5, ease: "easeInOut" }}
      className="bg-red-500 text-white p-4 rounded-md shadow-lg"
    >
      {message}
    </motion.div>
  );
};

export default ErrorComponent;
