import React, { useState } from "react";
import { motion } from "framer-motion";
import { adminURL } from "../../utils/urls";
import Loader from "../loader/FetchLoader";

const Xsmall = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const [showDeclineReason, setShowDeclineReason] = useState(false);
  const [declineReason, setDeclineReason] = useState("");
  const isApproved = user.approved;

  const handleApproval = async (approved) => {
    setLoading(true);
    try {
      await adminURL.patch(`users/${user.id}`, { approved: true });
      window.location.reload();
    } catch (error) {
      console.error("Error approving user:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDecline = async () => {
    setLoading(true);
    try {
      await adminURL.patch(`users/${user.id}`, {
        approved: false,
        meta: {
          decline_reason: declineReason,
        },
      });
      window.location.reload();
    } catch (error) {
      console.error("Error declining user:", error);
    } finally {
      setLoading(false);
      setShowDeclineReason(false);
    }
  };

  return (
    <section className="relative p-4 space-y-4 flex flex-col items-center">
      {loading && (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center z-10">
          <Loader text="Processing..." />
        </div>
      )}

      <div className="z-0">
        <h2 className="text-white text-xl text-center">{user.name}</h2>
        <img
          src={user?.photo_url}
          alt={user?.name}
          className="w-64 h-64 object-cover rounded-full mx-auto mt-4"
        />
      </div>

      <div className="flex justify-center space-x-4 mt-4 z-0">
        <button
          className={`bg-green-600 ${
            isApproved ? "opacity-50 cursor-not-allowed" : "hover:bg-green-700"
          } text-black font-bold py-2 px-4 rounded`}
          onClick={!isApproved ? () => handleApproval(true) : null}
          disabled={loading || isApproved}
        >
          Accept Profile
        </button>
        <motion.button
          className={`${
            isApproved ? "bg-red-600 hover:bg-red-700" : "bg-gray-600"
          } text-white font-bold py-2 px-4 rounded border border-white`}
          disabled={!isApproved || loading}
          onClick={isApproved ? () => setShowDeclineReason(true) : null}
          initial={{ opacity: 1 }}
          animate={{ opacity: isApproved ? 1 : 0.5 }}
          transition={{ duration: 0.3 }}
        >
          Decline Profile
        </motion.button>
      </div>

      <div className="space-y-1 mt-4 w-full max-w-xs z-0">
        <label className="text-white">Telephone Number</label>
        <input
          type="text"
          value={user?.phone}
          readOnly
          className="bg-gray-700 outline-none text-white shadow-sm w-full rounded-lg p-2"
        />
      </div>

      {showDeclineReason && (
        <motion.div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-20"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-sm w-full">
            <h3 className="text-white text-lg mb-4">Reason for Declining</h3>
            <textarea
              className="w-full h-32 p-2 bg-gray-700 text-white rounded-lg focus:outline-none"
              placeholder="Enter the reason for declining..."
              value={declineReason}
              onChange={(e) => setDeclineReason(e.target.value)}
              disabled={loading}
            />
            <div className="flex justify-end mt-4 space-x-2">
              <button
                className="bg-gray-600 hover:bg-gray-700 text-white py-2 px-4 rounded"
                onClick={() => setShowDeclineReason(false)}
                disabled={loading}
              >
                Cancel
              </button>
              <button
                className="bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded"
                onClick={handleDecline}
                disabled={loading || !declineReason.trim()}
              >
                Confirm
              </button>
            </div>
          </div>
        </motion.div>
      )}
    </section>
  );
};

export default Xsmall;
