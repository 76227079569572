import React from "react";
import ReactECharts from "echarts-for-react";
import { adminURL } from "../../utils/urls";
import { useQuery } from "@tanstack/react-query";

const fetchSeshData = async () => {
  const { data } = await adminURL.get(`/charts/appointments-duration`);
  return data;
};

const DurationPerCoach = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["appointment-duration"],
    queryFn: fetchSeshData,
  });

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading data</div>;

  const labels = data?.labels || [];
  const durations = data?.series?.[0]?.data || [];

  const maxDuration = Math.max(...durations);
  const yAxisMax = Math.ceil(maxDuration / 10) * 10;

  const options = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    xAxis: {
      type: "category",
      data: labels,
      axisLine: {
        lineStyle: {
          color: "#fff",
        },
      },
      axisLabel: {
        textStyle: {
          color: "#fff",
        },
        rotate: 45,
        interval: 0,
      },
    },
    yAxis: {
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#fff",
        },
      },
      axisLabel: {
        formatter: "{value} min",
        textStyle: {
          color: "#fff",
        },
      },
      splitLine: {
        lineStyle: {
          color: "rgba(255, 255, 255, 0.1)",
          type: "dashed",
        },
      },
      max: yAxisMax,
      interval: Math.max(10, Math.floor(yAxisMax / 5)), 
    },
    series: [
      {
        data: durations,
        type: "bar",
        barWidth: "60%",
        itemStyle: {
          color: "#165DFF",
        },
      },
    ],
  };

  return (
    <div className="flex flex-col bg-gray-500 bg-opacity-10 w-full space-y-1 rounded-xl relative shadow-xl">
      <h2 className="text-white text-left text-lg border-[0.5px] border-customBorderTwo rounded-t-lg py-3 px-4">
        Average session duration per coach
      </h2>
      <ReactECharts option={options} className="w-full h-[700px]" />
    </div>
  );
};

export default DurationPerCoach;
