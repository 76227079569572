import React from "react";
import { IoCardSharp } from "react-icons/io5";

const PayoutModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-black text-white rounded-xl p-6 lg:p-10 space-y-6 w-full max-w-lg">
        <div className="flex justify-between items-center">
          <h1 className="text-lg md:text-xl font-bold">Make Payments</h1>
          <button onClick={onClose} className="text-xl font-bold">
            x
          </button>
        </div>

        <div className="flex items-center bg-gray-600 rounded-xl max-w-[235px] px-4 py-2 space-x-3">
          <IoCardSharp className="text-primary text-2xl md:text-4xl" />
          <div className="flex flex-col space-y-4">
            <h1 className="text-sm font-semibold">Available balance</h1>
            <p className="font-bold text-lg ">$24,000.00</p>
          </div>
        </div>

        <div className="flex justify-between items-center rounded-lg px-4 py-2">
          <span>Transfer To</span>
          <span className="text-xl text-gray-400 md:text-2xl">
            Strong power bank
          </span>
        </div>

        <div className="flex justify-between items-center rounded-lg px-4 py-2">
          <span>Account Number</span>
          <span className="text-xl text-gray-400 md:text-2xl">124049904</span>
        </div>

        <div className="relative flex items-center bg-gray-700 rounded-lg px-4 py-2">
          <span className="flex-shrink-0 w-1/2">Enter Amount</span>
          <span className="absolute left-1/2 transform -translate-x-1/2 border-l border-gray-500 h-full"></span>
          <input
            type="text"
            placeholder="$0.00"
            className="bg-transparent text-white border-none focus:outline-none pl-2 ml-auto w-1/2"
          />
        </div>

        <div className="relative flex items-center bg-gray-700 rounded-lg px-4 py-2">
          <span className="flex-shrink-0 w-1/2">Enter Password</span>
          <span className="absolute left-1/2 transform -translate-x-1/2 border-l border-gray-500 h-full"></span>
          <input
            type="password"
            className="bg-transparent text-white border-none focus:outline-none pl-2 ml-auto w-1/2"
          />
        </div>

        <div className="flex justify-between">
          <button
            onClick={onClose}
            className="bg-gray-700 rounded-lg px-4 py-2 bg-white text-black py-2 px-10"
          >
            Cancel
          </button>
          <button className="bg-primary rounded-lg px-4 text-black py-2 bg-primary py-2 px-10">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default PayoutModal;
