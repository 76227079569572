import React from 'react';
import { motion } from 'framer-motion';

const ConfirmDeleteModal = ({ isOpen, onConfirm, onCancel, isDeleting }) => {
  if (!isOpen) return null;

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <motion.div
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.8, opacity: 0 }}
        className="bg-black p-6 rounded-lg shadow-xl"
      >
        <h2 className="text-xl font-bold mb-4">
          {isDeleting ? "Deleting Slot" : "Confirm Deletion"}
        </h2>
        <p className="mb-6">
          {isDeleting
            ? ""
            : "Are you sure you want to delete this slot?"}
        </p>
        {!isDeleting && (
          <div className="flex justify-end space-x-4">
            <button
              onClick={onCancel}
              className="px-4 py-2 bg-amber-300 text-black rounded hover:bg-gray-400"
            >
              Cancel
            </button>
            <button
              onClick={onConfirm}
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
            >
              Delete
            </button>
          </div>
        )}
        {isDeleting && (
          <div className="flex justify-center">
            <motion.div
              animate={{ rotate: 360 }}
              transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
              className="w-6 h-6 border-2 border-red-500 border-t-transparent rounded-full"
            />
          </div>
        )}
      </motion.div>
    </motion.div>
  );
};

export default ConfirmDeleteModal;