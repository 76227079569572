import React, { useEffect, useState } from "react";
import Content from "./content/Content";
import { CgSortAz } from "react-icons/cg";
import Pagination from "../../../pagination/Pagination";
import { adminURL } from "../../../../utils/urls";
import Loader from "../../../loader/FetchLoader";

const Verification = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [showSortOptions, setShowSortOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [loading, setLoading] = useState(true);
  const itemsPerPage = 15;

  const fetchData = async (page) => {
    setLoading(true);
    try {
      const response = await adminURL.get(`users?role=user&page=${page}`);
      setUsers(response.data.data);
      setTotalUsers(response.data.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    if (page > 0 && page <= Math.ceil(totalUsers / itemsPerPage)) {
      setCurrentPage(page);
    }
  };

  return (
    <section className="flex flex-col w-full p-5 space-y-5 min-h-screen bg-customBlack min-w-[300px] pt-40 md:pt-0">
      {loading ? (
        <Loader text="Fetching users..." />
      ) : (
        <>
          <header className="grid grid-cols-1  md:grid-cols-3 gap-4 justify-items-center md:justify-items-end items-center py-5 px-4 md:px-10 rounded-md">
            <h1 className="text-lg md:text-xl text-white">User Verification</h1>
            <div className="md:col-span-2 flex flex-col justify-center md:justify-end w-full xl:flex-row">
              <Pagination
                currentPage={currentPage}
                totalPages={totalUsers}
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}
              />
              <div className="flex items-center relative ml-4">
                <div
                  className="flex items-center space-x-2 md:space-x-6 cursor-pointer bg-gray-800 px-4 py-2 rounded-lg"
                  onClick={() => setShowSortOptions((prev) => !prev)}
                >
                  <span className="text-white text-sm md:text-base">
                    Sort by
                  </span>
                  <CgSortAz className="text-white text-2xl md:text-4xl" />
                </div>
                {showSortOptions && (
                  <div
                    style={{ backgroundColor: "rgba(12, 17, 23, 1)" }}
                    className="absolute top-full left-0 mt-2 border border-amber-900 text-white rounded-lg shadow-lg z-10 w-40 md:w-auto"
                  >
                    <div className="flex flex-col space-y-2">
                      <label className="cursor-pointer flex justify-between items-center text-xs md:text-sm px-4 md:px-6 py-2">
                        By Payment
                        <input
                          type="checkbox"
                          checked={selectedOption === "payment"}
                          onChange={() => setSelectedOption("payment")}
                          className="ml-2 cursor-pointer rounded-full text-amber-500"
                        />
                      </label>
                      <div className="border-t border-amber-900"></div>
                      <label className="cursor-pointer flex justify-between items-center text-xs md:text-sm px-4 md:px-6 py-2">
                        By Endorsement
                        <input
                          type="checkbox"
                          checked={selectedOption === "endorsement"}
                          onChange={() => setSelectedOption("endorsement")}
                          className="ml-2 cursor-pointer rounded-full text-amber-500"
                        />
                      </label>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </header>
          <section className="flex justify-center w-full px-10">
            <Content users={users} />
          </section>
        </>
      )}
    </section>
  );
};

export default Verification;
