import React from "react";
import { Link } from "react-router-dom";

const Content = ({ users }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full min-w-[320px]">
      {users.map((user, index) => (
        <div
          key={index}
          className="p-4 flex flex-col items-center min-w-[200px]"
        >
          <img
            src={user.photo_url}
            alt={user.name}
            className="w-full h-56 object-cover rounded-md"
          />
          <h2 className="text-white text-center mt-2">{user.name}</h2>
          <Link
            to={`/dashboard/users/user-verification/${user.id}`}
            className="w-full mt-2 py-1 bg-amber-500 rounded-md text-black text-center"
          >
            User Details
          </Link>
        </div>
      ))}
    </div>
  );
};

export default Content;
