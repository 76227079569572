import React, { useState } from "react";
import Earnings from "./earnings/Earnings";
import Payouts from "./payouts/Payouts";

const PaymentTables = () => {
  const [activeTab, setActiveTab] = useState("earnings");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <section className="flex flex-col w-full p-5 space-y-5 min-h-screen pt-20 md:pt-0">
      <div className="flex flex-col w-full">
        <div
          className="flex space-x-2 min-w-[300px] w-full mx-auto p-5 rounded-t-lg"
          style={{ backgroundColor: "rgba(12, 17, 23, 1)" }}
        >
          <button
            onClick={() => handleTabChange("earnings")}
            className={`${
              activeTab === "earnings"
                ? "bg-primary text-gray-900"
                : "text-gray-300 border border-gray-300 bg-black"
            } flex-1 text-sm px-4 py-2 rounded-lg capitalize font-semibold text-center md:text-xl`}
          >
            Earnings
          </button>
          <button
            onClick={() => handleTabChange("payouts")}
            className={`${
              activeTab === "payouts"
                ? "bg-primary text-gray-900"
                : "text-gray-300 border border-gray-300 bg-black"
            } flex-1 px-4 py-2 rounded-lg capitalize font-semibold text-center text-sm md:text-xl`}
          >
            Payouts
          </button>
        </div>

        <div className="relative p-4 z-10 bg-gray-800 rounded-b-lg flex-1 min-w-[300px] w-full">
          {activeTab === "earnings" && <Earnings />}
          {activeTab === "payouts" && <Payouts />}
        </div>
      </div>
    </section>
  );
};

export default PaymentTables;
