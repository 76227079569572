import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./pending.css";
import { adminURL } from "../../../../utils/urls";
import { useQuery } from "@tanstack/react-query";

const fetchAppointments = async (userID, page, start) => {
  const response = await adminURL.get(
    `appointments?booked=true&page=${page}&starts_at=${start}`
  );
  return response.data;
};

const PendingVer = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const userID = localStorage.getItem("refreshID");
  const start = new Date().toISOString().split("T")[0];

  const { data, isLoading, error } = useQuery({
    queryKey: ["slots", userID, currentPage],
    queryFn: () => fetchAppointments(userID, currentPage, start),
    enabled: !!userID,
    refetchInterval: 10000,
  });

  const handleNextPage = () => {
    if (currentPage < data?.last_page) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handlePAppClick = (userId) => {
    navigate(`/coaches/dashboard/appointments/details/${userId}`);
  };

  return (
    <div className="relative bg-customBlack border border-customBorderTwo shadow-md rounded-lg px-3 py-6 flex flex-col items-center min-w-[250px] md:min-w-[300px] max-w-full">
      <div className="flex justify-between items-center w-full mb-4">
        <h1 className="font-bold text-white">Pending Appointments</h1>
        {data?.data?.length > 0 && (
          <div className="text-sm text-white flex items-center">
            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className="cursor-pointer px-2 py-1 mr-2 bg-gray-700 rounded hover:bg-gray-600 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              &lt;
            </button>
            <span>
              {isLoading
                ? "Loading..."
                : `${data?.from}-${data?.to} of ${data?.total}`}
            </span>
            <button
              onClick={handleNextPage}
              disabled={currentPage === data?.last_page}
              className="cursor-pointer px-2 py-1 ml-2 bg-gray-700 rounded hover:bg-gray-600 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              &gt;
            </button>
          </div>
        )}
      </div>
      <div className="overflow-y-auto max-h-[24rem] custom-scrollbar w-full">
        {isLoading ? (
          <div className="text-white text-center">Loading Appointments...</div>
        ) : error ? (
          <div className="text-white text-center">Error: {error.message}</div>
        ) : data?.data?.length > 0 ? (
          data?.data?.map((appointment) => (
            <div
              key={appointment?.id}
              className="flex items-center p-2 border-b border-amber-500 cursor-pointer hover:bg-gray-800"
              onClick={() => handlePAppClick(appointment.id)}
            >
              <img
                src={appointment?.booker?.photo_url}
                alt={appointment?.booker?.name}
                className="w-12 h-12 mr-4 rounded-full"
              />
              <div className="flex-1 flex items-center space-x-4">
                <div className="text-white">{appointment?.booker?.name}</div>
                <div className="text-amber-500">
                  {appointment?.booker?.gender}
                </div>
                <div className="text-white">
                  {appointment.booker.city || "N/A"}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-white text-center mt-4">
            You don't have any Appointments today 😊
          </div>
        )}
      </div>
    </div>
  );
};

export default PendingVer;
