import { useQuery } from "@tanstack/react-query";
import ReactECharts from "echarts-for-react";
import { adminURL } from "../../utils/urls";

const fetchAgeDistData = async () => {
  const { data } = await adminURL.get(`charts/age-distribution`);
  return data;
};

const AgeDistributionGraph = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["age-distribution"],
    queryFn: fetchAgeDistData,
  });

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error loading data.</p>;

  
  const ageRangeLabels = data?.labels || [];
  const percentageData = data?.series?.[0]?.data || [];


  const maxPercentage = Math.max(...percentageData);

  const options = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        lineStyle: {
          type: "dashed",
          color: "rgba(224, 224, 224, 0.2)",
        },
        crossStyle: {
          type: "dashed",
          color: "rgba(224, 224, 224, 0.2)",
        },
      },
      backgroundColor: "rgba(0, 0, 0, 0)",
      borderWidth: "4px",
      textStyle: {
        color: "#fff",
      },
    },
    legend: {
      show: false,
    },
    xAxis: {
      type: "category",
      data: ageRangeLabels,
      axisLabel: {
        interval: 0,
        color: "#ffff",
      },
    },
    yAxis: {
      type: "value",
      name: "Percentage (%)",
      nameLocation: "middle",
      nameGap: 40,
      axisLabel: {
        formatter: "{value}%",
        color: "#ccc",
      },
      nameTextStyle: {
        color: "#fff",
      },
      splitLine: {
        lineStyle: {
          type: "dotted",
          color: "#e0e0e0",
          width: 2,
        },
      },
      axisLine: {
        show: false,
      },
      max: maxPercentage + 10, 
      interval: Math.ceil((maxPercentage + 10) / 5), 
    },
    series: [
      {
        name: "Age Range",
        type: "bar",
        barWidth: "60%",
        barGap: "50%",
        barCategoryGap: "50%",
        data: percentageData,
        itemStyle: {
          color: "rgb(66, 135, 245)",
          borderRadius: [30, 30, 0, 0],
        },
        emphasis: {
          itemStyle: {
            color: "rgb(66, 135, 245)",
            borderRadius: [30, 30, 0, 0],
          },
        },
      },
    ],
  };

  return (
    <div className="flex flex-col bg-gray-500 bg-opacity-10 w-full space-y-1 rounded-xl relative shadow-xl">
      <h2 className="text-white text-left text-lg border-[0.5px] border-customBorderTwo rounded-t-lg py-3 px-4">
        Age distribution
      </h2>
      <div className="p-1">
        {data && (
          <ReactECharts option={options} style={{ height: "400px", width: "100%" }} />
        )}
      </div>
      <div
        className="text-white text-center py-3"
        style={{ marginTop: "-45px" }}
      >
        Age Group
      </div>
    </div>
  );
};

export default AgeDistributionGraph;
