import React from "react";
import DailyActiveUserChart from "../../../../charts/DailyActiveUserChart";
// import AverageSessionDurationChart from "../../../../charts/AverageSessionDurationChart";
import AppUsageFrequencyGraph from "../../../../charts/AppUsageFrequencyGraph";

const UserEngagement = () => {
  return (
    <div className="flex flex-col w-full space-y-3 min-h-screen">
      <div className=" p-3 rounded-b-lg space-y-4">
        <div className="grid grid-cols-1 gap-4">
          <DailyActiveUserChart />
          {/* <AverageSessionDurationChart /> */}
        </div>
        <div className="grid grid-cols-1 gap-4">
          <AppUsageFrequencyGraph />
        </div>
      </div>
    </div>
  );
};

export default UserEngagement;
