import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { adminURL } from "../../../../utils/urls";
import Table from "../../../../utils/table/Table";
const Rsvp = () => {
  const [rsvpResponses, setRsvpResponses] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    perPage: 10,
    lastPage: 1,
    total: 0,
  });
  const [loading, setLoading] = useState(true);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);


  useEffect(() => {
    fetchEventData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchEventData = async (page = 1) => {
    setLoading(true);
    try {
      const response = await adminURL.get(`events/${id}/rsvps?page=${page}`);
      const data = response.data?.data;

      setRsvpResponses(data);
      setPagination({
        currentPage: response.data.current_page,
        lastPage: response.data.last_page,
        total: response.data.total,
        perPage: response.data.per_page,
      });
    } catch (error) {
      console.error("Error fetching events:", error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      Header: "No.",
      accessor: "serial_no",
      Cell: ({ value }) => (
        <span className="text-white font-medium">{value}</span>
      ),
    },
    {
      Header: "Attenders",
      accessor: (row) => (
        <div className="flex items-center space-x-4 capitalize">
          <section className="flex items-center justify-start space-x-4">
            <div className="avatar h-12 w-12">
              <img
                src={row?.photo_url}
                alt="Attender"
                className="rounded-full object-cover object-center h-full w-full"
              />
            </div>
            <span className="font-medium text-base text-slate-300 line-clamp-2">
              {row?.name}
            </span>
          </section>
        </div>
      ),
    },
    // {
    //   Header: "Name of Event",
    //   accessor: "name",
    //   Cell: ({ value }) => <span className="capitalize">{value}</span>,
    // },
    {
      Header: "Response",
      accessor: "response",
      Cell: ({ row }) => (
        <button
          className={`py-2 px-4 cursor-pointer rounded-full ${
            row.original.responseType === "danger"
              ? "bg-red-700 text-white"
              : "bg-amber-500 text-black"
          }`}
        >
          Accepted
        </button>
      ),
    },
  ];

  return (
    <div className="w-full min-w-[800px] rounded-b-lg mt-10 p-8">
      <div className="flex justify-between items-center py-4 px-10">
        <button
          onClick={() => navigate("/dashboard/events")}
          className="flex items-center space-x-2 bg-dark-700 p-2 rounded-lg hover:bg-dark-600 transition-all"
        >
          <FaArrowLeft className="text-white" />
          <span className="text-white">Back</span>
        </button>
        <h1 className="text-2xl font-bold text-center w-full capitalize text-white">
          RSVP's
        </h1>
      </div>
      {loading ? (
        <p className="text-white text-center mt-4">Loading...</p>
      ) : rsvpResponses.length === 0 ? (
        <p className="text-white text-center mt-4">
          No RSVPs for this event yet 😊
        </p>
      ) : (
        <div className="overflow-x-auto">
          <Table
            columns={columns}
            data={rsvpResponses}
            initialSortBy={[{ id: "serial_no", desc: false }]}
            fullWidth={true}
            pagination={pagination}
            onPageChange={fetchEventData}
          />
        </div>
      )}
    </div>
  );
};

export default Rsvp;
