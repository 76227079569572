import React from "react";
import ReactECharts from "echarts-for-react";
import { useQuery } from "@tanstack/react-query";
import { adminURL } from "../../utils/urls";

const fetchAppUsageData = async () => {
  const { data } = await adminURL.get(`charts/app-usage`);
  return data;
};

const AppUsageFrequencyGraph = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["app-usage"],
    queryFn: fetchAppUsageData,
  });

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error loading data</p>;

  const date = data ? data.labels : [];
  const frequency = data ? data.series[0].data : [];

  const options = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "line",
        lineStyle: {
          type: "dashed",
          color: "rgba(224, 224, 224, 0.2)",
        },
        crossStyle: {
          type: "dashed",
          color: "rgba(224, 224, 224, 0.2)",
        },
      },
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      borderWidth: "1px",
      textStyle: {
        color: "#fff",
      },
    },
    xAxis: {
      type: "category",
      data: date,
    },
    yAxis: {
      type: "value",
      splitLine: {
        lineStyle: {
          color: "#e0e0e0",
          opacity: "0.2",
        },
      },
    },
    series: [
      {
        name: "Usage Frequency",
        type: "line",
        smooth: true,
        data: frequency,
        showSymbol: false,
        lineStyle: {
          color: "#3182bd",
          width: 2,
        },
      },
    ],
  };

  return (
    <div className="flex flex-col w-full space-y-2 rounded-lg bg-gray-500 bg-opacity-10 mt-2">
      <h2 className="text-white py-2 px-4 text-lg border-[0.5px] border-customBorderTwo rounded-t-lg ">
        Frequency of app usage
      </h2>
      <div className="p-3">
        {data && (
          <div>
            <ReactECharts
              option={options}
              style={{ height: "400px", width: "100%" }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AppUsageFrequencyGraph;
