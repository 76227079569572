import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { adminURL } from "../../../../utils/urls";
import Table from "../../../../utils/table/Table";
import { FaExclamationTriangle } from "react-icons/fa";

const EventTable = () => {
  const [events, setEvents] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    perPage: 10,
    lastPage: 1,
    total: 0,
  });
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [eventIdToDelete, setEventIdToDelete] = useState(null);
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [descriptionToShow, setDescriptionToShow] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    fetchEventData();
  }, []);

  const fetchEventData = async (page = 1) => {
    setLoading(true);
    try {
      const response = await adminURL.get(`events?page=${page}`);
      setEvents(response.data.data);
      setPagination({
        currentPage: response.data.current_page,
        lastPage: response.data.last_page,
        total: response.data.total,
        perPage: response.data.per_page,
      });
    } catch (error) {
      console.error("Error fetching events:", error);
    }
    setLoading(false);
  };

  const handleDeleteConfirmation = (id) => {
    setEventIdToDelete(id);
    setShowModal(true);
  };

  const handleDelete = async () => {
    try {
      await adminURL.delete(`events/${eventIdToDelete}`);
      fetchEventData(pagination.currentPage);
    } catch (error) {
      console.error("Error deleting event:", error);
    } finally {
      setShowModal(false);
    }
  };

  const handleUpdate = (name, id) => {
    navigate(`/dashboard/events/update/${name}/${id}`);
  };

  const handleViewDetails = (description) => {
    setDescriptionToShow(description);
    setShowDescriptionModal(true);
  };

  const columns = [
    {
      Header: "No.",
      accessor: "id",
      Cell: ({ value }) => (
        <span className="text-white font-medium">{value}</span>
      ),
    },
    {
      Header: "Event",
      accessor: "name",
      Cell: ({ value }) => <span className="capitalize">{value}</span>,
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: ({ value }) => (
        <div className="capitalize">
          <span>{value.substring(0, 50)}...</span>
          <button
            onClick={() => handleViewDetails(value)}
            className="ml-2 text-amber-500  cursor-pointer"
          >
            View 
          </button>
        </div>
      ),
    },
    {
      Header: "Venue",
      accessor: "venue",
      Cell: ({ value }) => <span className="capitalize">{value}</span>,
    },
    {
      Header: "Date",
      accessor: "starts_at",
      Cell: ({ value }) => (
        <span className="capitalize">
          {new Date(value).toLocaleDateString()}
        </span>
      ),
    },
    {
      Header: "View",
      accessor: "view",
      Cell: ({ row }) => (
        <Link
          to={`/dashboard/events/${row.original.id}/rsvp`}
          className="bg-amber-500 text-black py-2 px-4 font-bold rounded-full cursor-pointer text-sm"
        >
          RSVP
        </Link>
      ),
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }) => (
        <div className="flex flex-wrap gap-2">
          <button
            onClick={() => handleUpdate(row.original.name, row.original.id)}
            className="bg-blue-500 text-white py-2 px-4 font-bold rounded-full cursor-pointer text-sm"
          >
            Update
          </button>
          <button
            onClick={() => handleDeleteConfirmation(row.original.id)}
            className="bg-red-500 text-white py-2 px-4 font-bold rounded-full cursor-pointer text-sm"
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="w-full rounded-b-lg mt-4 p-4 md:p-8 overflow-x-auto">
      <div
        style={{ backgroundColor: "rgba(12, 17, 23, 1)" }}
        className="flex flex-col md:flex-row justify-between items-center py-4 px-4 md:px-10"
      >
        <h1 className="text-2xl font-bold text-center w-full md:w-auto capitalize text-white mb-4 md:mb-0">
          Events
        </h1>
        <Link
          to="/dashboard/events/create"
          className="bg-amber-500 text-black py-2 px-6 font-bold rounded-full cursor-pointer"
        >
          Create
        </Link>
      </div>
      {loading ? (
        <p className="text-white text-center mt-4">Loading...</p>
      ) : (
        <div className="overflow-x-auto">
          <Table
            columns={columns}
            data={events}
            initialSortBy={[{ id: "id", desc: false }]}
            fullWidth={true}
            pagination={pagination}
            onPageChange={fetchEventData}
          />
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-gray-800 rounded-lg p-6 max-w-sm w-full">
            <div className="flex items-center mb-4">
              <FaExclamationTriangle className="text-yellow-400 text-xl mr-3" />
              <h3 className="text-white font-bold text-lg">Are you sure?</h3>
            </div>
            <p className="text-white mb-6">
              Do you really want to delete this event? This action cannot be
              undone.
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => setShowModal(false)}
                className="bg-gray-600 text-white py-2 px-4 rounded hover:bg-gray-500"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="bg-red-600 text-white py-2 px-4 rounded hover:bg-red-500"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Description Modal */}
      {showDescriptionModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-gray-800 rounded-lg p-6 max-w-lg w-full">
            <h3 className="text-white font-bold text-lg mb-4">Event Details</h3>
            <p className="text-white mb-6">{descriptionToShow}</p>
            <button
              onClick={() => setShowDescriptionModal(false)}
              className="bg-gray-600 text-white py-2 px-4 rounded hover:bg-gray-500"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventTable;
