import React from "react";
import { motion } from "framer-motion";
import Logo from "../assets/coachassets/images-videos/Logo.png";
import Icon from "../assets/coachassets/images-videos/logo2.png";
import FullLogo from "../assets/coachassets/images-videos/Platinum-Fuse-Full-Logo.png";

const Waiting = () => {
  const iconVariants = {
    initial: {
      x: "-50vw",
      y: "-50vh",
    },
    animate: {
      x: ["0vw", "50vw", "0vw", "-50vw", "0vw"],
      y: ["0vh", "50vh", "0vh", "-50vh", "0vh"],
      transition: {
        duration: 5,
        repeat: Infinity,
        ease: "easeInOut",
      },
    },
  };

  return (
    <div className="h-screen w-screen flex justify-center items-center bg-gray-700 overflow-hidden">
      <div className="grid grid-cols-2 gap-7 w-screen h-screen">
        <div className="flex flex-col justify-center items-center p-8 text-center space-y-5 tablet:bg-black tablet:bg-custom tablet:w-screen">
          <div className="flex justify-center items-center">
            <img src={FullLogo} alt="Platinum Fuse Icon" className="w-3/6" />
          </div>
          <p className="text-white text-lg">
            Your application is being reviewed at the moment, and an email will
            be sent to you once the process is complete.
          </p>
          <motion.div
            className="absolute top-0 left-0 w-20 h-20"
            variants={iconVariants}
            initial="initial"
            animate="animate"
          >
            <img src={Icon} alt="Moving Icon" className="w-full h-full" />
          </motion.div>
        </div>

        <div className="flex justify-center items-center p-20 bg-black rounded-l-[100px] bg-custom tablet:hidden">
          <img src={Logo} alt="Platinum Fuse Logo" className="" />
        </div>
      </div>
    </div>
  );
};

export default Waiting;
