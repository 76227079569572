import React from "react";
import UserRatingsChart from "../../../../charts/UserRatingsChart";
// import UserFeedbackChart from "../../../../charts/UserFeedbackChart";

const UserRatings = () => {
  return (
    <div className="flex flex-col w-full space-y-3 min-h-screen">
      <div className="p-3 rounded-b-lg grid grid-cols-1 gap-4">
        <UserRatingsChart />
        {/* <UserFeedbackChart/> */}
      </div>
    </div>
  );
};

export default UserRatings;
