import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faTriangleExclamation,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../modals/Modal";
import { baseURL } from "../../utils/urls";

const ForgotPassword = ({ onGoBack }) => {
  const [email, setEmail] = useState("");
  const [focusedInput, setFocusedInput] = useState("");
  const [emailError, setEmailError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    message: "",
    icon: null,
    title: "",
  });

  const validateEmail = () => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    if (!emailRegex.test(email)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    validateEmail();

    if (!emailError) {
      try {
        const res = await baseURL.post("forgot-password", { email });
        if (res.status === 200) {
          setModalContent({
            title: "Success",
            message: "Password reset email sent successfully!",
            icon: (
              <FontAwesomeIcon
                icon={faThumbsUp}
                className="text-4xl text-primary mb-4"
              />
            ),
          });

          window.location.reload();
        } else if (res.status === 422) {
          setModalContent({
            title: "Error",
            message: res.data.message,
            icon: (
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                className="text-red-600 text-4xl mb-4"
              />
            ),
          });
        }
        setShowModal(true);
      } catch (error) {
        setModalContent({
          title: "Error",
          message: "Password reset failed. Please try again.",
          icon: (
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="text-red-600 text-4xl mb-4"
            />
          ),
        });
        setShowModal(true);
      }
    }
  };

  return (
    <div>
      <div className="!mb-5">
        <h2 className="text-2xl text-white font-bold tablet:text-primary">
          Welcome
        </h2>
        <p className="text-base text-white font-bold tablet:text-primary">
          Please enter your email address to reset your password.
        </p>
      </div>
      <div className="!mt-10">
        <form onSubmit={handleSubmit}>
          <div
            className={`input-div one relative grid grid-cols-[7%,93%] mt-6 py-1 border-b-2 ${
              emailError
                ? "border-red-500"
                : email
                ? "border-active"
                : "border-b-gray-300"
            } mt-0 ${focusedInput === "email" ? "focus" : ""}`}
          >
            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
              <FontAwesomeIcon
                icon={faEnvelope}
                className={`text-gray-400 transition-all duration-300 ${
                  email ? (emailError ? "text-red-500" : "icon-active") : ""
                }`}
              />
            </div>
            <div className="div relative h-11">
              <label
                htmlFor="email"
                className={`absolute left-[40px] top-[50%] -translate-y-[50%] text-gray-300 text-lg transition-all duration-300 ${
                  email ? "label-active" : ""
                }`}
              >
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onFocus={() => setFocusedInput("email")}
                onBlur={() => !email && setFocusedInput("")}
                className={`input absolute left-0 top-0 text-primary text-xl h-full w-[28rem] bg-transparent py-2 px-3 pl-10 rounded-md focus:outline-none ${
                  emailError ? "text-red-500" : ""
                }`}
                autoComplete="email"
                required
              />
            </div>
          </div>
          {emailError && (
            <p className="text-red-500 text-sm w-full">{emailError}</p>
          )}
          <div className="flex justify-between items-center mt-6">
            <button
              onClick={onGoBack}
              className="text-white hover:text-primary text-sm block text-right transition duration-300"
            >
              Login
            </button>
            <Link
              to={"/coaches/signup"}
              className="text-white hover:text-primary text-sm block text-right transition duration-300"
            >
              Sign up
            </Link>
          </div>
          <button
            type="submit"
            className="login-btn block w-full my-4 rounded-md text-base font-medium font-sans uppercase transition-all duration-700 text-black hover:bg-right"
          >
            Reset Password
          </button>
        </form>
      </div>

      {/* Modal for displaying API response */}
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <div className="flex flex-col items-center justify-center">
          {modalContent.icon}
          <h2 className="text-lg font-bold mb-2 text-white">
            {modalContent.title}
          </h2>
          <p className="text-white">{modalContent.message}</p>
        </div>
      </Modal>
    </div>
  );
};

export default ForgotPassword;
