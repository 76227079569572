import React from "react";
import { motion } from "framer-motion";

const SlotModal = ({ isOpen, text = "Processing..." }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
      <motion.div
        className="flex flex-col items-center"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.8 }}
      >
        <div className="loader bg-amber-500 rounded-full w-16 h-16 mb-4 animate-spin"></div>
        <p className="text-white text-lg font-bold">{text}</p>
      </motion.div>
    </div>
  );
};

export default SlotModal;
