import React from "react";
import { FaTrashAlt, FaEdit, FaEye } from "react-icons/fa";
import { formatDistanceToNow } from "date-fns";

const PublishItem = ({ publication, handleDelete, handleEdit, isLast }) => {
  const { id, cover_url, name, price, published_at, views = 0 } = publication;

  return (
    <div
      className={`bg-gray-900 text-white p-4 flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-4 items-center rounded-lg shadow-lg ${
        isLast ? "" : "border-b-4 border-amber-500"
      } hover:bg-gray-800 transition duration-300 ease-in-out transform hover:scale-80 mb-4`}
      style={{
        backgroundColor: "rgba(12, 17, 23, 1)",
      }}
    >
      <div className="flex flex-col md:flex-row md:items-center md:space-x-4 flex-1">
        <img
          src={cover_url}
          alt={name}
          className="object-cover w-16 h-16 rounded-full"
        />
        <div className="flex flex-col space-y-2 flex-1 mt-2 md:mt-0">
          <span className="text-lg md:text-xl font-bold">{name}</span>
          <div className="flex items-center space-x-2 text-gray-400 text-sm">
            <span className="text-sm text-white">
              {formatDistanceToNow(new Date(published_at))} ago
            </span>
            <span className="text-lg text-white">&#183;</span>
            <FaEye className="text-white text-lg" />
            <span className="text-sm md:text-lg text-white">{views}</span>
          </div>
        </div>
        <span className="text-lg md:text-xl font-bold text-white md:ml-auto">
          {price === 0 ? "Free" : `₦${price.toLocaleString()}`}
        </span>
      </div>
      <div className="flex space-x-4">
        <div
          className="bg-white rounded-md p-2 cursor-pointer hover:scale-110 transform transition duration-200 ease-in-out"
          onClick={() => handleDelete(id)}
        >
          <FaTrashAlt className="text-black text-lg md:text-xl" />
        </div>
        <div
          className="bg-white rounded-md p-2 cursor-pointer hover:scale-110 transform transition duration-200 ease-in-out"
          onClick={() => handleEdit(id)}
        >
          <FaEdit className="text-black text-lg md:text-xl" />
        </div>
      </div>
    </div>
  );
};

export default PublishItem;
