import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./calendar.css";

const Schedule = ({ data }) => {
  const [date, setDate] = useState(new Date());
  
  const bookedDate = new Date(data?.slot?.booked_at); 

  const handleDateChange = (newDate) => {
    setDate(newDate);
  };

  return (
    <div className="p-4 max-w-md mx-auto">
      <h1 className="text-white text-center text-xl py-2">Schedule</h1>
      <Calendar
        onChange={handleDateChange}
        value={date}
        tileClassName={({ date, view }) => {
          if (view === "month") {
            if (date.toDateString() === bookedDate.toDateString()) {
              return "react-calendar__tile--active";
            }
            return "react-calendar__tile--disabled"; 
          }
          return "react-calendar__tile";
        }}
        tileDisabled={({ date, view }) => {
          if (view === "month") {
            return date.toDateString() !== bookedDate.toDateString(); 
          }
          return false;
        }}
        formatShortWeekday={(locale, date) =>
          date.toLocaleDateString(locale, { weekday: "short" }).charAt(0)
        }
        formatMonthYear={(locale, date) =>
          date
            .toLocaleDateString(locale, { month: "long", year: "numeric" })
            .replace(" ", " ")
        }
      />
    </div>
  );
};

export default Schedule;
