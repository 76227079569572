import React from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import {
  UilAngleLeft,
  UilAngleRight,
  UilLeftArrowToLeft,
  UilArrowToRight,
} from "@iconscout/react-unicons";

const Table = ({
  columns,
  data,
  initialSortBy,
  fullWidth,
  pagination,
  onPageChange,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: pagination.currentPage - 1,
        pageSize: pagination.perPage,
        sortBy: initialSortBy || [],
      },
      manualPagination: true,
      pageCount: pagination.lastPage,
    },
    useSortBy,
    usePagination
  );

  const handlePageChange = (newPage) => {
    onPageChange(newPage);
  };

  return (
    <div className={`w-full ${fullWidth ? "max-w-full" : ""} overflow-x-auto`}>
      <table
        {...getTableProps()}
        className="w-full border-collapse bg-gray-800 text-left text-sm text-gray-300"
      >
        <thead className="bg-gray-700">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="px-6 py-4 font-medium text-white"
                  style={{
                    minWidth: index === 0 ? "60px" : "auto",
                    width: index === 0 ? "60px" : "auto",
                  }}
                >
                  {column.render("Header") === "No." ? "S/N" : column.render("Header")}
                  <span className="ml-1">
                    {column.isSorted
                      ? column.isSortedDesc
                        ? "▼"
                        : "▲"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="divide-y divide-gray-700">
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="hover:bg-gray-700">
                {row.cells.map((cell, index) => (
                  <td
                    {...cell.getCellProps()}
                    className="px-6 py-4"
                    style={{
                      minWidth: index === 0 ? "60px" : "auto",
                      width: index === 0 ? "60px" : "auto",
                    }}
                  >
                    {index === 0 ? i + 1 : cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex items-center justify-between border-t border-gray-700 bg-gray-800 px-4 py-3 sm:px-6">
        <div className="flex flex-1 justify-between sm:hidden">
          <button
            onClick={() => handlePageChange(pagination.currentPage - 1)}
            disabled={!canPreviousPage}
            className="relative inline-flex items-center rounded-md border border-gray-600 bg-gray-800 px-4 py-2 text-sm font-medium text-gray-400 hover:bg-gray-700"
          >
            Previous
          </button>
          <button
            onClick={() => handlePageChange(pagination.currentPage + 1)}
            disabled={!canNextPage}
            className="relative ml-3 inline-flex items-center rounded-md border border-gray-600 bg-gray-800 px-4 py-2 text-sm font-medium text-gray-400 hover:bg-gray-700"
          >
            Next
          </button>
        </div>
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-400">
              Showing{" "}
              <span className="font-medium">{pagination.perPage * (pagination.currentPage - 1) + 1}</span>{" "}
              to{" "}
              <span className="font-medium">
                {Math.min(pagination.perPage * pagination.currentPage, pagination.total)}
              </span>{" "}
              of <span className="font-medium">{pagination.total}</span> results
            </p>
          </div>
          <div>
            <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
              <button
                onClick={() => handlePageChange(1)}
                disabled={!canPreviousPage}
                className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-600 hover:bg-gray-700 focus:z-20 focus:outline-offset-0"
              >
                <span className="sr-only">First</span>
                <UilLeftArrowToLeft className="h-5 w-5" aria-hidden="true" />
              </button>
              <button
                onClick={() => handlePageChange(pagination.currentPage - 1)}
                disabled={!canPreviousPage}
                className="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-600 hover:bg-gray-700 focus:z-20 focus:outline-offset-0"
              >
                <span className="sr-only">Previous</span>
                <UilAngleLeft className="h-5 w-5" aria-hidden="true" />
              </button>
              <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-600 focus:outline-offset-0">
                Page {pagination.currentPage} of {pagination.lastPage}
              </span>
              <button
                onClick={() => handlePageChange(pagination.currentPage + 1)}
                disabled={!canNextPage}
                className="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-600 hover:bg-gray-700 focus:z-20 focus:outline-offset-0"
              >
                <span className="sr-only">Next</span>
                <UilAngleRight className="h-5 w-5" aria-hidden="true" />
              </button>
              <button
                onClick={() => handlePageChange(pagination.lastPage)}
                disabled={!canNextPage}
                className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-600 hover:bg-gray-700 focus:z-20 focus:outline-offset-0"
              >
                <span className="sr-only">Last</span>
                <UilArrowToRight className="h-5 w-5" aria-hidden="true" />
              </button>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;