import React, { useEffect } from "react";
import Card from "./Card";
import DashboardChart from "./DashboardChart";
import PendingVer from "./PendingVer";
import { adminURL } from "../../../../utils/urls";
import { useQuery } from "@tanstack/react-query";

const fetchDashboardData = async () => {
  const { data } = await adminURL.get(`dashboard`);
  return data.data;
};

const CHome = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["dashboardData"],
    queryFn: fetchDashboardData,
    refetchInterval: 10000,
  });

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <section className="flex">
      <div className="flex flex-col w-full space-y-10 px-10 min-h-screen">
        <Card data={data} isLoading={isLoading} />

        <section className="grid grid-cols-1 xl:grid-cols-3 gap-4">
          <div className="md:col-span-2">
            <DashboardChart data={data} />
          </div>
          <PendingVer data={data} />
        </section>
      </div>
    </section>
  );
};

export default CHome;
