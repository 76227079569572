import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./await.css";
import { adminURL } from "../../../../utils/urls";

const Await = () => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const fetchData = async (page) => {
    setIsLoading(true);
    try {
      const response = await adminURL.get(
        `users?role=coach&approved=0&page=${page}`
      );
      const unapprovedUsers = response.data.data.filter(
        (user) => user.approved === false
      );
      setUsers(unapprovedUsers);
      setTotalPages(response.data.last_page);
      setTotalItems(response.data.total);
      setCurrentPage(page);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData(1);
  }, []);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      fetchData(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      fetchData(currentPage - 1);
    }
  };

  const handleCoachClick = (userId) => {
    navigate(`/dashboard/coaches/coaches-verification/${userId}`);
  };

  return (
    <div className="relative bg-customBlack border border-customBorderTwo shadow-md rounded-lg px-3 py-6 flex flex-col items-center min-w-[250px] md:min-w-[300px] max-w-full">
      <div className="flex justify-between items-center w-full mb-4">
        <h1 className="font-bold text-white">Coaches Awaiting Verification</h1>
      </div>

      <div className="overflow-y-auto max-h-[24rem] custom-scrollbar w-full">
        {isLoading ? (
          <div className="text-white text-center">Loading coaches...</div>
        ) : users.length > 0 ? (
          users.map((user) => (
            <div
              key={user.id}
              className="flex items-center p-2 border-b border-amber-500 cursor-pointer hover:bg-gray-800"
              onClick={() => handleCoachClick(user.id)}
            >
              <img
                src={user.photo_url}
                alt={user.name}
                className="w-12 h-12 mr-4 rounded-full"
              />
              <div className="flex-1 flex items-center space-x-4">
                <div className="text-white">{user.name}</div>
                <div className="text-amber-500">{user.gender}</div>
                <div className="text-white">{user.city || "N/A"}</div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-white text-center mt-4">
            No coaches awaiting verification 📝
          </div>
        )}
      </div>

      {/* Conditionally show pagination if there are users */}
      {users.length > 0 && (
        <div className="text-sm text-white flex items-center mt-4">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className="cursor-pointer px-2 py-1 mr-2 bg-gray-700 rounded hover:bg-gray-600 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            &lt;
          </button>
          <span>
            {isLoading
              ? "Loading..."
              : `${(currentPage - 1) * 15 + 1}-${Math.min(
                  currentPage * 15,
                  totalItems
                )} of ${totalItems}`}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="cursor-pointer px-2 py-1 ml-2 bg-gray-700 rounded hover:bg-gray-600 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            &gt;
          </button>
        </div>
      )}
    </div>
  );
};

export default Await;
