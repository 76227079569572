import React, { useState, useMemo } from "react";
import Table from "../Table";
// import Img from "../../../../../assets/coachassets/images-videos/wande.png";

const generateData = () => {
  const states = [
    "Abia",
    "Adamawa",
    "Akwa Ibom",
    "Anambra",
    "Bauchi",
    "Bayelsa",
    "Benue",
    "Borno",
    "Cross River",
    "Delta",
    "Ebonyi",
    "Edo",
    "Ekiti",
    "Enugu",
    "Gombe",
    "Imo",
    "Jigawa",
    "Kaduna",
    "Kano",
    "Katsina",
    "Kebbi",
    "Kogi",
    "Kwara",
    "Lagos",
    "Nasarawa",
    "Niger",
    "Ogun",
    "Ondo",
    "Osun",
    "Oyo",
    "Plateau",
    "Rivers",
    "Sokoto",
    "Taraba",
    "Yobe",
    "Zamfara",
  ];
  const data = [];

  for (let i = 0; i < 15; i++) {
    const state = states[Math.floor(Math.random() * states.length)];
    data.push({
      client: `Lemon Mane`,
      date: `2023-${Math.floor(Math.random() * 12 + 1)
        .toString()
        .padStart(2, "0")}-${Math.floor(Math.random() * 28 + 1)
        .toString()
        .padStart(2, "0")}`,
      time: `At ${Math.floor(Math.random() * 12 + 1)}:${Math.floor(
        Math.random() * 60
      )
        .toString()
        .padStart(2, "0")} ${Math.random() > 0.5 ? "AM" : "PM"}`,
      invoice: `${state.slice(0, 3).toUpperCase()}${Math.floor(
        100000 + Math.random() * 900000
      )}`,
      amount: `$${(Math.random() * 100).toFixed(2)}`,
      status: `Credit`,
    });
  }
  return data;
};

const Earnings = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [data, setData] = useState(generateData());

  const columns = useMemo(
    () => [
      {
        Header: "Client",
        accessor: "client",
        Cell: ({ row }) => (
          <div className="flex items-center space-x-4 capitalize">
            <div className="avatar h-10 w-10">
              <img
                src={''}
                alt="Client"
                className="rounded-full object-cover object-center h-full w-full"
              />
            </div>
            <span className="font-medium text-base text-slate-300 line-clamp-2">
              {row.original.client}
            </span>
          </div>
        ),
      },
      {
        Header: "Date",
        accessor: "date",
        Cell: ({ row }) => (
          <div>
            <div>{row.original.date}</div>
            <div>{row.original.time}</div>
          </div>
        ),
      },
      {
        Header: "Invoice",
        accessor: "invoice",
        Cell: ({ value }) => <span className="uppercase">{value}</span>,
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value }) => <span className="text-white">{value}</span>,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (
          <span className="py-1 px-2 rounded text-white">{value}</span>
        ),
      },
    ],
    []
  );

  const filteredData = data.filter((item) => {
    const itemDate = new Date(item.date);
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;

    if (start && end) {
      return itemDate >= start && itemDate <= end;
    } else if (start) {
      return itemDate >= start;
    } else if (end) {
      return itemDate <= end;
    } else {
      return true;
    }
  });

  return (
    <div className="p-6 bg-gray-800 min-h-screen">
      <div className="mb-6 flex justify-end space-x-0 space-y-4 flex-col md:flex-row md:space-x-4 md:space-y-0">
        <div className="flex flex-col">
          <label htmlFor="start-date" className="text-white mb-1">
            Start Date
          </label>
          <input
            id="start-date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="bg-transparent border border-white text-white p-2 rounded"
            placeholder="Start Date"
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="end-date" className="text-white mb-1">
            End Date
          </label>
          <input
            id="end-date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="bg-transparent border border-white text-white p-2 rounded"
            placeholder="End Date"
          />
        </div>
      </div>
      <Table
        columns={columns}
        data={filteredData}
        initialSortBy={[{ id: "date", desc: true }]}
        headStyles="custom-head-styles"
        fullWidth
      />
    </div>
  );
};

export default Earnings;
