import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

// Register necessary chart components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// Chart options
const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
        borderDash: [8, 4],
        color: "white",
      },
      ticks: {
        color: "white",
      },
    },
    y: {
      min: 0,
      max: 24,
      ticks: {
        stepSize: 2,
        callback: (value) => value,
      },
      grid: {
        display: true,
        color: "gray",
        borderDash: [4, 4],
      },
      title: {
        display: true,
        text: "Hours",
        color: "white",
        font: {
          size: 14,
        },
        padding: { top: 20, bottom: 0 },
        align: "end",
        lineHeight: 1.2,
      },
    },
  },
  barPercentage: 0.8,
  categoryPercentage: 0.8,
};

const DashboardChart = ({ data }) => {
  if (!data?.hours_chart) {
    return (
      <div className="flex justify-center text-center items-center w-full h-full">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-amber-500"></div>
      </div>
    );
  }

  const chartData = {
    labels: data.hours_chart.labels,
    datasets: [
      {
        label: data.hours_chart.datasets[0].label,
        data: data.hours_chart.datasets[0].data,
        backgroundColor: "#165DFF",
        borderRadius: 5,
      },
    ],
  };

  return (
    <div className="relative rounded-lg bg-black bg-opacity-10 border border-customBorderTwo px-3 py-6 shadow-md min-w-[250px] md:min-w-[400px]">
      <h2 className="text-white mb-5 ml-5 text-sm font-bold">Activity</h2>

      <div className="h-72 p-5">
        <Bar options={options} data={chartData} />
      </div>
    </div>
  );
};

export default DashboardChart;
