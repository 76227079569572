import React from "react";

const SessionTabs = ({ handleTabChange, activeTab }) => {
  return (
    <section className="flex space-x-12 px-10 py-4">
      <button
        // onClick={() => handleTabChange("weekdays")}
        className={`${
          activeTab === "weekdays"
            ? "bg-amber-500 text-black"
            : "text-amber-500 text-lg px-6 py-3"
        } flex-1 text-sm px-4 py-2 rounded-lg capitalize font-semibold text-center cursor-none md:text-xl`}
      >
        Session
      </button>
      {/* <button
        onClick={() => handleTabChange("weekends")}
        className={`${
          activeTab === "weekends"
            ? "bg-white text-gray-900"
            : "text-amber-500 text-lg px-6 py-3"
        } flex-1 text-sm px-4 py-2 rounded-lg capitalize font-semibold text-center md:text-xl`}
      >
        Weekends
      </button> */}
    </section>
  );
};

export default SessionTabs;
