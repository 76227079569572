import React from "react";

const SessionHeader = () => {
  return (
    <section>
      <div className="flex flex-col justify-between items-center w-full md:flex-row px-8">
        <div className="flex flex-col space-y-4 space-x-0 md:flex-row md:space-y-0 md:space-x-6">
          <h1 className="text-white">
            Click on <span className="text-amber-500">Add slots</span> to
            create meeting schedules
          </h1>
        </div>
      </div>
    </section>
  );
};

export default SessionHeader;
