import React, { useEffect, useState } from "react";
import SlotImg from "../../../../../assets/coachassets/images-videos/sessionImg.png";
import Schedule from "./Schedule";
import MorningSlot from "./MorningSlot";
// import EveningSlot from "./EveningSlot";
import SessionHeader from "./SessionHeader";

const Weekdays = () => {
  const userID = localStorage.getItem("refreshID");
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [firstDayOfMonth, setFirstDayOfMonth] = useState(null);

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);


  return (
    <>
      <SessionHeader />
      <section className="grid lg:grid-cols-1 xl:grid-cols-3 gap-2 py-8">
        <section className="col-span-1 md:col-span-1 p-4 rounded-md">
          <Schedule
            selectedDate={selectedDate}
            onDateChange={handleDateChange}
            setFirstDayOfMonth={setFirstDayOfMonth}
          />
          <img
            src={SlotImg}
            alt="slot-img text-2xl"
            className="hidden md:flex"
          />
        </section>
        <section className="col-span-2 px-4 space-8 md:col-span-2">
          <MorningSlot
            userID={userID}
            selectedDate={selectedDate}
            firstDayOfMonth={firstDayOfMonth}
          />
          {/* <EveningSlot /> */}
        </section>
      </section>
    </>
  );
};

export default Weekdays;
