import React from "react";
import CBOne from "./CBOne";

const Bone = () => {
  return (
    <section className="px-10">
      <h1 className="text-white text-md text-center md:text-left md:text-2xl">
        Basic details
      </h1>
      <section>
        <CBOne/>
      </section>
    </section>
  );
};

export default Bone;
