import React, { useState, useCallback } from "react";
import { FaImage, FaTimes } from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useDropzone } from "react-dropzone";
import Swal from "sweetalert2";

const Preview = ({ setCoverImage, imagePreview }) => {
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleImageUpload = async (file) => {
    if (file) {
      setLoading(true);
      try {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(reader.result);
          setCoverImage(file);
          setLoading(false);
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.error("Error uploading image:", error);
        setLoading(false);
      }
    }
  };

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Invalid file type",
        text: "Please upload an image file.",
        confirmButtonColor: "#3085d6",
      });
    } else if (acceptedFiles.length > 0) {
      const largeFiles = acceptedFiles.filter(file => file.size > 5 * 1024 * 1024);
      if (largeFiles.length > 0) {
        Swal.fire({
          icon: "error",
          title: "File too large",
          text: "Files must be less than 5MB.",
          confirmButtonColor: "#3085d6",
        });
        return;
      }
      handleImageUpload(acceptedFiles[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: "image/*",
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
  });

  const removeImage = () => {
    setImage(null);
    setCoverImage(null);
  };

  return (
    <section
      {...getRootProps()}
      className="flex flex-col items-center justify-center bg-gray-100 p-8 rounded-xl shadow-lg w-full h-full relative"
    >
      <input {...getInputProps()} />
      <div className="w-full flex flex-col items-center">
        {loading ? (
          <div className="flex flex-col items-center justify-center">
            <AiOutlineLoading3Quarters className="text-6xl text-gray-900 mb-4 animate-spin" />
            <p className="text-gray-900 font-bold md:text-xl">Uploading...</p>
          </div>
        ) : (
          <>
            {(image || imagePreview) && (
              <div className="relative w-full mb-4">
                <img
                  src={image || imagePreview}
                  alt="Cover"
                  className="w-full h-auto rounded-lg"
                />
                <button
                  type="button"
                  onClick={removeImage}
                  className="absolute top-2 right-2 bg-red-600 text-white rounded-full p-2 hover:bg-red-800"
                >
                  <FaTimes className="text-lg" />
                </button>
              </div>
            )}
            <div className="flex flex-col items-center">
              <FaImage className="text-6xl text-gray-900 mb-4" />
              <p className="mb-4 text-gray-900 font-bold md:text-xl text-center">
                {image || imagePreview
                  ? "Change Cover Image"
                  : "Upload Cover Image"}
              </p>
              <button
                type="button"
                onClick={open}
                className="px-6 py-2 bg-primary rounded-md text-black cursor-pointer"
              >
                {image || imagePreview ? "Change Image" : "Upload Image"}
              </button>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default Preview;
