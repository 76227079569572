import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import Cards from "./cards/Cards";
import DashboardChart from "./chart/DashboardChart";
import Await from "./awaiting-verfication/Await";
import { adminURL } from "../../../utils/urls";

const fetchDashboardData = async () => {
  const { data } = await adminURL.get(`dashboard`);
  return data.data;
};

const Home = () => {
  const { data } = useQuery({
    queryKey: ["dashboardData"],
    queryFn: fetchDashboardData,
  });
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <section className="flex">
      <div className="flex flex-col w-full space-y-10 px-10 min-h-screen">
        <Cards data={data} />
        <section className="grid grid-cols-1 xl:grid-cols-3 gap-4">
          <div className="md:col-span-2">
            <DashboardChart data={data?.user_statistics} />
          </div>
          <Await />
        </section>
      </div>
    </section>
  );
};

export default Home;
