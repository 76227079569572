import React from "react";
import { useStepContext } from "../../../context/stepContext";
import Bone from "../c_forms/Bone";
import Btwo from "../c_forms/Btwo";

const Details = () => {
  const { currentStep } = useStepContext();
  const displayForms = (step) => {
    switch (step) {
      case 1:
        return <Bone />;
      case 2:
        return <Btwo/> ;

      default:
        return <Bone />;
    }
  };
  return <div>{displayForms(currentStep)}</div>;
};

export default Details;
