import React, { useState, useEffect } from "react";
import Table from "./Table";
import { adminURL } from "../../../../../utils/urls";
import UnBanButton from "./UnbanButton";

function BlockedUsers() {
  const [blocked, setBlocked] = useState([]);
  const [loading, setLoading] = useState();
  const [pagination, setPagination] = useState({
    currentPage: 1,
    perPage: 10,
    lastPage: 1,
    total: 0,
  });

  useEffect(() => {
    fetchBanned();
  }, []);

  const fetchBanned = async (page = 1) => {
    setLoading(true);
    try {
      const response = await adminURL.get(`users?banned=true&role=user`);
      setBlocked(response?.data?.data);
      setPagination({
        currentPage: response.data.current_page,
        lastPage: response.data.last_page,
        total: response.data.total,
        perPage: response.data.per_page,
      });
      setLoading(false);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const initialSortBy = [{ id: "serial_no", desc: false }];

  const columns = [
    {
      Header: "S/N",
      accessor: "serial_no",
      Cell: ({ row }) => (
        <span className="text-white font-medium">{row.index + 1}</span>
      ),
    },
    {
      Header: "Blocked User",
      accessor: (row) => (
        <div className="flex items-center space-x-4 capitalize">
          <section className="flex items-center justify-start space-x-4">
            <div className="avatar h-12 w-12">
              <img
                src={row.photo_url}
                alt="Offender"
                className="rounded-full object-cover object-center h-full w-full"
              />
            </div>
            <span className="font-medium text-base text-slate-300 line-clamp-2">
              {row.name}
            </span>
          </section>
        </div>
      ),
    },
    {
      Header: "Action",
      accessor: (row) => (
        <div className="flex items-center justify-center space-x-4">
          <UnBanButton id={row.id} />
        </div>
      ),
    },
  ];

  return (
    <div className="w-full min-w-[800px] rounded-b-lg p-3 space-y-4">
      {loading ? (
        <div className="flex items-center justify-center w-full h-32">
          <div className="w-16 h-16 border-4 border-t-4 border-white border-solid rounded-full animate-spin"></div>
        </div>
      ) : blocked.length > 0 ? (
        <div>
          <Table
            columns={columns}
            data={blocked}
            initialSortBy={initialSortBy}
            fullWidth={true}
            pagination={pagination}
            onPageChange={fetchBanned}
          />
        </div>
      ) : (
        <div className="text-center py-4 text-white text-xl">
          All users are in their best behaviour!
        </div>
      )}
    </div>
  );
}

export default BlockedUsers;
