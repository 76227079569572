import React, { useEffect, useState } from "react";
import { CgSortAz } from "react-icons/cg";
import Pagination from "../../../pagination/Pagination";
import Ccontent from "./CoachContent.js/C_content";
import { adminURL } from "../../../../utils/urls";
import Loader from "../../../loader/FetchLoader";

const Coaches = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [showSortOptions, setShowSortOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [loading, setLoading] = useState(true);
  const itemsPerPage = 15;

  const fetchData = async (page) => {
    setLoading(true);
    try {
      const response = await adminURL.get(`users?role=coach&page=${page}`);
      setUsers(response.data.data);
      setTotalUsers(response.data.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    if (page > 0 && page <= Math.ceil(totalUsers / itemsPerPage)) {
      setCurrentPage(page);
    }
  };

  return (
    <section className="flex flex-col w-full p-5 space-y-5 min-h-screen bg-customBlack min-w-[300px] pt-40 md:pt-0">
      {loading ? (
        <Loader text="Fetching coaches..." />
      ) : (
        <>
          <header className="flex flex-col md:flex-row justify-end items-center py-5 px-5 md:px-10 rounded-md space-y-4 md:space-y-0 md:space-x-8">
            <h1 className="text-xl text-white">Coaches Profile Management</h1>
            {users.length > 0 && (
              <>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalUsers}
                  itemsPerPage={itemsPerPage}
                  onPageChange={handlePageChange}
                />
                <div className="flex items-center relative">
                  <div
                    className="flex items-center space-x-2 md:space-x-6 cursor-pointer bg-gray-800 px-2 md:px-4 rounded-lg"
                    onClick={() => setShowSortOptions((prev) => !prev)}
                  >
                    <span className="text-white text-sm md:text-base">Sort by</span>
                    <CgSortAz className="text-white text-2xl md:text-4xl" />
                  </div>
                  {showSortOptions && (
                    <div
                      style={{ backgroundColor: "rgba(12, 17, 23, 1)" }}
                      className="absolute top-full left-0 mt-2 border border-amber-900 text-white rounded-lg shadow-lg z-10"
                    >
                      <div className="flex flex-col space-y-2">
                        <label className="cursor-pointer flex justify-between items-center text-[10px] px-6 py-2">
                          By Payment
                          <input
                            type="checkbox"
                            checked={selectedOption === "payment"}
                            onChange={() => setSelectedOption("payment")}
                            className="ml-2 cursor-pointer rounded-full text-amber-500"
                          />
                        </label>
                        <div className="border-t border-amber-900"></div>
                        <label className="cursor-pointer flex justify-between items-center text-[10px] px-6 py-2">
                          By Endorsement
                          <input
                            type="checkbox"
                            checked={selectedOption === "endorsement"}
                            onChange={() => setSelectedOption("endorsement")}
                            className="ml-2 cursor-pointer rounded-full text-amber-500"
                          />
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </header>

          {users.length === 0 ? (
            <div className="text-white text-center text-lg">
              No coaches awaiting verification
            </div>
          ) : (
            <section className="flex justify-center w-full">
              <Ccontent users={users} />
            </section>
          )}
        </>
      )}
    </section>
  );
};

export default Coaches;
