import React, { useState } from "react";
import PendingTable from "./PendingTable";
import Chart from "./Chart";
import PendingProfile from "./pendingProfile/PendingProfile";
import { useQuery } from "@tanstack/react-query";
import { adminURL } from "../../../../utils/urls";

const fetchDashboardData = async () => {
  const { data } = await adminURL.get(`dashboard`);
  return data.data;
};

const Past = () => {
  const [fetchApp, setFetchApp] = useState();

  const { data } = useQuery({
    queryKey: ["dashboardData"],
    queryFn: fetchDashboardData,
    refetchInterval: 10000,
  });

  return (
    <section
      className={`grid grid-cols-1 ${
        fetchApp && fetchApp?.length > 0 ? "xl:grid-cols-3" : "xl:grid-cols-1"
      } gap-2 px-8`}
    >
      <section
        className={`col-span-1 px-4 ${
          fetchApp && fetchApp?.length > 0 ? "xl:col-span-2" : "xl:col-span-1"
        }`}
      >
        <PendingTable setFetchApp={setFetchApp} />
        <Chart data={data} />
      </section>
      {fetchApp && fetchApp?.length > 0 && (
        <section className="my-10 xl:my-0 rounded-md md:border md:border-primary">
          <section className="px-2 md:px-6 flex justify-center">
            <PendingProfile person={data} />
          </section>
        </section>
      )}
    </section>
  );
};

export default Past;
