import React, { lazy, Suspense } from "react";

import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Login from "./pages/Login";
// import Dashboard from "./pages/Dashboard";
import Home from "./components/dashboard/dashboard/Home";
import UserReport from "./components/dashboard/users/report/UserReport";
import Verification from "./components/dashboard/users/verification/Verification";
import Analytics from "./components/dashboard/users/analytics/Analytics";
import SingleContent from "./components/singleContent/SingleContent";
import Coaches from "./components/dashboard/coaches/coaches/Coaches";
import Sc from "./components/singleCoaches/Sc";
import Canalytics from "./components/dashboard/coaches/coaches/analytics/Canalytics";
// import PushNotifications from "./components/dashboard/push_notifications/PushNotifications";
import AddNotification from "./components/dashboard/push_notifications/AddNotification";
import Events from "./components/dashboard/events/Events";
import Rsvp from "./components/dashboard/events/Tables/Rsvp";
import CreateEvents from "./components/dashboard/events/create_notifications/CreateEvents";
import PreviewEvent from "./components/dashboard/events/create_notifications/preview_events/PreviewEvent";
// import Support from "./components/dashboard/support/Support";
// import CLogin from "./coaches-platform/pages/CLogin";
import Signup from "./pages/Signup";
// import CDashboard from "./coaches-platform/pages/CDashboard";
import CHome from "./coaches-platform/components/cdashboardComponents/cdashboard/CHome";
import CreateSession from "./coaches-platform/components/cCreateSession/CreateSession";
// import Pending from "./coaches-platform/components/cAppointment/pending/Pending";
// import Confirmed from "./coaches-platform/components/cAppointment/confirmed/Confirmed";
// import Declined from "./coaches-platform/components/cAppointment/declined/Declined";
import Upload from "./coaches-platform/components/cpublication/upload/Upload";
import Publications from "./coaches-platform/components/cpublication/publications/Publications";
import Payment from "./coaches-platform/components/cpayment/Payment";
import CSupport from "./coaches-platform/components/csupport/CSupport";
import Profile from "./coaches-platform/components/cprofile/Profile";
import Logout from "./pages/Logout";
import CLogout from "./coaches-platform/pages/CLogout";
import Waiting from "./pages/Waiting";
import PasswordReset from "./pages/PasswordReset";
import UpdateEvent from "./components/dashboard/events/create_notifications/update/UpdateEvent";
import Update from "./coaches-platform/components/cpublication/update/Update";
import SingleSlot from "./coaches-platform/pages/SingleSlot";
import Loader from "./components/loader/FetchLoader";
import Appointment from "./coaches-platform/components/cAppointment/Appointment/Appointment";

const Support = lazy(() => import("./components/dashboard/support/Support"));

const Pending = lazy(() =>
  import("./coaches-platform/components/cAppointment/pending/Pending")
);

const Dashboard = lazy(() => import("./pages/Dashboard"));
const CDashboard = lazy(() => import("./coaches-platform/pages/CDashboard"));

function App() {
  const router = createBrowserRouter([
    { path: "/", element: <Logout /> },
    // admin-dashboard
    { path: "/login", element: <Login /> },
    { path: "/logout", element: <Logout /> },
    { path: "/password-reset/:id", element: <PasswordReset /> },
    {
      path: "/dashboard",
      element: (
        <Suspense fallback={<Loader text="" />}>
          <Dashboard />
        </Suspense>
      ),
      children: [
        { index: true, element: <Home /> },
        { path: "users", element: <UserReport /> },
        { path: "users/reports", element: <UserReport /> },
        {
          path: "users/user-verification",
          element: <Verification />,
        },
        {
          path: "users/analytics",
          element: <Analytics />,
        },
        {
          path: "users/user-verification/:id",
          element: <SingleContent />,
        },
        { path: "coaches", element: <Coaches /> },
        { path: "coaches/view-coaches", element: <Coaches /> },
        { path: "coaches/analytics", element: <Canalytics /> },
        { path: "coaches/coaches-verification/:id", element: <Sc /> },
        // { path: "push-notifications", element: <PushNotifications /> },
        { path: "push-notifications", element: <AddNotification /> },
        // {
        //   path: "push-notifications/add-notification",
        //   element: <AddNotification />,
        // },
        { path: "events", element: <Events /> },
        { path: "events/:id/rsvp", element: <Rsvp /> },
        { path: "events/create", element: <CreateEvents /> },
        {
          path: "events/create/preview/:name/:eventID",
          element: <PreviewEvent />,
        },
        {
          path: "events/update/:name/:eventID",
          element: <UpdateEvent />,
        },
        {
          path: "support",
          element: (
            <Suspense fallback={<Loader text="" />}>
              <Support />
            </Suspense>
          ),
        },
      ],
    },
    // coaches platform
    // { path: "/coaches/login", element: <CLogin /> },
    { path: "/coaches/signup", element: <Signup /> },
    { path: "/coaches/logout", element: <CLogout /> },
    { path: "/coaches/approval-status", element: <Waiting /> },
    {
      path: "/coaches/dashboard",
      element: (
        <Suspense fallback={<Loader text="" />}>
          <CDashboard />
        </Suspense>
      ),

      children: [
        { index: true, element: <CHome /> },
        // create session
        { path: "create-session", element: <CreateSession /> },
        // appointment
        {
          path: "appointments",
          element: (
            <Suspense fallback={<Loader text="Your Appointments" />}>
              <Appointment />
            </Suspense>
          ),
        },
        { path: "appointments/pending", element: <Pending /> },
        // { path: "appointment/confirmed", element: <Confirmed /> },
        // { path: "appointment/declined", element: <Declined /> },
        // slots
        { path: "appointments/details/:id", element: <SingleSlot /> },
        // // publication
        { path: "publication", element: <Upload /> },
        { path: "publication/upload", element: <Upload /> },
        { path: "publication/upload/delete/:id", element: <Update /> },
        { path: "publication/publications", element: <Publications /> },
        // payment
        { path: "payment", element: <Payment /> },
        // support
        { path: "support", element: <CSupport /> },
        // profile
        { path: "profile", element: <Profile /> },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
