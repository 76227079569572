import React, { useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const Buttons = ({ data, onCancel, isCancelling }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleCancelClick = () => {
    setIsModalOpen(true);
  };

  const handleConfirmCancel = () => {
    setIsLoading(true);
    onCancel();

    setTimeout(() => {
      window.location.reload();
    }, 5000);
  };

  const handlePAppClick = (userId) => {
    navigate(`/coaches/dashboard/appointments/details/${userId}`);
  };

  return (
    <>
      <div className="flex justify-center items-center h-full p-4">
        <button
          className="bg-primary hover:bg-amber-600 text-black font-bold py-2 px-8 rounded-md mx-2"
          onClick={() => handlePAppClick(data?.slot.id)}
        >
          View
        </button>
        <button
          onClick={handleCancelClick}
          className="bg-transparent border border-white hover:bg-primary text-white hover:text-black hover:border-primary font-bold py-2 px-8 rounded-md mx-2"
        >
          Cancel
        </button>
      </div>

      {isModalOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
        >
          <motion.div
            initial={{ y: "-100vh" }}
            animate={{ y: 0 }}
            exit={{ y: "-100vh" }}
            className="bg-gray-800 text-white p-8 rounded-lg shadow-lg"
          >
            <p className="mb-4">
              Are you sure you want to decline this appointment?
            </p>
            <div className="flex justify-between">
              <button
                onClick={() => setIsModalOpen(false)}
                className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmCancel}
                className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                disabled={isCancelling}
              >
                {isCancelling || isLoading ? (
                  <motion.div
                    className="animate-spin h-5 w-5 border-t-2 border-b-2 border-white rounded-full"
                    aria-label="Loading"
                  ></motion.div>
                ) : (
                  "OK"
                )}
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </>
  );
};

export default Buttons;
