import React from "react";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts/core";
import { MapChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  VisualMapComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import worldGeoJSON from "echarts-countries-js/world-x.json";
import { adminURL } from "../../utils/urls";
import { useQuery } from "@tanstack/react-query";

echarts.use([
  MapChart,
  TitleComponent,
  TooltipComponent,
  VisualMapComponent,
  CanvasRenderer,
]);

echarts.registerMap("world", worldGeoJSON);

const fetchLocationDistData = async () => {
  const { data } = await adminURL.get(`charts/location-distribution`);
  return data;
};

const LocationDistributionChart = () => {
  const { data: userLocationData, isLoading, error } = useQuery({
    queryKey: ["location-dist"],
    queryFn: fetchLocationDistData,
  });

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading data</div>;

 
  const locationData = userLocationData?.series?.[0]?.data || [];


  const validData = locationData.length > 0;

  const getChartOption = () => {
    const maxValue = Math.max(...locationData.map((item) => item.value));

    return {
      tooltip: {
        trigger: "item",
        formatter: "{b}: {c} users",
      },
      visualMap: {
        min: 0,
        max: maxValue,
        left: "left",
        top: "bottom",
        text: ["High", "Low"],
        calculable: true,
        inRange: {
          color: ["yellow", "red"],
        },
      },
      series: [
        {
          name: "User Distribution",
          type: "map",
          map: "world",
          roam: true,
          label: {
            show: false,
          },
          emphasis: {
            label: {
              show: false,
              color: "#fff",
            },
          },
          data: locationData,
        },
      ],
    };
  };

  return (
    <div className="flex flex-col bg-gray-500 bg-opacity-10 w-full space-y-1 rounded-xl relative shadow-xl">
      <h2 className="text-white text-left text-lg border-[0.5px] border-customBorderTwo rounded-t-lg py-3 px-4">
        Heat Map
      </h2>
      {validData ? (
        <ReactECharts
          option={getChartOption()}
          style={{ height: "500px", width: "100%" }}
          notMerge={true}
          lazyUpdate={true}
        />
      ) : (
        <div className="p-4 text-white">No user location data available</div>
      )}
    </div>
  );
};

export default LocationDistributionChart;
