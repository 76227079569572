import { createSlice } from "@reduxjs/toolkit";

const getCoachFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem("coach")) || null;
};

const getAdminFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem("admin")) || null;
};

const initialState = {
  coach: getCoachFromLocalStorage(),
  admin: getAdminFromLocalStorage(),
};
const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    loginUser: (state, action) => {
      console.log(action);
    },
  },
});

export const { loginUser } = usersSlice.actions;
export default usersSlice.reducer;
