import React from "react";
// import UserMatchesMap from "../../../../charts/UserMatchesMap";
import MessagingActivityChart from "../../../../charts/MessagingActivityChart";

const UserInteraction = () => {
  return (
    <div className="flex flex-col w-full space-y-3 min-h-screen">
      <div className="p-3 rounded-b-lg space-y-4">
        {/* <div className="grid grid-cols-1 gap-4">
          <UserMatchesMap />
        </div> */}
        <div className="grid grid-cols-1 gap-4">
          <MessagingActivityChart />
        </div>
      </div>
    </div>
  );
};

export default UserInteraction;
