import React, { useState } from "react";
import { adminURL } from "../../../../../utils/urls";

const BanButton = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleBanClick = async () => {
    if (!id) return;

    setLoading(true);
    setSuccess(false);

    try {
      const response = await adminURL.put(`users/${id}`, {
        banned: true,
      });

      if (response.status !== 200) {
        throw new Error("Failed to ban user");
      }

      setSuccess(true);
      setLoading(false);

      window.location.reload();
    } catch (error) {
      console.error("Error banning user:", error);
      setLoading(false);
      alert("Failed to ban user");
    }
  };

  return (
    <div>
      {loading ? (
        <button
          disabled
          className="bg-gray-400 text-white py-2 px-4 rounded cursor-not-allowed"
        >
          <span className="animate-spin">🔄</span> Banning user...
        </button>
      ) : success ? (
        <button
          disabled
          className="bg-green-600 text-white py-2 px-4 rounded cursor-not-allowed"
        >
          Success!
        </button>
      ) : (
        id && (
          <button
            className="bg-red-600 text-white py-2 px-4 rounded cursor-pointer hover:bg-amber-500"
            onClick={handleBanClick}
          >
            Ban
          </button>
        )
      )}
    </div>
  );
};

export default BanButton;
