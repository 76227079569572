import React, { useState } from "react";
import Logo from "../assets/images/soloLogo.png";
import Bottom from "../assets/images/Bottom.png";
import Icon from "../assets/images/logo/logo2.png";
import FullLogo from "../assets/images/logo/Platinum-Fuse-Full-Logo.png";
import ForgotPassword from "../components/Auth/ForgotPassword";
import LoginForm from "../components/Auth/LoginForm";

const Login = () => {
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const handleForgotPasswordClick = () => {
    setShowForgotPassword(true);
  };

  const handleGoBackToLogin = () => {
    setShowForgotPassword(false);
  };

  return (
    <div className="h-screen w-screen flex justify-center items-center bg-gray-800 overflow-hidden">
      <div className="grid grid-cols-2 gap-7 w-screen h-screen">
        <div className="flex flex-col justify-center items-center p-8 text-center space-y-5 tablet:bg-black tablet:bg-custom tablet:w-screen">
          <div className="flex justify-center items-center">
            <img
              src={Icon}
              alt="Platinum Fuse Icon"
              className="w-20 tablet:hidden"
            />
            <img
              src={FullLogo}
              alt="Platinum Fuse Icon"
              className="w-3/6 lg:hidden"
            />
          </div>

          {showForgotPassword ? (
            <ForgotPassword onGoBack={handleGoBackToLogin} />
          ) : (
            <LoginForm
              onForgotPasswordClick={handleForgotPasswordClick}
              setIsLoggedIn={true}
            />
          )}
        </div>
        <div className="relative  bg-black rounded-tl-[100px] rounded-bl-[100px] bg-custom tablet:hidden">
          <div className="flex items-center justify-center h-full">
            <div className="text-center">
              <img src={Logo} alt="log" className="mx-auto" />
              <h2 className="text-white text-3xl mt-4 text-center">Platinum Fuse</h2>
            </div>
          </div>
          <img
            src={Bottom}
            alt="bottom"
            className="absolute bottom-0 right-0"
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
