// FilesUpload Component
import React from "react";
import Top from "./Top";
import Bottom from "./Bottom";

const FilesUpload = ({ setTitle,setDescription, setCoverImage, setFile, setPrice }) => {
  return (
    <section className="px-10">
      <div>
        <Top
          setTitle={setTitle}
          setCoverImage={setCoverImage}
          setFile={setFile}
        
        />
        <Bottom setPrice={setPrice}   setDescription={setDescription} />
      </div>
    </section>
  );
};

export default FilesUpload;
