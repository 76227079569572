import React from "react";
import CustomModal from "../CustomModal";

const AddTimeSlots = ({
  isModalOpen,
  setIsModalOpen,
  error,
  newSlot,
  setNewSlot,
  handleSaveSlot,
  setError,
}) => {
  const handleFocus = () => setError("");

  const convertTo24HourFormat = (time) => {
    let [hour] = time.split(":");
    hour = parseInt(hour, 10);

    if (hour < 12 && time.includes("PM")) {
      hour += 12;
    }

    if (hour === 12 && time.includes("AM")) {
      hour = "00";
    }

    return `${hour.toString().padStart(2, "0")}:00`;
  };

  const handleTimeChange = (time, type) => {
    const formattedTime = convertTo24HourFormat(time);
    setNewSlot({ ...newSlot, [type]: formattedTime });
  };

  return (
    <CustomModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <h2 className="text-lg font-bold mb-4">Add New Time Slot (Hourly)</h2>
      <div className="flex flex-col gap-2">
        {error && <div className="text-red-500">{error}</div>}
        <div className="flex flex-wrap justify-between">
          <div className="w-full lg:w-1/2 px-1 mb-2 lg:mb-0">
            <label>
              Start Time:
              <input
                type="time"
                className="w-full border p-2 rounded-md text-black bg-gray-100 outline-none"
                value={newSlot.start}
                onChange={(e) => handleTimeChange(e.target.value, "start")}
                onFocus={handleFocus}
                step="3600"
                required
              />
            </label>
          </div>
          <div className="w-full lg:w-1/2 px-1">
            <label>
              End Time:
              <input
                type="time"
                className="w-full border p-2 rounded-md text-black bg-gray-100 outline-none"
                value={newSlot.end}
                onChange={(e) => handleTimeChange(e.target.value, "end")}
                onFocus={handleFocus}
                step="3600"
                required
              />
            </label>
          </div>
        </div>
        <div className="flex gap-2 mt-4 justify-end">
          <button
            onClick={handleSaveSlot}
            className="bg-amber-600 text-white px-6 py-2 rounded-md"
          >
            Add
          </button>
          <button
            onClick={() => {
              setIsModalOpen(false);
              setError("");
            }}
            className="bg-gray-300 text-black px-4 py-2 rounded-md"
          >
            Cancel
          </button>
        </div>
      </div>
    </CustomModal>
  );
};

export default AddTimeSlots;
