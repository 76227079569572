import React, { useState } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { adminURL } from "../../../../../../utils/urls";
import { motion, AnimatePresence } from "framer-motion";
import BookedSlotDetails from "../../../BookedSlotDetails";

const fetchSlots = async (userID, startDate, endDate) => {
  const response = await adminURL.get(
    `users/${userID}/slots?starts_at=${startDate}&ends_at=${endDate} 23:59:59`
  );
  return response.data;
};

const Slots = ({
  handleAddSlot,
  userID,
  selectedSlots,
  handleSelectSlot,
  handleDeleteSlot,
}) => {
  const [selectedBookedSlot, setSelectedBookedSlot] = useState(null);

  const startDate =
    localStorage.getItem("selectedDate") ||
    new Date().toISOString().split("T")[0];

  const endDate =
    localStorage.getItem("selectedDate") ||
    new Date().toISOString().split("T")[0];

  const queryClient = useQueryClient();

  const { data, error, isLoading } = useQuery({
    queryKey: ["slots", userID, startDate, endDate],
    queryFn: () => fetchSlots(userID, startDate, endDate),
    enabled: !!userID && !!startDate && !!endDate,
    refetchInterval: 10000,
  });

  const handleOptimisticAddSlot = () => {
    handleAddSlot();
    queryClient.invalidateQueries(["slots", userID, startDate, endDate]);
  };

  const handleSlotClick = (slot) => {
    if (slot.booked) {
      setSelectedBookedSlot(slot);
    } else {
      handleSelectSlot(slot);
    }
  };

  const handleCloseDetails = () => {
    setSelectedBookedSlot(null);
  };

  return (
    <div className="border border-amber-500 shadow-xl bg-black p-4 w-full max-w-2xl rounded-md lg:max-w-4xl">
      <div className="text-white flex justify-between items-center mb-2">
        <div className="flex flex-col">
          <span className="text-gray-300 text-sm font-light">slots</span>
        </div>
        <button
          onClick={handleOptimisticAddSlot}
          className="flex items-center space-x-2 px-2 py-1 rounded"
        >
          <span className="bg-primary rounded-full p-2">
            <FaPlus className="text-black" />
          </span>
          <span className="text-primary text-sm">Add slots</span>
        </button>
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center h-40">
          <motion.div
            className="bg-amber-500 p-3 rounded-full"
            animate={{ rotate: 360 }}
            transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
          >
            <div className="loader border-t-transparent border-amber-500 border-4 border-solid rounded-full w-8 h-8" />
          </motion.div>
        </div>
      ) : error ? (
        <div className="text-red-500 flex justify-center items-center h-40">
          <span>😞 Oops! An error occurred. It’s not your fault.</span>
        </div>
      ) : (
        <div className="text-white flex flex-wrap gap-2">
          {data?.data?.length > 0 ? (
            data?.data.map((slot, index) => (
              <motion.div
                key={index}
                className={`flex items-center justify-between shadow-md rounded-md p-2 mb-1 cursor-pointer ${
                  selectedSlots.includes(slot.period_for_humans)
                    ? "bg-primary"
                    : slot.booked
                    ? "bg-red-500 text-white"
                    : "bg-white text-black"
                }`}
                onClick={() => handleSlotClick(slot)}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <div>{slot.period_for_humans}</div>
                {!slot.booked && (
                  <FaTrash
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteSlot(slot.id);
                    }}
                    className="text-red-500 ml-2 cursor-pointer"
                  />
                )}
              </motion.div>
            ))
          ) : (
            <div className="text-gray-400 flex items-center gap-2">
              No slots available <span>😴</span>
            </div>
          )}
        </div>
      )}

      <AnimatePresence>
        {selectedBookedSlot && (
          <BookedSlotDetails
            slot={selectedBookedSlot}
            onClose={handleCloseDetails}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default Slots;
