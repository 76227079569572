import React from "react";
import AgePref from "./agePreference/AgePref";

const Details = ({ profile }) => {
  const formatDOB = (dobString) => {
    const date = new Date(dobString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const getOrdinalSuffix = (d) => {
      if (d > 3 && d < 21) return "th";
      switch (d % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    const ordinalDay = `${day}${getOrdinalSuffix(day)}`;

    return `${ordinalDay} ${month} ${year}`;
  };

  const formattedDOB = formatDOB(profile?.dob);

  return (
    <section className="pt-5 px-4">
      <div className="flex flex-col space-y-4">
        <div className="flex space-x-4">
          <p className="text-white">Gender:</p>
          <p className="text-slate-300">
            {profile?.gender === "M" ? "Male" : "Female"}
          </p>
        </div>
        <div className="flex space-x-4">
          <p className="text-white">Email:</p>
          <p className="text-slate-300">{profile?.email}</p>
        </div>
        <div className="flex space-x-4">
          <p className="text-white">Date of Birth:</p>
          <p className="text-slate-300">{formattedDOB}</p>
        </div>
        <div className="flex space-x-4">
          <p className="text-white">Occupation:</p>
          <p className="text-slate-300 text-sm">{profile?.occupation}</p>
        </div>
      </div>

      <div className="mt-4 space-y-2">
        <AgePref profile={profile} />
        <div className="flex items-center space-x-4">
          <p className="text-white">City:</p>
          <p className="text-slate-300 text-sm">
            {profile?.city ? profile.city : "N|A"}
          </p>
        </div>
      </div>
    </section>
  );
};

export default Details;
