import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const AppointmentGrid = ({ currentID, appointments }) => {
  const [visibleAppointments, setVisibleAppointments] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [showAll, setShowAll] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    const filtered = appointments.filter(
      (appointment) => appointment.id !== parseInt(currentID)
    );
    setVisibleAppointments(filtered.slice(0, showAll ? filtered.length : 8));
  }, [appointments, currentID, showAll]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const handleClick = (id) => {
    navigate(`/coaches/dashboard/appointments/details/${id}`);
    window.location.reload();
  };

  return (
    <div className="p-4 h-[calc(100vh-200px)]">
      {visibleAppointments.length > 0 ? (
        <>
          <motion.div
            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6 overflow-y-auto max-h-full scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-gray-600 scrollbar-track-transparent"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            {visibleAppointments.map((appointment) => (
              <motion.div
                key={appointment.id}
                className="bg-black rounded-lg shadow-md p-4 flex flex-col items-center cursor-pointer hover:bg-gray-800 transition-all duration-300"
                variants={itemVariants}
                onClick={() => handleClick(appointment.id)}
              >
                <img
                  src={appointment.booker.photo_url}
                  alt={appointment.booker.name}
                  className="w-20 h-20 rounded-full mb-2 object-cover"
                />
                <p className="text-sm text-gray-300 truncate w-full text-center">
                  {appointment.booker.email}
                </p>
              </motion.div>
            ))}
          </motion.div>
        </>
      ) : (
        <p className="text-center text-gray-500">No appointments available.</p>
      )}
    </div>
  );
};

export default AppointmentGrid;
