// import AvgResponseTimeChart from "../../../../charts/AvgResponseTimeChart";
import DurationPerCoach from "../../../../charts/DurationPerCoach";
import NumberOfCoachSesh from "../../../../charts/NumberOfCoachSesh";

const  Ccharts = () => {
  return (
    <div className="flex flex-col w-full space-y-3 min-h-screen">
      <div className="p-5 rounded-b-lg space-y-4">
        <div className="grid grid-cols-1 gap-4">
         
          <NumberOfCoachSesh/>
       
        </div>
        {/* <div className="grid grid-cols-1 gap-4">
          < AvgResponseTimeChart/>
            //  
        </div> */}
        <DurationPerCoach/>
      </div>
    </div>
  );
};

export default  Ccharts;
