import React, { useState, useEffect } from "react";
import { CiFaceSmile } from "react-icons/ci";
import { adminURL } from "../../../../utils/urls";
import { useMutation } from "@tanstack/react-query";
import EmojiPicker from "emoji-picker-react";

const sendMessages = async (id, message) => {
  const response = await adminURL.post(`/conversations/${id}/messages`, {
    message,
  });
  return response.data;
};

const notifyTyping = async (id) => {
  await adminURL.post(`/conversations/${id}/typing`);
};

const ChatInput = () => {
  const [message, setMessage] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const conID = localStorage.getItem("activeSupportId");

  const mutation = useMutation({
    mutationFn: (message) => sendMessages(conID, message),
    onSuccess: () => {
      setMessage("");
    },
  });

  const handleSendMessage = () => {
    if (message.trim()) {
      mutation.mutate(message);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleTyping = () => {
    if (conID && message.trim()) {
      notifyTyping(conID);
    }
  };

  const handleEmojiClick = (emojiData) => {
    setMessage((prevMessage) => prevMessage + emojiData.emoji);
    setShowEmojiPicker(false);
  };

  useEffect(() => {
    handleTyping();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  return (
    <footer className="flex items-center bg-black p-4">
      <div className="relative flex-grow">
        <input
          type="text"
          className="pl-4 pr-10 py-2 shadow-md bg-white bg-opacity-50 outline-none rounded-full bg-transparent w-full text-white font-sans"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Type a message"
        />
        <CiFaceSmile
          className="absolute top-1/2 transform -translate-y-1/2 right-3 text-white text-xl cursor-pointer"
          onClick={() => setShowEmojiPicker(!showEmojiPicker)}
        />
        {showEmojiPicker && (
          <div className="absolute bottom-12 right-0 z-10">
            <EmojiPicker onEmojiClick={handleEmojiClick} />
          </div>
        )}
      </div>
      <button
        onClick={handleSendMessage}
        className={`ml-2 text-white bg-blue-500 rounded-full px-4 py-2 ${
          mutation.isLoading ? "opacity-50 cursor-not-allowed" : ""
        }`}
        disabled={mutation.isLoading}
      >
        {mutation.isLoading ? "Sending..." : "Send"}
      </button>
    </footer>
  );
};

export default ChatInput;
