import React from "react";
import ReactECharts from "echarts-for-react";
import { adminURL } from "../../utils/urls";
import { useQuery } from "@tanstack/react-query";

const fetchSeshData = async () => {
  const { data } = await adminURL.get(`/charts/appointments`);
  return data;
};

const NumberOfCoachSesh = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["appointment-chart"],
    queryFn: fetchSeshData,
  });

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading data</div>;

  const labels = data?.labels || [];
  const sessionsData = data?.series?.[0]?.data || [];

  const maxValue = Math.max(...sessionsData);
  const yAxisMax = maxValue === 0 ? 1 : Math.ceil(maxValue * 1.1);

  const options = {
    tooltip: {
      trigger: "axis",
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: labels,
      axisLine: {
        lineStyle: {
          color: "#fff",
        },
      },
      axisLabel: {
        textStyle: {
          color: "#fff",
        },
      },
    },
    yAxis: {
      type: "value",
      min: 0,
      max: yAxisMax,
      interval: Math.max(1, Math.floor(yAxisMax / 5)),
      axisLine: {
        lineStyle: {
          color: "#fff",
        },
      },
      axisLabel: {
        formatter: "{value}",
        textStyle: {
          color: "#fff",
        },
      },
      splitLine: {
        show: false,
      },
      name: "Number of sessions",
      nameLocation: "middle",
      nameGap: 50,
      nameTextStyle: {
        color: "#fff",
        fontWeight: "bold",
        verticalAlign: "middle",
        align: "left",
        rotate: 90,
      },
    },
    series: [
      {
        name: "Sessions",
        type: "line",
        smooth: true,
        data: sessionsData,
        itemStyle: {
          color: "#29B6F6",
        },
        symbol: "circle",
        symbolSize: 8,
        lineStyle: {
          width: 3,
        },
      },
    ],
  };

  return (
    <div className="flex flex-col bg-gray-500 bg-opacity-10 w-full space-y-1 rounded-xl relative shadow-xl">
      <h2 className="text-white text-left text-lg border-[0.5px] border-customBorderTwo rounded-t-lg py-3 px-4">
        Number of coaching sessions over time
      </h2>
      <ReactECharts option={options} className="w-full h-[700px] px-2" />
    </div>
  );
};

export default NumberOfCoachSesh;
