import React from "react";
import { motion } from "framer-motion";

const Modal = ({ message, onClose, success }) => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.8 }}
      className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50"
    >
      <div className="bg-gray-800 text-white p-6 rounded-lg space-y-4">
        <div className="flex justify-center items-center">
          <span className={`text-4xl ${success ? "text-green-500" : "text-red-500"}`}>
            {success ? "🎉" : "⚠️"}
          </span>
        </div>
        <p className="text-center text-lg">{message}</p>
        <button
          onClick={onClose}
          className="mt-4 bg-amber-500 text-black py-2 px-6 rounded-md"
        >
          Close
        </button>
      </div>
    </motion.div>
  );
};

export default Modal;
