import React from "react";
import { Controller } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import "react-phone-input-2/lib/style.css";

const Step1 = ({
  control,
  errors,
  register,
  showPassword,
  togglePasswordVisibility,
  clearErrors,
}) => (
  <div className="space-y-2">
    {/* First Name */}
    <div className="w-full py-3 flex flex-col">
      <label
        htmlFor="firstName"
        className="mb-2 text-white text-base text-lg text-left"
      >
        First Name:
      </label>
      <Controller
        name="firstName"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <input
            {...field}
            type="text"
            id="firstName"
            placeholder="john"
            className={`bg-transparent border-2 py-2 px-3 border-amber-400 text-primary rounded-lg h-11 focus:outline-none focus:border-primary ${
              errors.firstName ? "border-red-500" : ""
            }`}
            autoComplete="firstName"
            required
            onFocus={() => clearErrors("firstName")}
          />
        )}
      />
      {errors.firstName && (
        <p className="text-red-500 text-sm mt-1">{errors.firstName.message}</p>
      )}
    </div>

    {/* Last Name */}
    <div className="w-full py-3 flex flex-col">
      <label
        htmlFor="lastName"
        className="mb-2 text-white text-base text-lg text-left"
      >
        Last Name:
      </label>
      <Controller
        name="lastName"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <input
            {...field}
            type="text"
            id="lastName"
            className={`bg-transparent border-2 py-2 px-3 border-amber-400 text-primary rounded-lg h-11 focus:outline-none focus:border-primary ${
              errors.lastName ? "border-red-500" : ""
            }`}
            placeholder="doe"
            autoComplete="lastName"
            required
            onFocus={() => clearErrors("lastName")}
          />
        )}
      />
      {errors.lastName && (
        <p className="text-red-500 text-sm mt-1">{errors.lastName.message}</p>
      )}
    </div>
    {/* Email Address */}
    <div className="w-full py-3 flex flex-col">
      <label
        htmlFor="coach-email"
        className="mb-2 text-white text-base text-lg text-left"
      >
        Enter Email:
      </label>
      <Controller
        name="emailAddress"
        control={control}
        defaultValue=""
        render={({ field }) => (
          <input
            {...field}
            type="email"
            id="coach-email"
            className={`bg-transparent border-2 py-2 px-3 border-amber-400 text-primary rounded-lg h-11 focus:outline-none focus:border-primary ${
              errors.emailAddress ? "border-red-500" : ""
            }`}
            placeholder="johndoe@gmail.com"
            autoComplete="email"
            required
            onFocus={() => clearErrors("emailAddress")}
          />
        )}
      />
      {errors.emailAddress && (
        <p className="text-red-500 text-sm mt-1">
          {errors.emailAddress.message}
        </p>
      )}
    </div>

    {/* Password */}
    <div className="w-full py-3 flex flex-col relative">
      <label
        htmlFor="password"
        className="mb-2 text-white text-base text-lg text-left"
      >
        Enter your Password:
      </label>
      <div className="flex items-center relative">
        <Controller
          name="password"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <input
              {...field}
              id="password"
              type={showPassword ? "text" : "password"}
              {...register("password", {
                required: "Password is required",
              })}
              className={`bg-transparent border-2 py-2 px-3 border-amber-400 text-primary rounded-lg h-11 w-full focus:outline-none focus:border-primary ${
                errors.password ? "border-red-500" : ""
              }`}
              placeholder="12345678Xx@"
              autoComplete="new-password"
              required
              onFocus={() => clearErrors("password")}
            />
          )}
        />
        <div className="absolute right-[12px] top-[50%] inset-y-0 flex items-center pl-3">
          <FontAwesomeIcon
            icon={showPassword ? faEyeSlash : faEye}
            className="absolute cursor-pointer right-3 -translate-y-[70%] text-gray-400 transition-all duration-300 hover:text-primary"
            onClick={togglePasswordVisibility}
          />
        </div>
      </div>
      {errors.password && (
        <p className="text-red-500 text-sm mt-1">{errors.password.message}</p>
      )}
    </div>

    {/* Repeat Password */}
    <div className="w-full py-3 flex flex-col relative">
      <label
        htmlFor="repeatPassword"
        className="mb-2 text-white text-base text-lg text-left"
      >
        Confirm Password:
      </label>
      <div className="flex items-center relative">
        <Controller
          name="repeatPassword"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <input
              {...field}
              id="repeatPassword"
              type={showPassword ? "text" : "password"}
              className={`bg-transparent border-2 py-2 px-3 border-amber-400 text-primary rounded-lg h-11 w-full focus:outline-none focus:border-primary ${
                errors.repeatPassword ? "border-red-500" : ""
              }`}
              placeholder="12345678Xx@"
              autoComplete="new-password"
              required
              onFocus={() => clearErrors("repeatPassword")}
            />
          )}
        />
        <div className="absolute right-[12px] top-[50%] inset-y-0 flex items-center pl-3">
          <FontAwesomeIcon
            icon={showPassword ? faEyeSlash : faEye}
            className="absolute cursor-pointer right-3 -translate-y-[70%] text-gray-400 transition-all duration-300 hover:text-primary"
            onClick={togglePasswordVisibility}
          />
        </div>
      </div>
      {errors.repeatPassword && (
        <p className="text-red-500 text-sm mt-1">
          {errors.repeatPassword.message}
        </p>
      )}
    </div>
  </div>
);

export default Step1;
