import axios from "axios";
import Swal from "sweetalert2";

export const baseURL = axios.create({
  baseURL: "https://platinumfuse.v2napi.com/api",
  validateStatus: (status) => {
    return status >= 200 && status < 500;
  },
  headers: {
    "Content-Type": "application/json",
  },
});
export const adminURL = axios.create({
  baseURL: "https://platinumfuse.v2napi.com/api",
  validateStatus: (status) => {
    return status >= 200 && status < 500;
  },
  headers: {
    "Content-Type": "application/json",
  },
});
export const adminURLF = axios.create({
  baseURL: "https://platinumfuse.v2napi.com/api",
  validateStatus: (status) => {
    return status >= 200 && status < 500;
  },

  headers: {
    "Content-Type": "multipart/form-data",
  },
});

adminURL.interceptors.request.use((config) => {
  const token = localStorage.getItem("refID");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

adminURL.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      Swal.fire({
        icon: "warning",
        title: "Session Expired",
        text: "Your session has expired. You will be redirected to the login page.",
        background: "#333",
        color: "#fff",
        timer: 3000,
        showConfirmButton: false,
        willClose: () => {
          window.location.href = "/login";
        },
      });
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
