import React, { useState } from "react";
import { adminURL } from "../../../../../utils/urls";

const UnBanButton = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleBanClick = () => {
    if (!id) return;

    setLoading(true);
    setSuccess(false);

    adminURL.put(`users/${id}`, { banned: false })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Failed to unban user");
        }
        setSuccess(true);
        window.location.reload(); 
      })
      .catch((error) => {
        console.error("Error banning user:", error);
        alert("Failed to ban user");
      })
      .finally(() => setLoading(false));
  };

  return (
    <div>
      {loading ? (
        <button
          disabled
          className="bg-gray-400 text-white py-2 px-4 rounded cursor-not-allowed"
        >
          <span className="animate-spin">🔄</span> UnBanning user...
        </button>
      ) : success ? (
        <button
          disabled
          className="bg-green-600 text-white py-2 px-4 rounded cursor-not-allowed"
        >
          Success!
        </button>
      ) : (
        id && (
          <button
            className="bg-amber-600 text-white py-2 px-4 rounded cursor-pointer hover:bg-amber-500"
            onClick={handleBanClick}
          >
            UnBan
          </button>
        )
      )}
    </div>
  );
};

export default UnBanButton;
