import React from "react";
import SvgOne from "../../../../assets/images/charts/svg1.svg";
import SvgTwo from "../../../../assets/images/charts/svg2.svg";
import SvgThree from "../../../../assets/images/charts/svg3.svg";

const Cards = ({ data }) => {
  const cardData = [
    { title: "Coaches", percentage: data?.coaches_count, svg: SvgOne },
    { title: "Users", percentage: data?.users_count, svg: SvgTwo },
    {
      title: " Total Revenue",
      percentage: `${data?.revenue === 0 ? 0 : `NGN${data?.revenue}`}`,
      svg: SvgThree,
    },
  ];

  return (
    <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-10 px-6 pt-26  md:px-10">
      {cardData.map((card, index) => (
        <div
          key={index}
          className="relative bg-customBlack border border-customBorderTwo shadow-md rounded-lg flex-grow xl:min-w-[240px]"
        >
          <div className="mb-4 px-6 py-4">
            <h2 className="text-lg font-bold mb-2 text-white md:text-2xl">
              {card.title}
            </h2>
            <p className="text-2xl font-semibold text-white">
              {card.percentage}
            </p>
          </div>
          <div className="flex justify-center">
            <img
              src={card.svg}
              alt={`${card.title} chart`}
              className="w-full h-auto"
            />
          </div>
          <div className="absolute top-0 left-0 w-full h-full bg-gray-500 bg-opacity-10 rounded-lg"></div>
        </div>
      ))}
    </section>
  );
};

export default Cards;
