import React, { useEffect, useState } from "react";
import { FaCaretRight } from "react-icons/fa";
import Mail from "./mail/Mail";
import Chat from "./chat/Chat";

const CSupport = () => {
  const [activeTab, setActiveTab] = useState("live");

  useEffect(() => {
    window.scroll(0, 0);
  }, []);


  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <section className="px-4 py-8 w-full">
      <div className="flex justify-center mx-auto max-w-6xl">
        <header
          style={{ backgroundColor: "rgba(12, 17, 23, 1)" }}
          className="flex border border-primary justify-center items-center py-4 px-6 md:px-8 rounded-md space-x-4 md:space-x-8 w-full"
        >
          <h1 className="text-lg md:text-xl text-white">Support</h1>
        </header>
      </div>
      <section className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-8">
        <div className="col-span-1 md:col-span-1 rounded-md">
          <div className="space-y-4">
            <div
              onClick={() => handleTabChange("live")}
              className={`${
                activeTab === "live"
                  ? "bg-primary text-gray-900"
                  : "text-gray-300 bg-black"
              } flex items-center justify-between w-full px-4 py-6 font-semibold text-sm text-lg cursor-pointer md:text-xl`}
            >
              <p className="flex-1 text-left">via Live chat</p>
              <FaCaretRight className="text-xl" />
            </div>
            <div
              onClick={() => handleTabChange("email")}
              className={`${
                activeTab === "email"
                  ? "bg-primary text-gray-900"
                  : "text-gray-300 bg-black"
                } flex items-center justify-between w-full px-4 py-6 font-semibold text-sm text-lg cursor-pointer md:text-xl`}
            >
              <p className="flex-1 text-left">via Email</p>
              <FaCaretRight className="text-lg" />
            </div>
          </div>
        </div>
        <div className="col-span-1 md:col-span-3 p-4 rounded-md">
          {activeTab === "live" && (
            <div>
              <Chat />
            </div>
          )}
          {activeTab === "email" && (
            <div>
           <Mail/>
            </div>
          )}
        </div>
      </section>
    </section>
  );
};

export default CSupport;
