import React, { useState, useEffect } from "react";

const SupportList = ({ data }) => {
  const [activeId, setActiveId] = useState(null);
  const [users, setUsers] = useState(data?.data || []);
  const [page, setPage] = useState(data?.current_page || 1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (data?.data) {
      setUsers(data.data);
    }
  }, [data]);

  const loadMoreData = async () => {
    if (!hasMore) return;

    setIsLoading(true);
    try {
      const response = await fetch(`${data.path}?page=${page + 1}`);
      const newData = await response.json();

      if (newData.data.length > 0) {
        setUsers((prevUsers) => [...prevUsers, ...newData.data]);
        setPage(newData.current_page);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error loading more data", error);
    }
    setIsLoading(false);
  };

  const handleScroll = (e) => {
    const { scrollHeight, scrollTop, clientHeight } = e.target;

    if (scrollHeight - scrollTop === clientHeight && !isLoading) {
      loadMoreData();
    }
  };

  useEffect(() => {
    const savedId = localStorage.getItem("activeSupportId");
    if (savedId) {
      setActiveId(parseInt(savedId, 10));
    }
  }, []);

  const handleClick = (id) => {
    setActiveId(id);
    localStorage.setItem("activeSupportId", id);
    window.location.reload();
  };

  return (
    <div
      className="support-list flex flex-wrap md:flex-nowrap overflow-x-auto py-10"
      onScroll={handleScroll}
      style={{ height: "auto", width: "100%" }}
    >
      {users?.map((user) => (
        <div
          key={user?.id}
          onClick={() => handleClick(user.id)}
          className={`p-4 cursor-pointer transition-all relative w-24 md:w-32 flex-shrink-0 ${
            activeId === user?.id
              ? "transform scale-110 transition-all duration-200"
              : "transform scale-100 border-4 border-transparent"
          }`}
        >
          <div className="relative">
            <img
              src={user?.photo_url || "https://via.placeholder.com/150"}
              alt={user?.first_name}
              className="rounded-full w-16 h-16 md:w-24 md:h-24 object-cover"
            />
            <span className="absolute top-0 right-0 w-4 h-4 md:w-5 md:h-5 bg-green-500 rounded-full border-2 border-white"></span>
          </div>
          <div className="mt-2 text-center text-sm md:text-md font-medium text-white">
            {user?.name}
          </div>
        </div>
      ))}

      {isLoading && <div>Loading more...</div>}
    </div>
  );
};

export default SupportList;
