import React, { useEffect, useState } from "react";
import Prev from "./Prev";
import SendIv from "./SendIv";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../../loader/FetchLoader";
import { adminURL } from "../../../../../utils/urls";

const PreviewEvent = () => {
  const { eventID } = useParams();
  const [eventData, setEventData] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await adminURL.get(`events/${eventID}`);
        setEventData(response.data.data.event);
        if (response.status === 200) {
          console.log(response?.data?.data?.event);
          return;
        } else {
          navigate("/login");
        }
      } catch (error) {
        console.error("Error fetching event details:");
      } finally {
        setLoading(false);
      }
    };

    fetchEventData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventID]);

  return (
    <section
      style={{ backgroundColor: "rgba(12, 17, 23, 1)" }}
      className="flex flex-col items-center justify-center w-full max-w-[1020px] mx-auto"
    >
      <div className="w-full flex justify-center text-center border-b border-customBackgroundTwo">
        <header className="flex justify-center items-center rounded-md space-x-8 w-full py-6">
          <h1 className="text-xl text-white">Invitation Mail</h1>
        </header>
      </div>

      {loading ? (
        <Loader text="loading event..." />
      ) : (
        <>
          <h2 className="text-white text-2xl my-4">{eventData?.name}</h2>
          <p className="text-white">{eventData?.description}</p>
          <Prev data={eventData} />
          <SendIv eventData={eventData} />
        </>
      )}
    </section>
  );
};

export default PreviewEvent;
