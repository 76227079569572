import React from "react";

const Bottom = ({ setPrice, setDescription }) => {
  return (
    <section className="px-6 space-y-6">
      <div className="flex flex-col space-y-2">
        <label className="text-lg font-bold text-white">Description</label>
        <input
          type="text"
          onChange={(e) => setDescription(e.target.value)}
          required
          className="py-3 px-4 font-bold text-black bg-gray-200 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
        />
      </div>

      <div className="flex flex-col space-y-2">
        <label className="text-lg font-bold text-white">Enter Price</label>
        <input
          type="number"
          placeholder="Enter your price"
          required
          onChange={(e) => setPrice(e.target.value)}
          className="bg-gray-200 text-black py-3 px-4 rounded-lg border-2 border-transparent focus:border-blue-500 focus:outline-none transition duration-300 ease-in-out shadow-md hover:shadow-lg w-full"
        />
      </div>
    </section>
  );
};

export default Bottom;
