const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};

const validatePasswordStrength = (password) => {
  // At least one uppercase letter, one lowercase letter, one digit, one special character, and at least 8 characters long
  const re =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return re.test(password);
};

export const validateRepeatPassword = (password, repeatPassword) => {
  return password === repeatPassword;
};

export const validateDOB = (dob) => {
  const age = new Date().getFullYear() - new Date(dob).getFullYear();
  return age >= 18;
};

const validateLinkedIn = (linkedin) => {
  const regex =
    /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|pub|profile)\/[A-Za-z0-9_-]+\/?$/;
  return regex.test(linkedin);
};

const validateValue = (value) => {
  if (value === "") {
    return false;
  }

  return true;
};

export const validateStep1 = async (getValues, setError, clearErrors) => {
  const { firstName, lastName, emailAddress, password, repeatPassword } =
    getValues();
  let isValid = true;

  if (!validateValue(firstName)) {
    setError("firstName", { message: "First Name is required" });
    isValid = false;
  } else {
    clearErrors("firstName");
  }

  if (!validateValue(lastName)) {
    setError("lastName", { message: "Last Name is required" });
    isValid = false;
  } else {
    clearErrors("lastName");
  }

  if (!validateEmail(emailAddress)) {
    setError("emailAddress", { message: "Invalid email address" });
    isValid = false;
  } else {
    clearErrors("emailAddress");
  }

  if (!validatePasswordStrength(password)) {
    setError("password", {
      message:
        "Password must be at least 8 characters long, contain one uppercase letter, one lowercase letter, one digit, and one special character",
    });
    isValid = false;
  } else {
    clearErrors("password");
  }

  if (!validateRepeatPassword(password, repeatPassword)) {
    setError("repeatPassword", { message: "Passwords do not match" });
    isValid = false;
  } else {
    clearErrors("repeatPassword");
  }

  return isValid;
};

export const validateStep2 = (getValues, setError, clearErrors) => {
  const { practiceLocation, education, institution, linkedIn, specialty } =
    getValues();

  let isValid = true;

  if (!practiceLocation) {
    setError("practiceLocation", {
      type: "manual",
      message: "Practice location is required",
    });
    isValid = false;
  } else {
    clearErrors("practiceLocation");
  }

  if (!education) {
    setError("education", { type: "manual", message: "Education is required" });
    isValid = false;
  } else {
    clearErrors("education");
  }

  if (!institution) {
    setError("institution", {
      type: "manual",
      message: "Institution is required",
    });
    isValid = false;
  } else {
    clearErrors("institution");
  }

  if (!validateLinkedIn(linkedIn)) {
    setError("linkedIn", { type: "manual", message: "Invalid LinkedIn URL" });
    isValid = false;
  } else {
    clearErrors("linkedIn");
  }

  if (!specialty) {
    setError("specialty", { type: "manual", message: "Specialty is required" });
    isValid = false;
  } else {
    clearErrors("specialty");
  }

  return isValid;
};

export const nigeriaCities = [
  "Aba",
  "Abakaliki",
  "Abeokuta",
  "Abuja",
  "Ado Ekiti",
  "Afikpo",
  "Agbor",
  "Akwanga",
  "Akure",
  "Asaba",
  "Auchi",
  "Awka",
  "Azare",
  "Bauchi",
  "Benin City",
  "Bida",
  "Birnin Kebbi",
  "Calabar",
  "Damaturu",
  "Dutse",
  "Ede",
  "Effurun",
  "Eket",
  "Enugu",
  "Epe",
  "Gombe",
  "Gusau",
  "Hadejia",
  "Ibadan",
  "Ijebu Ode",
  "Ikare",
  "Ikirun",
  "Ikorodu",
  "Ile Ife",
  "Ilorin",
  "Jalingo",
  "Jebba",
  "Jimeta",
  "Jos",
  "Kaduna",
  "Kafanchan",
  "Kano",
  "Katsina",
  "Kontagora",
  "Lafia",
  "Lagos",
  "Lokoja",
  "Maiduguri",
  "Makurdi",
  "Minna",
  "Nnewi",
  "Nsukka",
  "Ogaminana",
  "Ogwashi Ukwu",
  "Oji River",
  "Okene",
  "Onitsha",
  "Orlu",
  "Oshogbo",
  "Owerri",
  "Owo",
  "Oyo",
  "Port Harcourt",
  "Sapele",
  "Sokoto",
  "Suleja",
  "Umuahia",
  "Uyo",
  "Warri",
  "Wukari",
  "Yenagoa",
  "Yola",
  "Zaria",
];
