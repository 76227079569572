import React from "react";
import Icon from "../../../../assets/coachassets/images-videos/logo2.png";
import FullLogo from "../../../../assets/coachassets/images-videos/Platinum-Fuse-Full-Logo.png";
import Navigator from "./Navigator";
import Details from "./Details";

const BasicDetails = () => {
  return (
    <section className="min-h-screen ">
      <header className="flex flex-col items-center p-8  tablet:w-screen tablet:pt-20 w-full">
        <div className="flex items-center space-x-4 w-full">
          <img
            src={Icon}
            alt="Platinum Fuse Icon"
            className="w-20 hidden md:block"
          />
          <img
            src={FullLogo}
            alt="Platinum Fuse Full Logo"
            className="w-3/6 block md:hidden"
          />
          {/* <p className="text-white text-lg">Platinum Fuse</p> */}
        </div>
      </header>
      <h1 className="text-center text-white text-xl py-2 md:3xl">
        Welcome to Platinum Fuse for coaches
      </h1>
      <section>
        <main className="max-w-sm mx-auto px-14">
          <Navigator />
        </main>
        <main>
          <Details/>
        </main>
      </section>
    </section>
  );
};

export default BasicDetails;
