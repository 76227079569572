import React, { useState, useEffect } from "react";
import { adminURL } from "../../../../utils/urls";
import Table from "./Table";

const PendingTable = ({ setFetchApp }) => {
  const [appointments, setAppointments] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    perPage: 5,
    lastPage: 1,
    total: 0,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAppointments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const start = new Date().toISOString().split("T")[0];

  const fetchAppointments = async (page = 1) => {
    try {
      const response = await adminURL.get(
        `appointments?booked=true&page=${page}&starts_at=${start}`
      );
      setFetchApp(response?.data?.data);
      setAppointments(response?.data?.data || []);
      setPagination({
        currentPage: response?.data.current_page,
        lastPage: response?.data?.last_page,
        total: response?.data?.total,
        perPage: response?.data?.per_page,
      });
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
    setLoading(false);
  };

  const initialSortBy = [{ id: "serial_no", desc: true }];

  const columns = [
    {
      Header: "Profile Name",
      accessor: "ProfileName",
      Cell: ({ row }) => {
        const profileName = `${row?.original.booker.first_name} ${row?.original.booker.last_name}`;

        return (
          <div className="flex items-center space-x-4 capitalize">
            <section className="flex items-center justify-start space-x-4">
              <div className="avatar h-12 w-12">
                <img
                  src={row?.original.booker.photo_url}
                  alt="Offender"
                  className="rounded-full object-cover object-center h-full w-full"
                />
              </div>
              <span className="font-medium text-base text-slate-300 line-clamp-2">
                {profileName}
              </span>
            </section>
          </div>
        );
      },
    },
    {
      Header: "Gender",
      accessor: "Gender",
      Cell: ({ row }) => (
        <span className="capitalize text-amber-600">
          {row?.original.booker.gender}
        </span>
      ),
    },
    {
      Header: "Age",
      accessor: "Age",
      Cell: ({ row }) => (
        <span className="capitalize text-gray-500">
          {row?.original.booker.age}
        </span>
      ),
    },
  ];

  return (
    <div className="flex-1 flex flex-col w-full">
      <div
        style={{
          backgroundColor: "rgba(12, 17, 23, 1)",
        }}
        className="flex justify-between items-center py-4 px-10"
      >
        <h1 className="text-2xl font-bold text-center w-full capitalize text-white">
          Appointments
        </h1>
      </div>
      <div className="flex-1 overflow-x-auto w-full min-w-[300px] scrollbar scrollbar-thumb-amber-700">
        {loading ? (
          <div className="text-center py-5 text-white">
            Loading appointments...
          </div>
        ) : appointments.length === 0 ? (
          <div className="text-center py-5 text-white">
            No appointments booked yet
          </div>
        ) : (
          <Table
            columns={columns}
            data={appointments}
            initialSortBy={initialSortBy}
            headStyles={""}
            fullWidth={true}
            pagination={pagination}
            onPageChange={fetchAppointments}
          />
        )}
      </div>
    </div>
  );
};

export default PendingTable;
