import React, { useEffect, useRef } from "react";
import { BiCheckDouble } from "react-icons/bi";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { adminURL } from "../../../../utils/urls";
import ChatInput from "./ChatInput";

const fetchMessages = async (id) => {
  const response = await adminURL.get(`/conversations/${id}/messages`);
  return response.data;
};

const groupMessagesByDate = (messages) => {
  return messages.reduce((groups, message) => {
    const messageDate = dayjs(message.created_at).format("YYYY-MM-DD");
    if (!groups[messageDate]) {
      groups[messageDate] = [];
    }
    groups[messageDate].push(message);
    return groups;
  }, {});
};

const formatDateLabel = (date) => {
  const today = dayjs().format("YYYY-MM-DD");
  const yesterday = dayjs().subtract(1, "day").format("YYYY-MM-DD");

  if (date === today) return "Today";
  if (date === yesterday) return "Yesterday";
  return dayjs(date).format("MMMM D, YYYY");
};

const ChatScreen = ({ adjustTime, conID }) => {
  const { data } = useQuery({
    queryKey: ["support-messaging"],
    queryFn: () => fetchMessages(conID),
    refetchInterval: 9000,
  });

  const groupedMessages = data?.messages
    ? groupMessagesByDate(data.messages)
    : {};

  const lastMessageRef = useRef(null);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [data?.messages]);

  return (
    <div className="flex flex-col h-screen bg-gray-900 text-white">
      <header className="bg-black flex justify-between items-center py-5 px-3 rounded-tl-3xl rounded-tr-3xl">
        <div className="flex items-center space-x-4 relative">
          <div className="relative">
            <img
              src={data?.user?.photo_url}
              alt="profile-pic"
              className="w-16 h-16 rounded-full"
            />
            <div className="absolute top-0 right-0 w-3 h-3 bg-green-500 border-2 border-white rounded-full"></div>
          </div>
          <div className="ml-2">
            <p className="font-bold text-lg capitalize text-white">
              {data?.user?.name}
            </p>
          </div>
        </div>
      </header>

      <main className="flex-1 bg-gray-800 bg-opacity-10 p-4 overflow-y-auto">
        {Object.keys(groupedMessages).map((date) => (
          <div key={date}>
            {/* Date label */}
            <div className="flex justify-center items-center mb-4">
              <span className="text-white bg-black rounded-full text-center px-4 py-0.5">
                {formatDateLabel(date)}
              </span>
            </div>

            {/* Messages for this date */}
            <div className="flex flex-col space-y-4">
              {groupedMessages[date].map((message, index, arr) => (
                <div
                  key={message.id}
                  className={`flex items-start space-x-2 max-w-sm ${
                    message.me ? "self-end" : "self-start"
                  }`}
                  ref={index === arr.length - 1 ? lastMessageRef : null}
                >
                  {/* Message bubble */}
                  <div
                    className={`${
                      message.me ? "bg-amber-500" : "bg-[#151515]"
                    } p-3 rounded-lg text-white`}
                  >
                    <p className="text-sm">{message.message}</p>
                    <div
                      className={`flex justify-end items-center mt-2 text-xs ${
                        message.me ? "text-black" : "text-gray-400"
                      }`}
                    >
                      <span>{adjustTime(message.time)}</span>
                      {message.me && (
                        <div className="ml-2 flex items-center">
                          <BiCheckDouble
                            className={`${
                              message.read ? "text-green-500" : "text-black"
                            }`}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  {/* User profile image */}
                  {!message.me && (
                    <img
                      src={data?.user?.photo_url}
                      alt="profile-pic"
                      className="w-6 h-6 rounded-full mt-2"
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
        {/* Typing indicator */}
        {data?.typing && (
          <div className="flex justify-start px-4 py-2 text-sm italic text-amber-400">
            {`${data.user.name} is typing...`}
          </div>
        )}
      </main>

      <ChatInput />
    </div>
  );
};

export default ChatScreen;
