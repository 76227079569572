import React from "react";
import { Range } from "react-range";

const Details = ({ user }) => {
  const formattedDob = new Date(user?.dob)
    .toISOString()
    .split("T")[0]
    .replace(/-/g, "/");

    const { min_age, max_age } = user?.preferences;
  
    
    // eslint-disable-next-line no-unused-vars
    const [values, setValues] = React.useState([
      Number(min_age), 
      Number(max_age)
    ]);
  

  

  return (
    <div className="p-4 space-y-4">
      {/* Gender, Interested In, Interaction */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="space-y-1">
          <label className="text-white capitalize">Gender</label>
          <input
            type="text"
            value={user?.gender === "F" ? "Female" : "Male"}
            readOnly
            className="bg-gray-700 outline-none text-white shadow-sm w-full rounded-lg p-2"
          />
        </div>
        <div className="space-y-1">
          <label className="text-white capitalize">Interested In</label>
          <input
            type="text"
            value={user?.interest}
            readOnly
            className="bg-gray-700 outline-none text-white shadow-sm w-full rounded-lg p-2"
          />
        </div>
        {/* <div className="space-y-1">
          <label className="text-white capitalize">Interaction</label>
          <input
            type="text"
            value={user.interaction}
            readOnly
            className="bg-gray-700 outline-none text-white shadow-sm w-full rounded-lg p-2"
          />
        </div> */}
      </div>

      {/* First Name */}
      <div className="space-y-1">
        <label className="text-white capitalize">First Name</label>
        <input
          type="text"
          value={user?.first_name}
          readOnly
          className="bg-gray-700 outline-none text-white shadow-sm w-full rounded-lg p-2"
        />
      </div>

      {/* Last Name */}
      <div className="space-y-1">
        <label className="text-white capitalize">Last Name</label>
        <input
          type="text"
          value={user?.last_name}
          readOnly
          className="bg-gray-700 outline-none text-white shadow-sm w-full rounded-lg p-2"
        />
      </div>

      {/* Birthday */}
      <div className="space-y-1">
        <label className="text-white capitalize">Birthday</label>
        <input
          type="text"
          value={formattedDob}
          readOnly
          className="bg-gray-700 outline-none text-white shadow-sm w-full rounded-lg p-2"
        />
      </div>

      {/* Level of Education */}
      <div className="space-y-1">
        <label className="text-white capitalize">Level of Education</label>
        <input
          type="text"
          value={user?.education}
          readOnly
          className="bg-gray-700 outline-none text-white shadow-sm w-full rounded-lg p-2"
        />
      </div>

      {/* Name of Institution */}
      <div className="space-y-1">
        <label className="text-white capitalize">Name of Institution</label>
        <input
          type="text"
          value={user?.institute}
          readOnly
          className="bg-gray-700 outline-none text-white shadow-sm w-full rounded-lg p-2"
        />
      </div>

      {/* Occupation */}
      <div className="space-y-1">
        <label className="text-white capitalize">Occupation</label>
        <input
          type="text"
          value={user?.occupation}
          readOnly
          className="bg-gray-700 outline-none text-white shadow-sm w-full rounded-lg p-2"
        />
      </div>

      {/* Email */}
      <div className="space-y-1">
        <label className="text-white capitalize">Email</label>
        <input
          type="text"
          value={user?.email}
          readOnly
          className="bg-gray-700 outline-none text-white shadow-sm w-full rounded-lg p-2"
        />
      </div>

      {/* LinkedIn */}
      <div className="space-y-1">
        <label className="text-white capitalize">LinkedIn</label>
        <input
          type="text"
          value={user?.linkedin}
          readOnly
          className="bg-gray-700 outline-none text-white shadow-sm w-full rounded-lg p-2"
        />
      </div>

      {/* City */}
      <div className="space-y-1">
        <label className="text-white capitalize">City</label>
        <input
          type="text"
          value={user?.city}
          readOnly
          className="bg-gray-700 outline-none text-white shadow-sm w-full rounded-lg p-2"
        />
      </div>

      {/* State */}
      {/* <div className="space-y-1">
        <label className="text-white capitalize">State</label>
        <input
          type="text"
          value={user.state}
          readOnly
          className="bg-gray-700 outline-none text-white shadow-sm w-full rounded-lg p-2"
        />
      </div> */}

      {/* Age Preference */}
      <div className="space-y-1">
          <label className="text-white capitalize">Age Preference</label>
          <div className="flex justify-between items-center">
            <span className="text-white">{`${min_age} - ${max_age}`}</span>
          </div>
          <div className="relative">
            <Range
              values={values}
              step={1}
              min={0}
              max={100}
              renderTrack={({ props, children }) => (
                <div
                  {...props}
                  className="w-full h-2 bg-white rounded-lg"
                  style={{
                    background: "linear-gradient(to right, #D97706, #D97706)",
                  }}
                >
                  {children}
                </div>
              )}
              renderThumb={({ props }) => (
                <div
                  {...props}
                  className="w-4 h-4 bg-amber-500 rounded-full shadow"
                />
              )}
              disabled={true} 
            />
          </div>
          </div>
      {/* Interests */}
      {/* Interests */}
      <div className="space-y-1">
        <label className="text-white capitalize">Interests</label>
        <div className="flex flex-wrap gap-2">
          {user?.preferences?.interests?.map((interest, index) => (
            <span
              key={index}
              className="bg-amber-500 text-black rounded-lg px-2 py-1"
            >
              {interest || ""}
            </span>
          ))}
        </div>
      </div>

      {/* Bio */}
      <div className="space-y-1">
        <label className="text-white capitalize">Bio</label>
        <textarea
          value={user?.bio}
          readOnly
          className="bg-gray-700 outline-none text-white shadow-sm w-full rounded-lg p-2"
        />
      </div>

      {/* Dislikes */}
      {/* <div className="space-y-1">
        <label className="text-white capitalize">Dislikes</label>
        <textarea
          value={user.dislikes}
          readOnly
          className="bg-gray-700 outline-none text-white shadow-sm w-full rounded-lg p-2"
        />
      </div> */}

      {/* NIN */}
      <div className="space-y-1">
        <label className="text-white capitalize">NIN</label>
        <input
          type="text"
          value={user?.nin}
          readOnly
          className="bg-gray-700 outline-none text-white shadow-sm w-full rounded-lg p-2"
        />
      </div>
    </div>
  );
};

export default Details;
