import React, { useState } from "react";
import Modal from "./Modal";

const ViewReasonButton = ({ reason }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <>
      <button
        onClick={openModal}
        className="text-amber-600 hover:underline"
      >
        View Reason
      </button>
      {isOpen && <Modal reason={reason} closeModal={closeModal} />}
    </>
  );
};

export default ViewReasonButton;
