import React from "react";
import ChatScreen from "./ChatScreen";
import SupportList from "./supportList/SupportList";
import { adminURL } from "../../../../utils/urls";
import { useQuery } from "@tanstack/react-query";
import "./chat.css";

const fetchList = async () => {
  const response = await adminURL.get(`users?role=admin`);
  return response?.data;
};

const adjustTime = (timeString) => {
  const [timePart, period] = timeString.match(/(\d+:\d+)([ap]m)/i).slice(1, 3);
  const [hours, minutes] = timePart.split(":").map(Number);

  let adjustedHours = hours;

  if (period.toLowerCase() === "pm" && hours !== 12) {
    adjustedHours = hours + 12;
  } else if (period.toLowerCase() === "am" && hours === 12) {
    adjustedHours = 0;
  }

  adjustedHours = (adjustedHours + 1) % 24;

  const finalPeriod = adjustedHours >= 12 ? "pm" : "am";
  const displayHours = adjustedHours % 12 || 12;

  return `${displayHours}:${minutes.toString().padStart(2, "0")}${finalPeriod}`;
};

const Chat = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["support-list"],
    queryFn: fetchList,
  });
  const conID = localStorage.getItem("activeSupportId");
  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading data</div>;

  return (
    <section className="p-4">
      {data && <SupportList data={data} />}
      {conID ? (
        <ChatScreen adjustTime={adjustTime} conID={conID} />
      ) : (
        <div className="mt-4 text-center text-gray-500">
          Click on a profile to start a conversation.
        </div>
      )}
    </section>
  );
};

export default Chat;
