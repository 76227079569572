import React from "react";
import { Range } from "react-range";

const AgePref = ({ profile }) => {
  const [values, setValues] = React.useState([
    parseInt(profile?.preferences.min_age) || 21,
    parseInt(profile?.preferences.max_age) || 35,
  ]);

  return (
    <div className="flex items-center space-x-4">
      <label className="text-white capitalize">Age Preference:</label>
      <div className="flex-grow">
        <div className="flex justify-end items-center ">
          <span className="text-white relative bottom-4">
            {values[0]}-{values[1]}
          </span>
        </div>
        <div className="flex justify-between items-center relative bottom-3">
          <Range
            values={values}
            step={1}
            min={0}
            max={100}
            onChange={(values) => setValues(values)}
            renderTrack={({ props, children }) => (
              <div
                {...props}
                className="w-full h-2 bg-white rounded-lg"
                style={{
                  background: "linear-gradient(to right, #D97706, #D97706)",
                }}
              >
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                className="w-4 h-4 bg-amber-500 rounded-full shadow"
              />
            )}
            disabled={true} 
          />
        </div>
      </div>
    </div>
  );
};

export default AgePref;
