import React, { useState } from "react";
import ModalThumbsUp from "../cmodal/ModalThumbsUp";
import SessionTabs from "./sessionTabs/SessionTabs";
import Weekdays from "./sessionTabs/weekdays/Weekdays";
import Summary from "./Summary";
// import Weekends from "./sessionTabs/weekends/Weekends";

const CreateSession = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showEditBtn, setShowEditBtn] = useState(false);
  const [activeTab, setActiveTab] = useState("weekdays");
  const [isSummaryVisible, setIsSummaryVisible] = useState(true);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleCheckSession = () => {
    setShowEditBtn(!showEditBtn);
    window.scrollTo({
      top: document.documentElement.scrollHeight,
    });
  };

  return (
    <main className="px-4 relative">
      {isSummaryVisible && (
        <Summary onClose={() => setIsSummaryVisible(false)} />
      )}
      <div className="flex justify-center md:justify-end">
        <SessionTabs handleTabChange={handleTabChange} activeTab={activeTab} />
      </div>

      {/* {activeTab === "weekdays" ? (
        <Weekdays
          handleCheckSession={handleCheckSession}
          showEditBtn={showEditBtn}
        />
      ) : (
        <Weekends />
      )} */}
      <Weekdays
        handleCheckSession={handleCheckSession}
        showEditBtn={showEditBtn}
      />
      <ModalThumbsUp
        textBottom={"Your Sessions has been created successfully"}
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setShowEditBtn(false);
        }}
      />
    </main>
  );
};

export default CreateSession;
