import React from "react";

const Pagination = ({ currentPage, totalPages, itemsPerPage, onPageChange }) => {
  const totalPagesCount = Math.ceil(totalPages / itemsPerPage);

  return (
    <div className="flex items-center space-x-2 text-white">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="px-2 py-1  rounded-md"
      >
        &lt;
      </button>
      <span>{`${(currentPage - 1) * itemsPerPage + 1}-${Math.min(currentPage * itemsPerPage, totalPages)} of ${totalPages}`}</span>
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPagesCount}
        className="px-2 py-1  rounded-md"
      >
        &gt;
      </button>
    </div>
  );
};

export default Pagination;
