import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SlotProfile from "../components/singleSlots/SlotProfile";
import { FaArrowLeft } from "react-icons/fa";
import { adminURL } from "../../utils/urls";
import Loader from "../../components/loader/FetchLoader";
import Others from "../components/singleSlots/Others/Others";

const SingleSlot = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [profile, setProfile] = useState();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [appointments, setAppointments] = useState([]);
  const start = new Date().toISOString().split("T")[0];

  useEffect(() => {
    fetchAppointments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const response = await adminURL.get(`slots/${id}`);
    setData(response?.data?.data);

    const { booker } = response?.data?.data.slot;
    setProfile(booker);
    setLoading(false);
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAppointments = async (page = 1) => {
    try {
      const response = await adminURL.get(
        `appointments?booked=true&page=${page}&starts_at=${start}`
      );
      setAppointments(response?.data?.data || []);
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
  };

  return (
    <div className="px-2 md:px-10 relative">
      <button
        onClick={() => navigate(-1)}
        className="absolute left-4 top-4 text-white hover:text-amber-500 transition-colors duration-300 ease-in-out"
        aria-label="Go back"
      >
        <FaArrowLeft className="text-2xl md:text-3xl animate-pulse" />
      </button>
      <header className="text-center w-full max-w-full min-w-0 text-xl font-bold text-white border-b border-amber-500 py-4 px-10 md:text-4xl lg:pl-[your-sidebar-width] transition-all">
        Appointment Details
      </header>
      <section className="py-4">
        {loading ? (
          <Loader
            text="Appointment Details
          "
          />
        ) : (
          <SlotProfile data={data} profile={profile} />
        )}

        {appointments.length > 1 && (
          <>
            <hr className="w-full bg-amber-500 my-3" />
            <Others currentID={id} appointments={appointments} />{" "}
          </>
        )}
      </section>
    </div>
  );
};

export default SingleSlot;
