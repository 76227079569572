import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const CLogout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
    navigate("/coaches/login");
  }, [navigate]);

  return <div>Logging out...</div>;
};

export default CLogout;
