import React from "react";


const Details = ({ data }) => {
  const formatDOB = (dobString) => {
    const date = new Date(dobString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const getOrdinalSuffix = (d) => {
      if (d > 3 && d < 21) return "th";
      switch (d % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    const ordinalDay = `${day}${getOrdinalSuffix(day)}`;

    return `${ordinalDay} ${month} ${year}`;
  };

  const formattedDOB = formatDOB(data?.slot?.booker?.dob);

  return (
    <section className="pt-5 px-4 text-center md:text-start">
      <div className="grid grid-cols-1 gap-y-4 md:grid-cols-2 md:gap-x-4">
        <div className="flex flex-col">
          <p className="text-white">Gender:</p>
          <p className="text-slate-300">
            {data?.slot?.booker?.gender === "M" ? "Male" : "Female"}
          </p>
        </div>
        <div className="flex flex-col">
          <p className="text-white">Email:</p>
          <p className="text-slate-300 break-words">{data?.slot?.booker?.email}</p>
        </div>
        <div className="flex flex-col">
          <p className="text-white">DOB:</p>
          <p className="text-slate-300">{formattedDOB}</p>
        </div>
        <div className="flex flex-col">
          <p className="text-white">Occupation:</p>
          <p className="text-slate-300 text-sm">{data?.slot?.booker?.occupation}</p>
        </div>
        <div className="flex flex-col">
          <p className="text-white">City:</p>
          <p className="text-slate-300 text-sm">
            {data?.slot?.booker?.city ? data?.slot?.booker?.city : "N/A"}
          </p>
        </div>
      </div>

    </section>
  );
};

export default Details;
