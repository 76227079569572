import React from "react";

const Prev = ({ data }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "numeric", day: "numeric", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    return date.toLocaleTimeString("en-US", options);
  };

  return (
    <div className="w-full px-4 sm:px-10 pt-8">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8">
        {/* Left Column: Date, Time, Title, and Venue */}
        <div className="md:col-span-2 space-y-4">
          <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0">
            <div className="flex flex-col space-y-2">
              <label className="text-white">Date</label>
              <p className="text-gray-500 py-3 px-2 font-bold">
                {formatDate(data?.starts_at)}
              </p>
            </div>
            <div className="flex flex-col space-y-2">
              <label className="text-white">Time</label>
              <p className="text-gray-500 py-3 px-2 font-bold">
                {formatTime(data?.starts_at)}
              </p>
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <label className="text-white">Title</label>
            <p className="text-gray-500 py-3 px-2 font-bold">{data?.name}</p>
          </div>

          <div className="flex flex-col space-y-2">
            <label className="text-white">Venue</label>
            <p className="text-gray-500 py-3 px-2 font-bold max-w-md">
              {data?.venue}
            </p>
          </div>

          <div className="flex flex-col space-y-2">
            <label className="text-white">Description</label>
            <p className="text-gray-500 py-3 px-2 font-bold">
              {data?.description}
            </p>
          </div>
        </div>

        {/* File Upload Preview */}
        <div className="flex flex-col space-y-2 h-full">
          <div className="p-2 rounded-xl text-center text-white flex-grow h-full flex items-center justify-center">
            <img
              src={data?.image_url}
              alt="Uploaded File"
              className="h-full w-full object-cover rounded-xl"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prev;
