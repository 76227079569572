import React, { useState } from "react";
import Btns from "./Btns";
import FilesUpload from "./filesUpload/FilesUpload";
import axios from "axios";
import Modal from "react-modal";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const Upload = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [coverImage, setCoverImage] = useState(null);
  const [file, setFile] = useState(null);
  const [price, setPrice] = useState("");
  const [errors, setErrors] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    let validationErrors = {};

    if (!title) validationErrors.title = "Title is required";
    if (!description) validationErrors.description = "Title is required";
    if (!price) validationErrors.price = "Price is required";
    if (!coverImage) validationErrors.coverImage = "Cover image is required";
    if (!file) validationErrors.file = "File submission is required";

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setModalIsOpen(true);
      return;
    }

    setLoading(true);

    const token = localStorage.getItem("refID");

    const formData = new FormData();
    formData.append("name", title);
    formData.append("description", description);
    formData.append("price", parseFloat(price).toFixed(2));
    formData.append("published", 1);
    formData.append("cover", coverImage);
    formData.append("file", file);

    try {
      const response = await axios.post(
        "https://platinumfuse.v2napi.com/api/publications",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setLoading(false);

      if (response.status === 200) {
        navigate("/coaches/dashboard/publication/publications");
      } else {
        setErrors({
          general:
            "Oops, an error occurred. Not your fault, we are working on it.",
        });
        setModalIsOpen(true);
      }
    } catch (error) {
      setLoading(false);
      setErrors({
        general:
          "Oops, an error occurred. Not your fault, we are working on it.",
      });
      setModalIsOpen(true);
      console.error("Error uploading publication:", error);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <section>
      <div>
        <div className="flex justify-center">
          <header
            style={{ backgroundColor: "rgba(12, 17, 23, 1)" }}
            className="flex justify-center items-center py-6 px-14 md:px-10 rounded-md space-x-4 md:space-x-8 md:max-w-[1020px] w-full"
          >
            <h1 className="text-lg md:text-xl text-white">
              Upload publication
            </h1>
          </header>
        </div>
        <form onSubmit={handleSubmit}>
          <section className="max-w-5xl">
            <Btns loading={loading}/>
          </section>
          <section>
            <FilesUpload
              setTitle={setTitle}
              setDescription={setDescription}
              setCoverImage={setCoverImage}
              setFile={setFile}
              setPrice={setPrice}
            />
          </section>
        </form>

        {/* Uploading animation */}
        {loading && (
          <Modal
            isOpen={loading}
            contentLabel="Publishing Modal"
            ariaHideApp={false}
            style={{
              content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "black",
                color: "white",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
            }}
          >
            <div className="flex items-center">
              <AiOutlineLoading3Quarters className="text-4xl animate-spin mr-4" />
              <p className="text-lg">Publishing your publication...</p>
            </div>
          </Modal>
        )}

        {/* Error modal */}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          ariaHideApp={false}
          contentLabel="Error Modal"
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#2a2d34",
              color: "white",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          <h2>Error</h2>
          {errors.title && <p>⚠️ {errors.title}</p>}
          {errors.description && <p>⚠️ {errors.description}</p>}
          {errors.price && <p>⚠️ {errors.price}</p>}
          {errors.coverImage && <p>⚠️ {errors.coverImage}</p>}
          {errors.file && <p>⚠️ {errors.file}</p>}
          {errors.general && <p>⚠️ {errors.general}</p>}
          <button
            onClick={closeModal}
            className="mt-4 bg-red-600 px-4 py-2 rounded-md hover:bg-red-800"
          >
            Close
          </button>
        </Modal>
      </div>
    </section>
  );
};

export default Upload;
