// Btns Component
import React from "react";
import { useNavigate } from "react-router-dom";

const Btns = ({ loading }) => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-center xl:justify-end">
      <section className="flex space-x-4 sm:space-x-8 py-4 sm:py-8">
        <button
          onClick={() =>
            navigate("/coaches/dashboard/publication/publications")
          }
          type="button"
          className="capitalize bg-transparent border border-white py-2 px-3 sm:py-3 sm:px-5 text-white text-base sm:text-2xl hover:border-primary hover:bg-primary"
        >
          cancel
        </button>
        <button
          type="submit"
          className="capitalize bg-primary border border-primary py-2 px-3 sm:py-3 sm:px-5 text-black text-base sm:text-2xl hover:bg-amber-600"
        >
          {loading ? "updating..." : "update"}
        </button>
      </section>
    </div>
  );
};

export default Btns;
