import React from "react";
import { motion } from "framer-motion";

const SuccessModal = ({ isOpen, message, isError = false, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <motion.div
        className={`bg-${isError ? "red-500" : "green-500"} p-6 rounded-lg shadow-lg w-11/12 max-w-md`}
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.8 }}
      >
        <h2 className="text-white text-lg font-bold mb-4">{message}</h2>
        <button
          onClick={onClose}
          className="bg-white text-black px-4 py-2 rounded-md"
        >
          Close
        </button>
      </motion.div>
    </div>
  );
};

export default SuccessModal;
