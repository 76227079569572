import { motion } from "framer-motion";
import Icon from "../../assets/coachassets/images-videos/logo2.png";

const LoadingModal = ({ isOpen }) => {
  if (!isOpen) return null;

  return (
    <motion.div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className="text-white text-lg"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ repeat: Infinity, repeatType: "reverse", duration: 1.5 }}
      >
        <img src={Icon} alt="Platinum Fuse Icon" className="w-20" />
      </motion.div>
      {/* <motion.div
        className="border-t-4 border-amber-500 rounded-full w-16 h-16 animate-spin mt-4"
        animate={{ rotate: 360 }}
        transition={{ repeat: Infinity, duration: 1.5, ease: "linear" }}
      ></motion.div> */}
    </motion.div>
  );
};

export default LoadingModal;
