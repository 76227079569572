import React, { useState, useEffect } from "react";
import ReactECharts from "echarts-for-react";
import { useQuery } from "@tanstack/react-query";
import { adminURL } from "../../utils/urls";

const fetchUserAppRating = async () => {
  const { data } = await adminURL.get("charts/rating");
  return data;
};

const UserRatingsChart = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["ratings"],
    queryFn: fetchUserAppRating,
  });

  const [ratingsData, setRatingsData] = useState(null);

  useEffect(() => {
    if (data) {
      setRatingsData(data);
    }
  }, [data]);

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error loading data</p>;

  const generateStarRating = (rating) => {
    const filledStar = "★";
    const unfilledStar = "☆";
    return filledStar.repeat(rating) + unfilledStar.repeat(5 - rating);
  };

  const ratingLabels = ratingsData
    ? ratingsData.labels.map((rating) => generateStarRating(rating))
    : [];
  const votes = ratingsData ? ratingsData.series[0].data : [];

  const options = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "line",
        lineStyle: {
          type: "dashed",
          color: "rgba(224, 224, 224, 0.2)",
        },
      },
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      borderWidth: 1,
      textStyle: {
        color: "#fff",
      },
    },
    xAxis: {
      type: "category",
      data: ratingLabels,
      axisLabel: {
        textStyle: {
          color: "rgb(202 138 4)",
        },
      },
      splitLine: {
        lineStyle: {
          type: "dotted",
          color: "white",
          width: 2,
          opacity: 0.2,
        },
      },
    },
    yAxis: {
      type: "value",
      min: 0,
      axisLabel: {
        textStyle: {
          color: "lightgrey",
        },
      },
      splitLine: {
        lineStyle: {
          type: "dotted",
          color: "white",
          width: 2,
          opacity: 0.2,
        },
      },
    },
    series: [
      {
        name: "Number of Votes",
        type: "line",
        data: votes,
        symbol: "none",
        itemStyle: {
          color: "rgb(0, 128, 0)",
        },
        lineStyle: {
          width: 2,
        },
        areaStyle: {
          color: "rgba(0, 128, 0, 0.3)",
        },
      },
    ],
  };

  return (
    <div className="flex flex-col bg-gray-500 bg-opacity-10 w-full space-y-1 rounded-xl relative shadow-xl">
      <h2 className="text-white text-left text-lg border-[0.5px] border-customBorderTwo rounded-t-lg py-3 px-4">
        User ratings & reviews
      </h2>
      <div className="p-3">
        {ratingsData && (
          <ReactECharts
            option={options}
            style={{ height: "400px", width: "100%" }}
          />
        )}
      </div>
    </div>
  );
};

export default UserRatingsChart;
