import React, { useEffect } from "react";
import Display from "./Display";

const SingleContent = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <section className="min-h-screen bg-customBlack">
      <div className="flex justify-center items-center py-4 md:py-8">
        <header
          style={{ backgroundColor: "rgba(12, 17, 23, 1)" }}
          className="flex justify-center items-center py-6 px-4 md:px-10 rounded-md space-x-4 md:space-x-8 max-w-[1020px] w-full"
        >
          <h1 className="text-lg md:text-xl text-white">User Information</h1>
        </header>
      </div>
      <div className="flex justify-center px-4">
        <Display />
      </div>
    </section>
  );
};

export default SingleContent;
