import React from "react";
import ReactECharts from "echarts-for-react";
import { useQuery } from "@tanstack/react-query";
import { adminURL } from "../../utils/urls";

const fetchDashboardData = async () => {
  const { data } = await adminURL.get(`dashboard`);
  return data.data;
};

const GenderDistributionGraph = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["dashboardData"],
    queryFn: fetchDashboardData,
  });

  if (isLoading || !data) {
    return <div className="text-white">Loading...</div>;
  }

  const maleData = data.user_statistics.datasets[0].data.reduce(
    (sum, value) => sum + value,
    0
  );
  const femaleData = data.user_statistics.datasets[1].data.reduce(
    (sum, value) => sum + value,
    0
  );

  const genderCounts = [
    {
      name: "Male",
      value: maleData,
      itemStyle: { color: "#3b82f6" },
    },
    {
      name: "Female",
      value: femaleData,
      itemStyle: { color: "#ec4899" },
    },
  ];

  const options = {
    tooltip: {
      show: true,
      trigger: "item",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      borderWidth: "1px",
      textStyle: {
        color: "#fff",
      },
      formatter: "{b}: {c} ({d}%)",
    },
    legend: {
      orient: "horizontal",
      bottom: "10px",
      left: "center",
      itemWidth: 20,
      itemHeight: 20,
      textStyle: {
        color: "#fff",
      },
    },
    series: [
      {
        name: "Gender",
        type: "pie",
        radius: "55%",
        avoidLabelOverlap: false,
        label: {
          show: true,
          position: "inside",
          formatter: "{d}%",
          color: "#fff",
          fontWeight: "bold",
        },
        labelLine: {
          show: false,
        },
        data: genderCounts,
        itemStyle: {
          borderRadius: 5,
          borderWidth: 2,
          borderColor: "#000",
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 16,
            fontWeight: "bold",
          },
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  return (
    <div className="flex flex-col bg-gray-500 bg-opacity-10  w-full space-y-1 rounded-xl  relative shadow-xl">
      <h2 className="text-white text-left text-lg border-[0.5px] border-customBorderTwo rounded-t-lg py-3 px-4">
        Gender Distribution
      </h2>
      <div className="p-3">
        {genderCounts.length > 0 ? (
          <div>
            <ReactECharts
              option={options}
              style={{ height: "400px", width: "100%" }}
            />
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </div>
  );
};

export default GenderDistributionGraph;
