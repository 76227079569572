import React from "react";
import Highlights from "./Highlights/Highlights";

const Payments = () => {
  return (
    <section className="">
      <h1 className="text-xl text-white text-center py-8 md:text-start md:px-12 md:text-2xl">
        Payments
      </h1>
      <Highlights />
    </section>
  );
};

export default Payments;
