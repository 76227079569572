import React from "react";
import { useQuery } from "@tanstack/react-query";
import { adminURL } from "../../../../../utils/urls";
import Loader from "../../../../loader/FetchLoader";
import Cstat from "./statistics/Cstat";
import Ccharts from "../c_charts/Ccharts";

const fetchMetrics = async () => {
  const { data } = await adminURL.get(`/metrics/coaches`);
  return data;
};

const Canalytics = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["coaches-metrics"],
    queryFn: fetchMetrics,
  });

  if (isLoading) {
    return <Loader text="fetching metrics" />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <section className="flex flex-col py-6 w-full">
      <div className="flex justify-center w-full">
        <header
          style={{ backgroundColor: "rgba(12, 17, 23, 1)" }}
          className="flex justify-center items-center py-6 px-10 rounded-md space-x-8 max-w-[1168px] w-full"
        >
          <h1 className="text-xl text-white">Coaches Analysis</h1>
        </header>
      </div>
      <Cstat data={data} />
      <Ccharts />
    </section>
  );
};

export default Canalytics;
