import React, { useState, useEffect } from "react";
import { IoCardSharp } from "react-icons/io5";
import PayoutModal from "../PayoutModal";
import { adminURL } from "../../../../../utils/urls";
import Loader from "../../../../../components/loader/FetchLoader";

const Highlights = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const id = localStorage.getItem("refreshID");

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const fetchData = async () => {
    try {
      const response = await adminURL.get(`users/${id}`);
      setUserData(response.data?.data?.user);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Loader text="Loading Payment Information" />;
  }

  return (
    <section className="flex flex-col lg:flex-row lg:space-x-10 space-y-6 lg:space-y-0 px-4 lg:px-14">
      <div className="flex items-center bg-gray-600 rounded-xl px-4 lg:px-6 py-3 space-x-4">
        <img
          src={userData?.photo_url}
          alt="coachImg"
          className="w-16 h-16 rounded-full"
        />
        <p className="text-white font-bold text-lg md:text-xl ">
          Wande Adeshina
        </p>
      </div>

      <div className="flex items-center bg-gray-600 rounded-xl px-4 lg:px-6 py-3 space-x-4">
        <IoCardSharp className="text-primary text-2xl md:text-4xl " />
        <div className="flex flex-col space-y-4 px-4 lg:px-14">
          <h1 className="text-white text-lg font-semibold md:text-xl">
            Total Earnings
          </h1>
          <p className="font-bold text-white text-lg md:text-xl">
            $24,000.00
          </p>
        </div>
      </div>

      <div
        className="rounded-xl bg-primary px-6 lg:px-10 h-16 lg:h-20 relative lg:top-7 flex items-center cursor-pointer"
        onClick={handleModalOpen}
      >
        <p className="text-black capitalize font-bold text-lg md:text-xl">
          Request Payout
        </p>
      </div>

      <PayoutModal isOpen={isModalOpen} onClose={handleModalClose} />
    </section>
  );
};

export default Highlights;
