import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  IoCloudUpload,
  IoTrash,
  IoInformationCircleOutline,
} from "react-icons/io5";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const CBFiles = ({ text, setFiles }) => {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");
  const [fileName, setFileName] = useState("");

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      setError("");
      if (rejectedFiles.length > 0) {
        const file = rejectedFiles[0];
        if (file.errors.some((e) => e.code === "file-too-large")) {
          setError("File is greater than 5MB");
        } else if (file.errors.some((e) => e.code === "file-invalid-type")) {
          setError("Invalid file type");
        }
        return;
      }

      setFiles(acceptedFiles);
      setFileName(acceptedFiles[0].name);
      setError("");
      simulateUpload();
    },
    [setFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize: 5 * 1024 * 1024,
    accept: {
      "application/pdf": [],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [],
      "application/msword": [],
    },
  });

  const simulateUpload = () => {
    setUploading(true);
    let uploadProgress = 0;
    const interval = setInterval(() => {
      uploadProgress += 10;
      setProgress(uploadProgress);
      if (uploadProgress === 100) {
        clearInterval(interval);
        setUploading(false);
      }
    }, 100);
  };

  const clearFiles = () => {
    setFiles([]);
    setProgress(0);
    setError("");
    setFileName("");
  };

  // Truncate the file name if it's too long
  const truncatedFileName =
    fileName.length > 20 ? `${fileName.substring(0, 17)}...` : fileName;

  return (
    <div
      {...getRootProps()}
      className={`flex flex-col items-center justify-center rounded-lg cursor-pointer px-4 py-6 ${
        error ? "bg-red-500" : "bg-gray-700"
      }`}
      style={{ height: "250px" }}
    >
      <input {...getInputProps()} />
      <div className="flex items-center justify-between">
        <IoCloudUpload size={60} className="text-white" />
        <div className="flex flex-col ml-4">
          <div className="flex items-center">
            <p className="text-white text-lg">
              <span className="text-amber-500">
                {fileName ? truncatedFileName : text}
              </span>
            </p>
            <IoInformationCircleOutline
              size={20}
              className="text-white ml-2"
              data-tooltip-id="resume-tooltip"
              data-tooltip-content="Accepts .pdf, .doc, .docx formats (Max size: 4MB)"
            />
            <Tooltip id="resume-tooltip" place="top" effect="solid" />
          </div>
          <p className="text-gray-400 text-sm mt-1">
            PDF, DOC, DOCX (Max size: 5MB)
          </p>
          {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
        </div>
      </div>

      {uploading && (
        <div className="w-full bg-gray-600 mt-4 rounded">
          <div
            className="bg-amber-500 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-l"
            style={{ width: `${progress}%` }}
          >
            {progress}%
          </div>
        </div>
      )}

      {progress === 100 && !uploading && (
        <div className="flex justify-center items-center mt-4">
          <IoTrash
            size={30}
            className="text-white cursor-pointer"
            onClick={clearFiles}
          />
          <p className="ml-2 text-white">{truncatedFileName} Uploaded</p>
        </div>
      )}
    </div>
  );
};

export default CBFiles;
