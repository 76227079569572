import React, { useState } from "react";
import Details from "./Details";
import Schedule from "./schedule/Schedule";
import Duration from "./duration/Duration";
import { adminURL } from "../../../utils/urls";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const SlotProfile = ({ data, profile }) => {
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const queryClient = useQueryClient(); 
  const navigate = useNavigate(); 

  const cancelAppointment = async (id) => {
    const response = await adminURL.delete(`appointments/${id}`);
    return response.data;
  };

  const { mutate: cancelSlot, isLoading: isCancelling } = useMutation({
    mutationFn: () => cancelAppointment(data?.slot.id),
    onSuccess: () => {
      queryClient.invalidateQueries(["appointment", data?.slot.id]); 
      navigate("/coaches/dashboard/appointments"); 
    },
  });

  const handleCancelClick = () => {
    setIsModalOpen(true); 
  };

  const handleConfirmCancel = () => {
    cancelSlot(); 
    setIsModalOpen(false); 
  };

  return (
    <section className="w-full flex justify-center items-center">
      <section className="flex flex-col w-full lg:max-w-4xl">
        <div className="text-center mb-6">
          <img
            src={profile?.photo_url}
            alt="pending profile img"
            className="mx-auto w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl rounded-full object-cover aspect-square"
          />
        </div>
        <Details profile={profile} />
        <Schedule data={data} />
        <Duration data={data} />

        {/* Decline Button */}
        <div className="flex justify-center mt-6">
          <button
            onClick={handleCancelClick}
            className={`px-6 py-3 text-white rounded-lg font-bold transition-all duration-200 ${
              isCancelling
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-red-500 hover:bg-red-600"
            }`}
            disabled={isCancelling}
          >
            {isCancelling ? "Cancelling..." : "Decline"}
          </button>
        </div>

        {/* Confirmation Modal */}
        {isModalOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          >
            <motion.div
              initial={{ y: "-100vh" }}
              animate={{ y: 0 }}
              exit={{ y: "-100vh" }}
              className="bg-gray-800 text-white p-8 rounded-lg shadow-lg"
            >
              <p className="mb-4">
                Are you sure you want to decline this appointment?
              </p>
              <div className="flex justify-between">
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                >
                  Cancel
                </button>
                <button
                  onClick={handleConfirmCancel}
                  className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                  disabled={isCancelling}
                >
                  {isCancelling ? (
                    <motion.div
                      className="animate-spin h-5 w-5 border-t-2 border-b-2 border-white rounded-full"
                      aria-label="Loading"
                    ></motion.div>
                  ) : (
                    "OK"
                  )}
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}

        {/* Full-Screen Loading Animation */}
        {isCancelling && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50"
          >
            <motion.div
              className="animate-spin h-16 w-16 border-t-4 border-b-4 border-white rounded-full"
              aria-label="Loading"
            ></motion.div>
          </motion.div>
        )}
      </section>
    </section>
  );
};

export default SlotProfile;
