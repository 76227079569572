import React from "react";
import ModalThumbsUpIcon from "../../../assets/coachassets/images-videos/Groupone.png";

const ModalThumbsUp = ({ isOpen, onClose, textTop, textBottom, btnText }) => {
  if (!isOpen) return null;

  const handleClose = () => {
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center px-4">
      <div className="bg-gray-900 rounded-lg shadow-lg p-8 w-full max-w-xl">
        <h2 className="text-2xl font-bold text-center mb-4 text-white">
          {textTop || ""}
        </h2>
        <div className="flex justify-center mb-4">
          <img src={ModalThumbsUpIcon} alt="Thumbs Up" className="w-24 h-24" />
        </div>
        <p className="text-center text-sm text-white mb-4">
          {textBottom || ""}
        </p>
        <div className="flex justify-center">
          <button
            onClick={handleClose}
            className="px-8 py-2 bg-amber-500 text-black font-bold rounded-lg hover:bg-amber-700"
          >
           { btnText ? btnText :  'Done'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalThumbsUp;
