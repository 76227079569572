import React from "react";
import AppointmentGrid from "./AppointmentGrid";
import { Link } from "react-router-dom";

const Others = ({ currentID, appointments }) => {
  return (
    <div className="flex flex-col items-center md:items-start">
      <div className="flex flex-col justify-between items-center py-8 w-full space-y-3 md:space-y-0 xl:flex-row">
        <h2 className="text-xl md:text-2xl font-bold mb-4 bg-amber-500 text-white px-4 py-2 rounded-md">
          Other Appointments
        </h2>
        <Link
          to="/coaches/dashboard/appointments"
          className="bg-white text-amber-500 px-4 py-2 rounded-md font-semibold hover:bg-amber-500 text-black transition duration-300"
        >
          See All
        </Link>
      </div>
      <AppointmentGrid currentID={currentID} appointments={appointments} />
    </div>
  );
};

export default Others;
