import React from "react";
import AgeDistributionGraph from "../../../../charts/AgeDistributionGraph";
import GenderDistributionGraph from "../../../../charts/GenderDistributionGraph";
import LocationDistributionChart from "../../../../charts/LocationDistributionChart";

const UserDemographics = () => {
  return (
    <div className="flex flex-col w-full space-y-3 min-h-screen">
      <div className="p-5 rounded-b-lg space-y-4">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <AgeDistributionGraph />
          <GenderDistributionGraph />
        </div>
        <div className="grid grid-cols-1 gap-4">
          <LocationDistributionChart />
        </div>
      </div>
    </div>
  );
};

export default UserDemographics;
