import React from "react";
import SubscriptionChart from "../../../../charts/SubscriptionChart";
// import InAppPuchaseChart from "../../../../charts/InAppPuchaseChart";

const UserConversion = () => {
  return (
    <div className="flex flex-col w-full space-y-3 min-h-screen">
      <div className="p-3 rounded-b-lg grid grid-cols-1 gap-4">
        <div className="grid grid-cols-1 gap-4">
          <SubscriptionChart />
        </div>
        {/* <div className="grid grid-cols-1 gap-4">
          <InAppPuchaseChart />
        </div> */}
      </div>
    </div>
  );
};

export default UserConversion;
