import React from "react";
import SvgOne from "../../../../assets/images/charts/svg1.svg";
import SvgTwo from "../../../../assets/images/charts/svg2.svg";
import SvgThree from "../../../../assets/images/charts/svg3.svg";

const Card = ({ data, isLoading }) => {
  const cardData = [
    {
      title: "Todays Appointments",
      percentage: data?.today_appointments_count,
      svg: SvgOne,
    },
    {
      title: "Completed Appointments",
      percentage: data?.completed_appointments_count,
      svg: SvgTwo,
    },
    {
      title: "Total Number of Appointments",
      percentage: data?.appointments_count,
      svg: SvgThree,
    },
  ];

  return (
    <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-10 px-6 pt-26 md:px-10">
      {isLoading ? (
        <div className="flex justify-center text-center items-center w-full h-full">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-amber-500"></div>
        </div>
      ) : (
        cardData.map((card, index) => (
          <div
            key={index}
            className="relative bg-customBlack border border-customBorderTwo shadow-md rounded-lg flex-grow xl:min-w-[250px]"
          >
            <div className="mb-4 px-2 py-4">
              <h2 className="font-bold mb-2 text-white">{card.title}</h2>
              <p className="text-2xl font-semibold text-white">
                {card.percentage}
              </p>
            </div>
            <div className="flex justify-center">
              <img
                src={card.svg}
                alt={`${card.title} chart`}
                className="w-full h-auto"
              />
            </div>
            <div className="absolute top-0 left-0 w-full h-full bg-gray-500 bg-opacity-10 rounded-lg"></div>
          </div>
        ))
      )}
    </section>
  );
};

export default Card;
