import React from "react";
import sessionImgOne from "../../../../../../assets/images/admincoaches/sessionIconAnalysis.png";
import sessionImgTwo from "../../../../../../assets/images/admincoaches/sessionDuration.png";
import sessionImgThree from "../../../../../../assets/images/admincoaches/sessionDuration.png";
import sessionImgFour from "../../../../../../assets/images/admincoaches/userrevenueanalysis.png";

const StatCard = ({ stat }) => (
  <div className="flex flex-col space-y-4 py-8 px-6 bg-[#20242E] shadow-xl m-4 rounded-md w-[250px]">
    <h4 className="text-white text-sm">{stat.label}</h4>
    <div className="flex items-center space-x-4">
      <div className="bg-gray-300 rounded-full p-3">
        <img src={stat.img} alt={stat.label} className="w-10 h-10" />
      </div>
      <p className="text-2xl text-white font-bold">
        {stat.isRating ? (
          <>
            <span className="text-amber-500">{stat.value.split("/")[0]}</span>
            /5
          </>
        ) : (
          stat.value
        )}
      </p>
    </div>
  </div>
);

const Cstat = ({ data }) => {
  const stats = [
    {
      label: "Total number of sessions",
      value: data?.appointments_count,
      img: sessionImgOne,
    },
    {
      label: "Average session duration",
      value: data?.appointments_duration,
      img: sessionImgTwo,
    },
    {
      label: "User ratings",
      value: `${data?.rating_avg}/5`,
      img: sessionImgThree,
      isRating: true,
    },
    {
      label: "Total revenue",
      value: `${data?.revenue === 0 ? 0 : `NGN${data?.revenue}`}`,
      img: sessionImgFour,
    },
  ];

  return (
    <main className="mt-6 max-w-[1355px] flex justify-center w-full">
      <div className="flex flex-wrap gap-6">
        {stats.map((stat, index) => (
          <StatCard key={index} stat={stat} />
        ))}
      </div>
    </main>
  );
};

export default Cstat;
