import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { FiUpload, FiX, FiRefreshCcw } from "react-icons/fi";
import { motion } from "framer-motion";
import { ImSpinner2 } from "react-icons/im";

const EventFileInput = ({ onFileChange }) => {
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setIsLoading(true);
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
        setIsLoading(false);
        onFileChange(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const removeImage = () => {
    setImage(null);
    onFileChange(null);
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    multiple: false,
    accept: "image/*",
    noClick: true, // Prevents opening the file browser on click
    noKeyboard: true, // Disables file selection via keyboard
  });

  return (
    <div className="flex flex-col space-y-2 w-[250px]">
      <div
        {...getRootProps()}
        className={`p-4 rounded-xl text-center text-white h-full flex flex-col items-center justify-center bg-gray-700 border-dashed ${
          isDragActive ? "border-blue-500" : "border-gray-400"
        } border-2 ${image ? "p-0" : "p-4"}`}
      >
        <input {...getInputProps()} />
        {isLoading ? (
          <motion.div
            className="flex flex-col items-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <ImSpinner2 className="animate-spin text-4xl mb-2" />
            <p className="text-sm">Uploading...</p>
          </motion.div>
        ) : image ? (
          <div className="relative w-full h-auto">
            <img
              src={image}
              alt="Preview"
              className="w-full h-auto rounded-xl object-cover"
            />
            <div className="absolute top-2 right-2 flex space-x-2">
              <button
                type="button"
                onClick={removeImage}
                className="bg-red-600 hover:bg-red-700 text-white rounded-full p-1"
              >
                <FiX />
              </button>
              <button
                type="button"
                onClick={open} // Reopen the file dialog to replace the image
                className="bg-amber-500 hover:bg-blue-600 text-white rounded-full p-1"
              >
                <FiRefreshCcw />
              </button>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center">
            <FiUpload className="text-4xl mb-2" />
            {isDragActive ? (
              <p className="text-sm">Drop the image here ...</p>
            ) : (
              <p className="text-sm">Drag & drop your image here</p>
            )}
            <p className="py-3">or</p>
            <button
              type="button"
              onClick={open}
              className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-6 rounded"
            >
              Browse
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventFileInput;
